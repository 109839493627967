const NewHero = () => {
  return (
    <div id="new-hero" className="section">
      <div className="container">
        <h4>Drive into the Future, Today.</h4>
        <h1 className="mb-3">Luxury Vehicle Tokenization</h1>
        <h5>
          Drivora transforms your cars into digital assets, enabling you to
          list, trade, and manage fractional ownerships with unparalleled
          precision and luxury.
        </h5>
      </div>
    </div>
  );
};

export default NewHero;
