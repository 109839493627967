import { useEffect, useState } from "react";
import add_photo_icon from "../assets/images/add-photo-icon.png";
import { formatSize } from "../utils/filesize";

const FileUpload = ({ onFilesSelected }) => {
  const [files, setFiles] = useState([]);

  const handleFileChange = (event) => {
    const selectedFiles = event.target.files;
    if (selectedFiles && selectedFiles.length > 0) {
      const newFiles = Array.from(selectedFiles);
      setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    }
  };
  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFiles = event.dataTransfer.files;
    if (droppedFiles.length > 0) {
      const newFiles = Array.from(droppedFiles);
      setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    }
  };

  const handleRemoveFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  useEffect(() => {
    onFilesSelected(files);
  }, [files, onFilesSelected]);

  return (
    <div
      className="upload-container"
      onDrop={handleDrop}
      onDragOver={(e) => e.preventDefault()}
    >
      <div className="d-flex align-items-center gap-4">
        <img src={add_photo_icon} alt="add_photo_icon" />
        <p>
          Upload photos and videos of your exotic car. High-quality visuals help
          showcase the vehicle's features and attract potential buyers.
        </p>
      </div>
      <div className="upload-media-box">
        <p className="text-white">UPLOAD MEDIA</p>
        <label htmlFor="upload-image" className="profile-btn mt-4">
          <i className="fas fa-upload me-2"></i> UPLOAD PHOTO/VIDEO
        </label>
        <input
          type="file"
          id="upload-image"
          className="d-none"
          accept="video/*,image/*"
          onChange={handleFileChange}
          multiple
        />
        <p className="mt-3">OR DRAG A FILE HERE.</p>
      </div>
      {files.length > 0 && (
        <div className="uploaded">
          <p className="mt-4">UPLOADED FILES:</p>
          <div className="w-100 w-sm-75">
            {files.map((file, index) => (
              <div className="uploaded-file" key={index}>
                <div className="d-flex align-items-center gap-3">
                  <i className="far fa-image"></i>
                  <p className="text-white">{file.name}</p>
                  {/* <p className="text-white">coverimg123009.jpg</p> */}
                  <p>{formatSize(file.size)}</p>
                </div>
                <i
                  className="fas fa-trash-alt"
                  onClick={() => handleRemoveFile(index)}
                ></i>
              </div>
            ))}
          </div>
        </div>
      )}

      <p className="mt-5">
        Provide clear and detailed images or videos that highlight different
        angles and unique aspects of your car. Include shots of the exterior,
        interior, engine, and any special features. This media will be displayed
        during the tokenized asset preview, giving users a comprehensive view of
        the asset.
      </p>
    </div>
  );
};

export default FileUpload;
