import { motion } from "framer-motion";
import "./where.css";

const Where = () => {
  return (
    <div className="where">
      <div className="container">
        <motion.div
          initial={{ opacity: 0, y: 200 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
        >
          <h4 className="add-vertical-line">WHERE</h4>
          <div className="section-content">
            <h2>
              AUTOMOTIVE PASSION MEETS{" "}
              <span className="text-shadow">BLOCKCHAIN TECHNOLOGY.</span>
            </h2>
          </div>
        </motion.div>
        <div className="section-content">
          <motion.p
            initial={{ opacity: 0, y: -50, x: -50, scale: 1.3 }}
            whileInView={{ opacity: 1, y: 0, x: 0, scale: 1 }}
            transition={{ delay: 0.6, duration: 1 }}
            viewport={{ once: true }}
            className="mt-5"
          >
            Drivora provides a secure, exclusive marketplace where owners of
            high-end vehicles can tokenize their cars, making them accessible
            for selling and fractional investment. Our approach enhances the
            liquidity of physical assets and preserves the thrill and prestige
            of exotic car ownership.
          </motion.p>
        </div>
      </div>
    </div>
  );
};

export default Where;
