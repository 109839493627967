import { motion } from "framer-motion";
import image from "../../assets/images/story-image.png";
import user from "../../assets/images/user.png";
import "./story.css";

const Story = () => {
  const teamMembers = [
    { name: "JOHN DOE", role: "CEO" },
    { name: "ANDREW KING", role: "CTO" },
    { name: "NATALIE H.", role: "Finance Director" },
    { name: "KAREN MOORE", role: "Technology Director" },
    { name: "JASON HOWARD", role: "Product Management Director" },
  ];

  return (
    <div className="story">
      <div className="container">
        <motion.div
          initial={{ opacity: 0, y: 200 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
        >
          <h4 className="add-vertical-line">OUR STORY</h4>
          <div className="section-content">
            <h2>
              DRIVEN BY LUXURY, GUIDED BY
              <span className="text-shadow"> EXCELLENCE.</span>
            </h2>
          </div>
        </motion.div>
        <div className="d-flex story-box flex-column flex-lg-row">
          <div className="w-100 w-lg-50">
            <motion.img
              initial={{ opacity: 0, x: 300 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.5, duration: 1 }}
              viewport={{ once: true }}
              src={image}
              alt="story car"
            />
          </div>
          <div className="w-100 w-lg-50 story-content">
            <motion.div
              initial={{ opacity: 0, y: 100 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.3, duration: 1 }}
              viewport={{ once: true }}
            >
              <p className="mb-4">
                Founded by pioneers in the automotive and blockchain industries,
                Drivora was born from a passion for luxury cars and a vision to
                empower owners like never before.
              </p>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 100 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.6, duration: 1 }}
              viewport={{ once: true }}
            >
              <p className="mb-4">
                Our brand story is woven from the desire to blend the
                exclusivity of exotic cars with the cutting-edge possibilities
                of digital technology.
              </p>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 100 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.9, duration: 1 }}
              viewport={{ once: true }}
            >
              <p>
                With each token, we’re not just offering a piece of luxury;
                we’re inviting you into a future where your car does more than
                drive - it appreciates.
              </p>
            </motion.div>
          </div>
        </div>
        <div className="team">
          <motion.div
            initial={{ opacity: 0, y: 100 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
            viewport={{ once: true }}
          >
            <h2>EXECUTIVE TEAM.</h2>
          </motion.div>
          <div className="d-flex gap-4 justify-content-center story-box flex-wrap">
            {teamMembers.map((member, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 100 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2 * index, duration: 0.6 }}
                viewport={{ once: true }}
                className="text-center"
              >
                <img src={user} alt="user" />
                <div className="name">{member.name}</div>
                <div className="role">{member.role}</div>
              </motion.div>
            ))}
          </div>
          {/* <div className="d-flex gap-4 justify-content-center story-box flex-wrap">
            <div>
              <img src={user} alt="user" />
              <div className="name">JOHN DOE</div>
              <div className="role">CEO</div>
            </div>
            <div>
              <img src={user} alt="user" />
              <div className="name">ANDREW KING</div>
              <div className="role">CTO</div>
            </div>
            <div>
              <img src={user} alt="user" />
              <div className="name">NATALIE H.</div>
              <div className="role">Finance Director</div>
            </div>
            <div>
              <img src={user} alt="user" />
              <div className="name">KAREN MOORE</div>
              <div className="role">Technology Director</div>
            </div>
            <div>
              <img src={user} alt="user" />
              <div className="name">JASON HOWARD</div>
              <div className="role">Product Management Director</div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Story;
