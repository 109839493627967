import {
  prepareWriteContract,
  writeContract,
  readContract,
  waitForTransaction,
} from "@wagmi/core";
import { toast } from "react-toastify";

const Drivora = require("./Drivora.json");

const ipfsHash = "";

export const transferNFTWeb3 = async (nftAddress) => {
  try {
    const config = await prepareWriteContract({
      address: nftAddress,
      abi: Drivora.abi,
      functionName: "mint",
      args: [ipfsHash],
    });
    const { hash } = await writeContract(config);
    const transaction = await waitForTransaction({ hash });
    return { success: true, hash: transaction.transactionHash };
  } catch (err) {
    if (err.code !== 4001) toast.error(err?.error?.message || err.message);
    return;
  }
};

export const mint = async () => {
  try {
    const config = await prepareWriteContract({
      address: "0x373654C36ff772270b52Afcd19E0C49E48817ffb",
      abi: Drivora.abi,
      functionName: "mint",
      args: ["test"],
    });
    const { hash } = await writeContract(config);
    await waitForTransaction({ hash });

    const data = await readContract({
      address: process.env.REACT_APP_DRIVORA_ADDRESS,
      abi: Drivora.abi,
      functionName: "totalToken",
      args: [],
    });

    return { success: true, tokenId: (data - 1).toString() };
  } catch (err) {
    console.log(err);
    if (err.code !== 4001) toast.error(err?.error?.message || err.message);
    return;
  }
};
