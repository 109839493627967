import Modal from "react-modal";
import "./modal.css";

const CounterModal = (props) => {
  const { showCounterModal, setShowCounterModal } = props;
  const handleClose = () => {
    setShowCounterModal(false);
  };

  return (
    <Modal
      appElement={document.getElementById("root")}
      isOpen={showCounterModal}
      onRequestClose={handleClose}
      overlayClassName={{
        base: "overlay-base",
        afterOpen: "offer-overlay-after",
        beforeClose: "overlay-before",
      }}
      className={{
        base: "content-base",
        afterOpen: "bid-content-after",
        beforeClose: "content-before",
      }}
      preventScroll={true}
    >
      <div className="modal-close">
        <i className="material-icons" onClick={handleClose}>
          close
        </i>
      </div>
      <h5 className="text-black modal-title">COUNTER OFFER</h5>
      <div className="my-4 d-flex gap-2">
        <input type="text" className="modal-input" />
        <input type="text" className="modal-input" placeholder="Amount" />
      </div>
      <h5 className="mb-2">DURATION</h5>
      <div className="position-relative w-100">
        <input type="text" className="modal-input ps-5" placeholder="7 days" />
        <i className="far fa-calendar-plus input-icon"></i>
      </div>
      <h5 className="mb-1 mt-4">RESERVE FOR BUYER</h5>
      <p className="mb-3">This item can be purchased as soon as it's listed.</p>
      <input
        type="text"
        className="modal-input mb-4"
        placeholder="0xsadfhkcvkksdfiashdf"
      />
      <button className="modal-btn black-btn">SEND OFFER</button>
    </Modal>
  );
};

export default CounterModal;
