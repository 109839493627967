import { createSlice } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const initialState = {
  token: null,
  vin: "",
  nftData: null, // Add nftData to the initial state
};

const slice = createSlice({
  name: "drivora-nft",
  initialState,
  reducers: {
    setCurrentToken: (state, { payload }) => {
      state.token = payload;
    },
    setVin: (state, { payload }) => {
      state.vin = payload;
    },
    setNftData: (state, { payload }) => {
      state.nftData = payload;
    },
  },
});

const persistConfig = {
  key: "current-token",
  version: 1,
  storage,
  whitelist: ["token", "vin"], // Only persist the token, not the nftData
};

export const { setCurrentToken, setNftData, setVin } = slice.actions;

export const reducer = persistReducer(persistConfig, slice.reducer);
