import { RotatingLines } from "react-loader-spinner";

const LoadingButton = ({
  isLoading,
  clickAction,
  className,
  content,
  disabled,
}) => {
  return (
    <button className={className} onClick={clickAction} disabled={disabled}>
      {isLoading ? (
        <RotatingLines
          visible={true}
          height="20"
          width="20"
          color="#FFFFFF"
          strokeWidth="5"
          strokeColor="#ffffff"
          animationDuration="0.75"
          ariaLabel="rotating-lines-loading"
          wrapperStyle={{}}
          wrapperClass=""
        />
      ) : (
        content
      )}
    </button>
  );
};

export default LoadingButton;
