import React, { useEffect } from "react";

const steps = [
  "Basic Details",
  "Add Your VIN",
  "Asset Details",
  "Documentation",
  "Media",
  "RFID",
];

const StepBar = ({ currentStep }) => {
  const [stepNum, setStepNum] = React.useState(0);

  useEffect(() => {
    if (!currentStep) {
      setStepNum(1);
    } else {
      setStepNum(currentStep);
    }
  }, [currentStep]);

  return (
    <div className="d-flex w-100 justify-content-center align-items-center gap-2 gap-md-4 mt-5 step-bar">
      {steps.map((step, index) => (
        <React.Fragment key={index}>
          <p className={`${index >= stepNum && "step-bar"}`}>{step}</p>
          <i
            className={`${step.length === index - 1 && "d-none"} ${
              index >= stepNum - 1 ? "step-bar" : "text-white"
            } material-icons`}
          >
            keyboard_arrow_right
          </i>
        </React.Fragment>
      ))}
    </div>
  );
};

export default StepBar;
