import { useState } from "react";
import Slider from "react-slick";
import { motion } from "framer-motion";
import main_car1 from "../../assets/images/marketplace-car.png";
import main_car2 from "../../assets/images/marketplace-car2.png";
import main_car3 from "../../assets/images/marketplace-car3.png";
import main_car4 from "../../assets/images/marketplace-car4.png";
import main_car5 from "../../assets/images/marketplace-car5.png";
import circle from "../../assets/images/circle.png";
import car1 from "../../assets/images/car-01.png";
import car2 from "../../assets/images/car-02.png";
import car3 from "../../assets/images/car-03.png";
import car4 from "../../assets/images/car-04.png";
import car5 from "../../assets/images/car-05.png";
import "./welcome.css";

const cars = [car1, car2, car3, car4, car5];

const main_cars = [main_car1, main_car2, main_car3, main_car4, main_car5];

const carName = [
  "SF90 Stradale",
  "SF90 Spider",
  "296 GTB",
  "296 GTS",
  "12 Cilindri",
];

const Welcome = () => {
  const [selectedCar, setSelectedCar] = useState(0);

  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    pauseOnHover: false,
    autoplaySpeed: 3000,
    swipeToSlide: true,
    touchMove: true,
    // initialSlide: 0,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="welcome">
      <div className="container">
        <div className="d-flex flex-column flex-lg-row align-items-center align-items-xxl-end gap-4 gap-sm-5 gap-lg-0">
          <div className="w-100 w-lg-50 text-center text-lg-start">
            <motion.h2
              initial={{ opacity: 0, y: 100 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, delay: 0.3 }}
              viewport={{ once: true }}
              className="text-shadow lh-lg"
            >
              ENTER THE NEW ERA OF LUXURY CAR OWNERSHIP.
            </motion.h2>
            <motion.p
              initial={{ opacity: 0, y: 100 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, delay: 0.6 }}
              viewport={{ once: true }}
            >
              Drive into the future of luxury and opportunity. Trade luxury
              vehicles, participate in fractional ownership, tokenize your
              exotic with unmatched security and efficiency through Drivora's
              blockchain technology.
            </motion.p>
            <motion.div
              initial={{ opacity: 0, y: 100 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, delay: 1 }}
              viewport={{ once: true }}
              className="mt-5 px-5"
            >
              <Slider {...settings}>
                {cars.map((car, index) => (
                  <div
                    className={`${
                      selectedCar === index ? "active" : ""
                    } slide-item`}
                    onClick={() => setSelectedCar(index)}
                    key={index}
                  >
                    <div className="cars-box">
                      <img src={car} alt="car" />
                    </div>
                    <div className="cars-name">{carName[index]}</div>
                  </div>
                ))}
              </Slider>
            </motion.div>
          </div>
          <div className="w-100 w-lg-50">
            <div className="position-relative text-center text-lg-end">
              <motion.img
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 1, delay: 0.5 }}
                viewport={{ once: true }}
                src={main_cars[selectedCar]}
                alt="car"
                className="position-relative w-90 z-2"
              />
              <img src={circle} alt="circle" className="circle" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
