import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { useSetCurrentToken } from "../store/hooks/token";
import { ethers } from "ethers";

const CustomNftGroup = (props) => {
  const { title, nfts, rows } = props;
  const navigate = useNavigate();
  const setCurrentToken = useSetCurrentToken();

  const handleNft = (id, tokenId, status) => {
    if (tokenId) {
      navigate(`/detail/${tokenId}`);
    } else {
      setCurrentToken({ token: id });
      navigate(`/tokenize`);
    }
  };

  return (
    <div className="nft-group">
      <div className="nft-group-header">
        <div className="group-title">
          {title?.split(" ").slice(0, -1).join(" ")}{" "}
          <span className="text-shadow text-white">
            {" "}
            {title?.split(" ").at(-1)}
          </span>
        </div>
        <div className="bottom-line"></div>
        <div className="view-more">
          VIEW MORE <i className="fas fa-arrow-right ms-2"></i>
        </div>
      </div>
      <div className={rows ? "nft-grid nft-grid-4" : "nft-grid"}>
        {nfts.map((item, index) => (
          <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            whileInView={{ opacity: 1, scale: 1 }}
            transition={{ duration: 1, delay: 0.2 * index }}
            viewport={{ once: true }}
            className="nft-card"
            onClick={() => handleNft(item.id, item.tokenId, item.status)}
            key={index}
          >
            <img
              src={item.asset_files.split(",")[0]}
              alt="trending car"
              className="nft-img"
            />
            <div className={item.type ? "card-text-type" : "card-text"}>
              {item.tokenId ? (
                <div className="card-badge">New Mint</div>
              ) : (
                <div className="card-badge">Editing</div>
              )}
              {item.type ? (
                <>
                  <p className="card-name text-white">{item.type}</p>
                  <div>
                    <span className="text-white">250</span> Listed
                  </div>
                </>
              ) : (
                <p className="card-name">{item.name}</p>
              )}
              {item.price !== undefined && (
                <p className="text-white mt-2 card-name">
                  <i className="	fab fa-ethereum"></i>
                  {ethers.utils.formatEther(item.price)}
                </p>
              )}
            </div>
            {item.info && (
              <div className="d-flex">
                <div className="card-info w-100">
                  <span className="text-white dot">3</span> New Bids
                </div>
                <div className="card-info w-100">
                  <i className="far fa-eye me-2"></i>{" "}
                  <span className="text-white">2,345</span> VIEWS
                </div>
              </div>
            )}
            {item.footer && (
              <div className="d-flex justify-content-between px-2 card-footer">
                <div className="star-rating">
                  <span className="fa fa-star"></span>
                  <span className="fa fa-star"></span>
                  <span className="fa fa-star"></span>
                  <span className="fa fa-star"></span>
                  <span className="fa fa-star"></span>
                </div>
                <div className="card-info border-0">
                  <i className="far fa-thumbs-up me-2"></i>{" "}
                  <span className="text-white">2,345</span> LIKES
                </div>
              </div>
            )}
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default CustomNftGroup;
