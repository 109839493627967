import { useState } from "react";
import { motion } from "framer-motion";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import shape1 from "../../assets/images/shape1.png";
import shape2 from "../../assets/images/shape2.png";
import main_car1 from "../../assets/images/marketplace-car.png";
import main_car2 from "../../assets/images/marketplace-car2.png";
import main_car3 from "../../assets/images/marketplace-car3.png";
import main_car4 from "../../assets/images/marketplace-car4.png";
import main_car5 from "../../assets/images/marketplace-car5.png";
import circle from "../../assets/images/circle.png";
import engine_icon from "../../assets/images/engine-icon.png";
import speed_icon from "../../assets/images/speed-icon.png";
import power_icon from "../../assets/images/power-icon.png";
import time_icon from "../../assets/images/time-icon.png";
import ferrari_icon from "../../assets/images/ferrari-icon.png";
import car1 from "../../assets/images/car-01.png";
import car2 from "../../assets/images/car-02.png";
import car3 from "../../assets/images/car-03.png";
import car4 from "../../assets/images/car-04.png";
import car5 from "../../assets/images/car-05.png";
import "./marketplace.css";

const cars = [car1, car2, car3, car4, car5];

const main_cars = [main_car1, main_car2, main_car3, main_car4, main_car5];

const carName = [
  "SF90 Stradale",
  "SF90 Spider",
  "296 GTB",
  "296 GTS",
  "12 Cilindri",
];

const Marketplace = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedCar, setSelectedCar] = useState(0);
  return (
    <div id="marketplace">
      <div className="container">
        <Navbar />
        <div className="main">
          <img src={shape1} alt="shape" className="shape1" />
          <div className="car-type">FERRARI</div>
          <div className="main-place">
            <motion.div
              initial={{ opacity: 0, x: -200 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.6, duration: 1 }}
              viewport={{ once: true }}
              className="brands mobile-hide"
            >
              <div className="d-flex gap-3 align-items-center">
                <div className="line"></div>
                <div className="brands-title">BRANDS</div>
                <div className="line"></div>
              </div>
              <div
                className="select"
                onClick={() => setShowDropdown(!showDropdown)}
              >
                <img src={ferrari_icon} alt="ferrari icon" />
                <div className="selected-option">Ferrari</div>
                <i className="fa">&#xf107;</i>
                <div className={`options ${showDropdown && "d-block"}`}>
                  <div className="option">Ferrari</div>
                  <div className="option">Ferrari</div>
                </div>
              </div>
              <div>
                <ul>
                  {carName.map((name, index) => (
                    <li
                      key={index}
                      className={index === selectedCar ? "selected" : ""}
                    >
                      {name}
                    </li>
                  ))}
                </ul>
              </div>
            </motion.div>
            <div className="car-name">
              <motion.div
                initial={{ opacity: 0, y: 200 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.6, duration: 1 }}
                viewport={{ once: true }}
              >
                <h1 className="text-shadow fw-light">SF90 STRADALE.</h1>
                <div className="brands-title mt-4 mb-1">PRICE</div>
                <div className="car-price">USD $580,000</div>
              </motion.div>
            </div>
            <motion.div
              initial={{ opacity: 0, x: 200 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.6, duration: 1 }}
              viewport={{ once: true }}
              className="performance mobile-hide"
            >
              <img src={shape2} alt="shape" className="shape2" />
              <div className="performance-item">
                <img
                  src={engine_icon}
                  alt="engine"
                  className="performance-icon"
                />
                <div>
                  <div className="performance-item-name">Engine</div>
                  <div className="performance-item-value">
                    V12 gasoline engine
                  </div>
                </div>
              </div>
              <div className="performance-item">
                <img
                  src={speed_icon}
                  alt="speed"
                  className="performance-icon"
                />
                <div>
                  <div className="performance-item-name">Top Speed</div>
                  <div className="performance-item-value">340 km/h</div>
                  <div className="progress-bar"></div>
                </div>
              </div>
              <div className="performance-item">
                <img src={time_icon} alt="time" className="performance-icon" />
                <div>
                  <div className="performance-item-name">0 - 100 km/h</div>
                  <div className="performance-item-value">2.9 sec</div>
                  <div className="progress-bar"></div>
                </div>
              </div>
              <div className="performance-item">
                <img
                  src={power_icon}
                  alt="power"
                  className="performance-icon"
                />
                <div>
                  <div className="performance-item-name">Power</div>
                  <div className="performance-item-value">
                    789 hp @ 8,500 rpm (588 kw)
                  </div>
                  <div className="progress-bar"></div>
                </div>
              </div>
            </motion.div>

            <div className="car-group">
              <motion.img
                initial={{
                  opacity: 0,
                  scale: 0,
                  // x: "-50%",
                  // translateY: "30%",
                }}
                whileInView={{
                  opacity: 1,
                  scale: 1,
                  // x: "-50%",
                  // translateY: "30%",
                }}
                transition={{ delay: 1.2, duration: 1 }}
                viewport={{ once: true }}
                src={circle}
                alt="car"
              />
              <motion.img
                initial={{
                  opacity: 0,
                  scale: 0,
                  x: "-50%",
                  translateY: "-25px",
                }}
                whileInView={{
                  opacity: 1,
                  scale: 1,
                  x: "-50%",
                  translateY: "-25px",
                }}
                transition={{ delay: 1.2, duration: 1 }}
                viewport={{ once: true }}
                src={main_cars[selectedCar]}
                alt="car"
                className="main-car"
              />
            </div>
          </div>
          <div className="brands mobile-show">
            <div className="d-flex gap-3 align-items-center">
              <div className="line"></div>
              <div className="brands-title">BRANDS</div>
              <div className="line"></div>
            </div>
            <div className="select">
              <img src={ferrari_icon} alt="ferrari icon" />
              <div
                className="selected-option"
                onClick={() => setShowDropdown(!showDropdown)}
              >
                Ferrari
              </div>
              <i className="fa">&#xf107;</i>
              <div className={`options ${showDropdown && "d-block"}`}>
                <div className="option">Ferrari</div>
                <div className="option">Ferrari</div>
              </div>
            </div>
            <div>
              <ul>
                {carName.map((name, index) => (
                  <li
                    key={index}
                    className={index === selectedCar ? "selected" : ""}
                  >
                    {name}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ delay: 0.6, duration: 2 }}
            viewport={{ once: true }}
          >
            <div className="cars-container">
              {cars.map((car, index) => (
                <div
                  className={selectedCar === index ? "active" : ""}
                  onClick={() => setSelectedCar(index)}
                  key={index}
                >
                  <div className="cars-box">
                    <img src={car} alt="car" />
                  </div>
                  <div className="cars-name">{carName[index]}</div>
                </div>
              ))}
            </div>
          </motion.div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Marketplace;
