import { motion } from "framer-motion";
import AnimatedTextWord from "../../components/AnimatedTextWord";
import Navbar from "../../components/Navbar";
import "./aboutHero.css";

const AboutHero = () => {
  return (
    <div className="about-hero">
      <div className="container">
        <Navbar />
        <div className="about-title">
          <AnimatedTextWord text="EMPOWERING YOUR PASSION FOR EXOTIC CARS." />
        </div>
        {/* <h1 className="text-shadow my-4">EXOTIC CAR TOKENIZATION.</h1> */}
        <motion.p
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ delay: 2, duration: 1 }}
          viewport={{ once: true }}
        >
          At Drivora, our mission is clear: to revolutionize the ownership and
          investment in exotic cars through the power of blockchain technology.
          We envision a world where the thrill of exotic car ownership is not
          only accessible but also profitable, turning your automotive passion
          into a valuable asset.
        </motion.p>
      </div>
    </div>
  );
};

export default AboutHero;
