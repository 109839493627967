import drivoraAxios from "./api";

// Utility function to handle API requests
const apiRequest = async (endpoint, params, headers) => {
  try {
    const { data } = await drivoraAxios.post(endpoint, params, {
      headers: {
        ...headers,
      },
    });
    return data;
  } catch (err) {
    console.error(`Error in ${endpoint} request:`, err);
    throw err; // Optionally, rethrow the error or handle it as needed
  }
};

export const getAllowance = (params) => apiRequest("getAllowance", params);
export const cancelOffer = (params) => apiRequest("cancelOffer", params);
export const createSignature = (params) =>
  apiRequest("createSignature", params);
