import drivoraAxios from "./api";

// Utility function to handle API requests
const apiRequest = async (endpoint, params, headers) => {
  try {
    const { data } = await drivoraAxios.post(endpoint, params, {
      headers: {
        ...headers,
      },
    });
    return data;
  } catch (err) {
    console.error(`Error in ${endpoint} request:`, err);
    throw err; // Optionally, rethrow the error or handle it as needed
  }
};

export const getNfts = (params) => apiRequest("getNfts", params);
export const getNftsByUser = (params) => apiRequest("getNftsByUser", params);
export const getNftById = (params) => apiRequest("getNftById", params);
export const getActivityById = (params) =>
  apiRequest("getActivityById", params);
export const getNftByTokenId = (params) =>
  apiRequest("getNftByTokenId", params);
export const getListedNftById = (params) =>
  apiRequest("getListedNftById", params);
export const mintNft = (params) => apiRequest("mintNft", params);
export const addTokenInfo = (params) =>
  apiRequest("addTokenInfo", params, "multipart/form-data");
export const updateListing = (params) => apiRequest("updateListing", params);
