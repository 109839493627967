import AboutHero from "../../sections/aboutHero";
import Inspiration from "../../sections/inspiration";
import Process from "../../sections/process";
import Story from "../../sections/story";
import AboutJoinUs from "../../sections/aboutJoinus";
import Footer from "../../components/Footer";

const About = () => {
  return (
    <div>
      <AboutHero />
      <Inspiration />
      <Process />
      <Story />
      <AboutJoinUs />
      <Footer />
    </div>
  );
};

export default About;
