import LandingHero from "../../sections/landingHero";
import Where from "../../sections/where";
import Why from "../../sections/why";
import Partnership from "../../sections/partnership";
import Featured from "../../sections/featured";
import Drivora from "../../sections/drivora";
import JoinUs from "../../sections/joinus";
import Footer from "../../components/Footer";

const Home = () => {  
  return (
    <div>
      <LandingHero />
      <Where />
      <Why />
      <Partnership />
      <Featured />
      <Drivora />
      <JoinUs />
      <Footer />
    </div>
  );
};

export default Home;
