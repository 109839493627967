import { motion } from "framer-motion";
import "./inspiration.css";

const Inspiration = () => {
  return (
    <div className="inspiration">
      <div className="container">
        <motion.div
          initial={{ opacity: 0, y: 200 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
        >
          <h4 className="add-vertical-line">INSPIRATION</h4>
          <div className="section-content">
            <h2>
              INNOVATION THAT
              <span className="text-shadow"> DRIVE US.</span>
            </h2>
          </div>
        </motion.div>
        <div className="section-content">
          <motion.p
            initial={{ opacity: 0, y: -50, x: -50, scale: 1.3 }}
            whileInView={{ opacity: 1, y: 0, x: 0, scale: 1 }}
            transition={{ delay: 0.6, duration: 1 }}
            viewport={{ once: true }}
            className="mt-5"
          >
            Drivora stands distinct in the luxury automotive market. Unlike
            traditional car ownership, we enable you to tokenize your vehicle
            vehicles, creating a new realm of investment opportunities and a
            simplified, secure way of managing assets. Our innovative platform
            transforms the way enthusiasts and investors interact with the
            luxury car market.
          </motion.p>
        </div>
      </div>
    </div>
  );
};

export default Inspiration;
