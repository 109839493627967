import { useState } from "react";
import Modal from "react-modal";
import "./modal.css";
import { toast } from "react-toastify";

const ListingModal = (props) => {
  const { showListingModal, setShowListingModal, handleListing } = props;

  const [sellPrice, setSellPrice] = useState();

  const handleClose = () => {
    setShowListingModal(false);
  };

  return (
    <Modal
      appElement={document.getElementById("root")}
      isOpen={showListingModal}
      onRequestClose={handleClose}
      overlayClassName={{
        base: "overlay-base",
        afterOpen: "offer-overlay-after",
        beforeClose: "overlay-before",
      }}
      className={{
        base: "content-base",
        afterOpen: "bid-content-after",
        beforeClose: "content-before",
      }}
      preventScroll={true}
    >
      <div className="modal-close">
        <i className="material-icons" onClick={handleClose}>
          close
        </i>
      </div>
      <h5 className="text-black modal-title">LIST FOR SALE</h5>
      <h5 className="mb-1 mt-4">PRICE</h5>
      <p className="mb-3">This item can be purchased as soon as it's listed.</p>
      <input
        type="text"
        className="modal-input mb-4"
        placeholder="Price"
        value={sellPrice}
        onChange={(e) => setSellPrice(e.target.value)}
      />
      <button
        className="modal-btn black-btn"
        onClick={() => {
          sellPrice > 0
            ? handleListing(sellPrice)
            : toast.error("Please input the price");
        }}
      >
        CONFIRM
      </button>
    </Modal>
  );
};

export default ListingModal;
