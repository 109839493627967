import * as yup from "yup";

const rfidValidationSchema = yup.object({
  first_name: yup.string().trim().required("First name is required."),
  last_name: yup.string().trim().required("Last name is required."),
  address1: yup.string().trim().required("Address line1 is required."),
  address2: yup.string().trim().required("Address line2 is required."),
  city: yup.string().trim().required("City is required."),
  state: yup.string().trim().required("State is required."),
  zip: yup.string().trim().required("Zip is required."),
});

export default rfidValidationSchema;
