import { motion } from "framer-motion";
import light from "../../assets/images/community-light.png";
import light2 from "../../assets/images/login-light2.png";
import "./community.css";

const Community = () => {
  return (
    <div className="community">
      <img src={light} alt="light" className="login-effect1" />
      <motion.div
        initial={{ opacity: 0, y: 200 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
        viewport={{ once: true }}
      >
        <p className="tracking-widest">GROW WITH US</p>
        <h2 className="text-shadow">JOIN OUR COMMUNITY.</h2>
      </motion.div>
      <div className="d-flex flex-column flex-md-row gap-4 gap-md-0">
        <motion.div
          initial={{ opacity: 0, x: -200 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
          className="community-box"
        >
          <img src={light2} alt="light2" className="community-light" />
          <div className="community-social">DISCORD</div>
          <div className="community-name">MEET OUR COMMUNITY.</div>
          <button className="normal-btn large d-flex align-items-center m-auto m-md-0">
            <i className="fab fa-discord me-2" style={{ fontSize: 18 }}></i>JOIN
            US
          </button>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, x: 200 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
          className="community-box"
        >
          <img src={light2} alt="light2" className="community-light" />
          <div className="community-social">TELEGRAM</div>
          <div className="community-name">
            STAY UP TO
            <br /> DATE.
          </div>
          <button className="normal-btn large d-flex align-items-center m-auto m-md-0">
            <i
              className="fab fa-telegram-plane me-2"
              style={{ fontSize: 20 }}
            ></i>
            JOIN US
          </button>
        </motion.div>
      </div>
    </div>
  );
};

export default Community;
