import React from "react";
import { Navigate } from "react-router-dom";

import { useIsAuthenticated } from "../store/hooks/auth";

const PrivateRoute = ({ children }) => {
  const isAuthenticated = useIsAuthenticated();
  return !!isAuthenticated ? children : <Navigate to="/auth/login" />;
};

export default PrivateRoute;
