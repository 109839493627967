export function formatSize(size) {
  if (size > 1024) {
    if (size / 1024 > 1024) {
      return `${(size / 1024 / 1024).toFixed(0)} MB`;
    } else {
      return `${(size / 1024).toFixed(0)} KB`;
    }
  }
  return `${size.toFixed(0)} B`;
}
