import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import ConfirmButtons from "../../components/ConfirmButtons";
import useYupValidationResolver from "../../store/hooks/useYupValidationResolver";
import { useSetCurrentToken } from "../../store/hooks/token";
import CustomInput from "../../components/CustomInput";
import { addTokenInfo } from "../../api/nft";
import basicDetailValidationSchema from "../../store/contexts/basicDetailValidationSchema";

import basic_asset from "../../assets/images/add-basic-asset.png";

const Step1 = ({ navigate, user, nftData }) => {
  const [isLoading, setIsLoading] = useState(false);
  const setCurrentToken = useSetCurrentToken();

  const resolver = useYupValidationResolver(basicDetailValidationSchema);
  const { control, handleSubmit, formState, setValue } = useForm({
    mode: "onSubmit",
    reValidateMode: "onBlur",
    resolver,
  });
  const { errors } = formState;

  useEffect(() => {
    if (nftData) {
      setValue("name", nftData.name);
      setValue("description", nftData.description);
    }
  }, [nftData, setValue]);

  const onSubmit = async (formValues) => {
    const formData = new FormData();

    for (const key in formValues) {
      if (formValues.hasOwnProperty(key)) {
        formData.append(key, formValues[key]);
      }
    }

    formData.append("userId", user.id);
    formData.append("currentStep", 2);

    if (nftData) {
      formData.append("id", nftData.id);
    }

    try {
      setIsLoading(true);
      const data = await addTokenInfo(formData);
      if (data.success) {
        setCurrentToken({ token: data.data.id });
        toast.success("Token info is added successfully!");
        navigate("/tokenize/2");
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
    setIsLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="basic-details background-gray">
        <img src={basic_asset} alt="basic_asset" className="basic-asset" />
        <div className="container">
          <div className="basic-details-contain">
            <h2 className="mb-1 text-shadow">BASIC DETAILS.</h2>
            <p className="mb-4">
              Please provide the following information about your exotic car to
              get started with the tokenization process.
            </p>
            <div className="mb-4">
              <p className="mb-2">Name</p>
              <Controller
                name="name"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <CustomInput
                    placeholder="Enter name or nickname"
                    type="text"
                    className=""
                    name="name"
                    error={errors.name ? errors.name.message : ""}
                    {...field}
                  />
                )}
              />
              <p className="description">
                Enter the full name or nickname of your vehicle (e.g., "Ferrari
                F8 Tributo" or "Arty’s Aventador"). No one exotic is the same,
                help personalize your asset.
              </p>
            </div>
            <div className="mb-4">
              <p className="mb-2">Description</p>
              <Controller
                name="description"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <textarea
                    name="description"
                    className="custom-input"
                    placeholder="Enter description here"
                    rows={7}
                    {...field}
                  />
                )}
              />
              <p className="description">
                Provide information about your car, including its key features,
                specifications, the story of how you got the car, and any unique
                attributes. This helps potential buyers understand the value,
                story and uniqueness of your vehicle.
              </p>
            </div>
            <ConfirmButtons isLoading={isLoading} />
          </div>
        </div>
      </div>
    </form>
  );
};

export default Step1;
