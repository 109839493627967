import { useEffect } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

import Home from "../pages/home";
import About from "../pages/about";
import Marketplace from "../pages/marketplace";
import Detail from "../pages/detail";
import NftMarketplace from "../pages/nft";
import Profile from "../pages/profile";
import EditProfile from "../pages/editProfile";
import Notification from "../pages/notification";
import Add from "../pages/addNew";
import AuthPage from "./AuthPage";
import AuthRoute from "./AuthRoute";
import PrivateRoute from "./PrivateRoute";
import { getUser } from "../api/user";
import { useAccessToken, useGetUser, useLogout } from "../store/hooks/auth";
import { setupAxios } from "../api/setupAxios";
import drivoraAxios from "../api/api";
import store from "../store";
import NewHome from "../pages/newHome";


const privateRoutes = [
  { path: "myhome", element: <Profile /> },
  { path: "myhome/:username", element: <Profile /> },
  { path: "edit-profile", element: <EditProfile /> },
  { path: "notification", element: <Notification /> },
  { path: "tokenize", element: <Add /> },
  { path: "tokenize/:step", element: <Add /> },
];

const AppRoutes = () => {
  const token = useAccessToken();
  const getUserHook = useGetUser();
  const logout = useLogout();
  const navigate = useNavigate();

  setupAxios(drivoraAxios, store);

  useEffect(() => {
    const checkUserAuthentication = async () => {
      if (token) {
        const data = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        if (data.exp < currentTime) {
          logout();
          navigate("/auth/login");
          return;
        }
        try {
          const res = await getUser();
          if (res.success) {
            getUserHook(res.user);
          }
        } catch (err) {
          logout();
          navigate("/auth/login");
        }
      }
    };

    checkUserAuthentication();
  }, [token, navigate, getUserHook, logout]);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/new-home" element={<NewHome />} />
      <Route path="/about" element={<About />} />
      <Route path="/vault" element={<Marketplace />} />
      <Route path="/detail/:tokenId" element={<Detail />} />
      <Route path="/marketplace" element={<NftMarketplace />} />
      <Route
        path="/auth/*"
        element={
          <AuthRoute>
            <AuthPage />
          </AuthRoute>
        }
      />
      {privateRoutes.map(({ path, element }, index) => (
        <Route
          key={index}
          path={path}
          element={<PrivateRoute>{element}</PrivateRoute>}
        />
      ))}
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default AppRoutes;
