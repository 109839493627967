import Navbar from "../../components/Navbar";
import UserInfo from "../../sections/userInfo";
import Footer from "../../components/Footer";
import shape from "../../assets/images/profile/shape.png";
import effect1 from "../../assets/images/login-light1.png";
import "./notification.css";
import { useUser } from "../../store/hooks/auth";

const notifications = [
  {
    id: 1,
    type: "Follow Request",
    description: "Jack sent you a follow request",
  },
  {
    id: 2,
    type: "Bid",
    description: "A New Bid Request on NFT #223",
  },
  {
    id: 3,
    type: "Follow Request",
    description: "Howard sent you a follow request",
  },
  {
    id: 4,
    type: "Like",
    description: "Karen M. liked your NFT #223",
  },
  {
    id: 5,
    type: "Bid",
    description: "A New Bid Request on NFT #223",
  },
  {
    id: 1,
    type: "Follow Request",
    description: "Jack sent you a follow request",
  },
  {
    id: 2,
    type: "Bid",
    description: "A New Bid Request on NFT #223",
  },
  {
    id: 3,
    type: "Follow Request",
    description: "Howard sent you a follow request",
  },
  {
    id: 4,
    type: "Like",
    description: "Karen M. liked your NFT #223",
  },
  {
    id: 5,
    type: "Bid",
    description: "A New Bid Request on NFT #223",
  },
];

const Notification = () => {
  const user = useUser();
  return (
    <div id="notification">
      <div className="container">
        <img src={effect1} alt="login effect" className="login-effect1" />
        <Navbar />
        <div className="main">
          <img src={shape} alt="shape" className="shape1" />
          <UserInfo notification currentUser={user} />
          <div className="nft-group-header">
            <div className="group-title">
              <span className="text-shadow text-white">NOTIFICATIONS.</span>
            </div>
            <div className="bottom-line"></div>
          </div>
          <div className="table-responsive mt-5">
            <table className="table table-dark table-hover">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Type</th>
                  <th scope="col">Description</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {notifications.map((notification, index) => {
                  if (notification.type === "Follow Request") {
                    return (
                      <tr key={index}>
                        <td>{notification.id}</td>
                        <td>
                          <i className="fas fa-user-plus me-2 me-lg-3"></i>
                          {notification.type}
                        </td>
                        <td className="follow">{notification.description}</td>
                        <td>
                          <div className="d-flex gap-2 gap-lg-4 align-items-center">
                            <span>
                              <i className="material-icons">
                                add_circle_outline
                              </i>{" "}
                              ADD
                            </span>
                            <div className="divider"></div>
                            <span>
                              <i className="fas fa-trash-alt"></i> DELETE
                            </span>
                          </div>
                        </td>
                      </tr>
                    );
                  }
                  if (notification.type === "Bid") {
                    return (
                      <tr key={index}>
                        <td>{notification.id}</td>
                        <td>
                          <i
                            className="far fa-check-square me-2 me-lg-3"
                            style={{ fontSize: 16 }}
                          ></i>
                          {notification.type}
                        </td>
                        <td className="bid">{notification.description}</td>
                        <td>
                          <div
                            className="d-flex align-items-center justify-content-between"
                            style={{ maxWidth: "200px" }}
                          >
                            <div>
                              <i className="far fa-sticky-note"></i> VIEW BID
                              PAGE
                            </div>
                            <i className="fas fa-arrow-right"></i>
                          </div>
                        </td>
                      </tr>
                    );
                  }
                  return (
                    <tr key={index}>
                      <td>{notification.id}</td>
                      <td>
                        <i
                          className="far fa-thumbs-up me-2 me-lg-3"
                          style={{ fontSize: 16 }}
                        ></i>
                        {notification.type}
                      </td>
                      <td className="like">{notification.description}</td>
                      <td></td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Notification;
