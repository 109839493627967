import { useState } from "react";
import Modal from "react-modal";
import AcceptModal from "./AcceptModal";
import { useUser } from "../../store/hooks/auth";
import createAvatar from "../../utils/createAvatar";
import "./modal.css";

const serviceFee = 0;

const AcceptDetailModal = (props) => {
  const { showAcceptDetailModal, setShowAcceptDetailModal } = props;
  const user = useUser();
  const [bidAmount, setBidAmount] = useState(0);
  const [showAcceptModal, setShowAcceptModal] = useState(false);

  const handleClose = () => {
    setShowAcceptDetailModal(false);
  };

  const handleAccept = () => {
    setShowAcceptDetailModal(false);
    setShowAcceptModal(true);
  };

  return (
    <>
      <Modal
        appElement={document.getElementById("root")}
        isOpen={showAcceptDetailModal}
        onRequestClose={handleClose}
        overlayClassName={{
          base: "overlay-base",
          afterOpen: "offer-overlay-after",
          beforeClose: "overlay-before",
        }}
        className={{
          base: "content-base",
          afterOpen: "bid-content-after",
          beforeClose: "content-before",
        }}
        preventScroll={true}
      >
        <div className="modal-close">
          <i className="material-icons" onClick={handleClose}>
            close
          </i>
        </div>
        <div className="d-flex align-items-center justify-content-between gap-2 mt-5">
          {user?.avatar ? (
            <img src={user.avatar} alt="avatar" className="nav-avatar" />
          ) : (
            <div
              initial={{ opacity: 0, scale: 0.5 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 1, delay: 0.5 }}
              viewport={{ once: true }}
              className="nav-avatar"
              style={{
                backgroundColor: createAvatar(user?.fullname).color,
              }}
            >
              {createAvatar(user?.fullname).name}
            </div>
          )}
          <p className="text-black">
            You are about to accept a bid for SF90 Stradale by Paul Smith.
          </p>
        </div>
        <div className="w-100 my-4">
          <h5 className="mb-2">500.34 ETH</h5>
          <div className="bottom-line mb-3"></div>
          <div className="d-flex align-items-center justify-content-between mb-1">
            <p>Service fee</p>
            <p className="text-black">
              <b>0 ETH</b>
            </p>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <p>Total bid amount</p>
            <p className="text-black">
              <b>500.34 ETH</b>
            </p>
          </div>
        </div>
        <button className="modal-btn black-btn" onClick={handleAccept}>
          ACCEPT BID
        </button>
        <button className="modal-btn white-btn mt-2" onClick={handleClose}>
          CANCEL
        </button>
      </Modal>

      <AcceptModal
        showAcceptModal={showAcceptModal}
        setShowAcceptModal={setShowAcceptModal}
        bidAmount={bidAmount}
      />
    </>
  );
};

export default AcceptDetailModal;
