import { motion } from "framer-motion";
import car from "../../assets/images/featured-car.png";
import icon1 from "../../assets/images/featured-icon1.png";
import icon2 from "../../assets/images/featured-icon2.png";
import icon3 from "../../assets/images/featured-icon3.png";
import icon4 from "../../assets/images/featured-icon4.png";
import icon5 from "../../assets/images/featured-icon5.png";
import icon6 from "../../assets/images/featured-icon6.png";
import icon7 from "../../assets/images/featured-icon7.png";
import icon8 from "../../assets/images/featured-icon8.png";
import icon9 from "../../assets/images/featured-icon9.png";
import icon10 from "../../assets/images/featured-icon10.png";
import "./featured.css";

const icons = [
  {
    icon: icon1,
    type: "Model",
    description: "Ferrari 812 Superfast",
  },
  {
    icon: icon2,
    type: "Units Produced",
    description: "799",
  },
  {
    icon: icon3,
    type: "Capacity",
    description: "6,486 cc",
  },
  {
    icon: icon4,
    type: "Torque",
    description: "5310lb ft @ 7,000 rpm (718 Nm)",
  },
  {
    icon: icon5,
    type: "Power",
    description: "789 hp @ 8,500 rpm (588 kW)",
  },
  {
    icon: icon6,
    type: "Engine",
    description: "V12 gasoline engine",
  },
  {
    icon: icon7,
    type: "Transmission",
    description: "7-Speed Automatic",
  },
  {
    icon: icon8,
    type: "Year",
    description: "2017",
  },
  {
    icon: icon9,
    type: "Kerb Weight",
    description: "1,774kh",
  },
  {
    icon: icon10,
    type: "0-100km/h",
    description: "2.9 sec",
  },
];

const Featured = () => {
  return (
    <div className="featured">
      <div className="container">
        <motion.div
          initial={{ opacity: 0, y: 200 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
        >
          <h4 className="add-vertical-line">SHOWCASE</h4>
          <h2>FEATURED CAR.</h2>
          <h4>FERRARI 812 SUPERFAST</h4>
          <p className="my-5">
            "Was sold In 2016 in Los Angeles for $1,550,000."
          </p>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 200 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
          className="my-5 d-flex justify-content-center gap-3 gap-sm-4 gap-md-5 align-items-sm-center"
        >
          <div>
            <h1>$450K</h1>
            <p>Sold by Ferrari in 2015</p>
          </div>
          <div className="divider"></div>
          <div>
            <h1>$998K</h1>
            <p>Current Value</p>
          </div>
          <div className="divider"></div>
          <div>
            <h1>20%</h1>
            <p>Total Target IRR until 2025</p>
          </div>
        </motion.div>
        <motion.img
          initial={{ opacity: 0, scale: 0 }}
          whileInView={{ opacity: 1, scale: 1 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
          src={car}
          alt="car"
        />
        <div className="features">
          {icons.map((item, index) => (
            <motion.div
              initial={{ opacity: 0, y: 100 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1, duration: 1 }}
              viewport={{ once: true }}
              key={index}
            >
              <img src={item.icon} alt="icon" />
              <p>{item.type}</p>
              <p className="text-white">{item.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Featured;
