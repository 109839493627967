import { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import NftGroup from "../../components/NftGroup";
import Footer from "../../components/Footer";
import AddNewNft from "../../sections/addNewNft";
import Blog from "../../sections/blog";
import Community from "../../sections/community";
import Welcome from "../../sections/welcome";
import shape from "../../assets/images/profile/shape.png";
import effect1 from "../../assets/images/login-light1.png";
import { useAccessToken } from "../../store/hooks/auth";

import trending_car1 from "../../assets/images/profile/trending-car1.png";
import trending_car2 from "../../assets/images/profile/trending-car2.png";
import trending_car3 from "../../assets/images/profile/trending-car3.png";
import trending_car4 from "../../assets/images/profile/trending-car4.png";
import trending_car5 from "../../assets/images/profile/trending-car5.png";
import trending_car6 from "../../assets/images/profile/trending-car6.png";
import featured_car1 from "../../assets/images/profile/feature-car1.png";
import featured_car2 from "../../assets/images/profile/feature-car2.png";
import featured_car3 from "../../assets/images/profile/feature-car3.png";
import viewed_car1 from "../../assets/images/profile/viewed-car1.png";
import viewed_car2 from "../../assets/images/profile/viewed-car2.png";
import viewed_car3 from "../../assets/images/profile/viewed-car3.png";
import featured_profile1 from "../../assets/images/profile/featured-profile-car1.png";
import featured_profile2 from "../../assets/images/profile/featured-profile-car2.png";
import featured_profile3 from "../../assets/images/profile/featured-profile-car3.png";
import featured_profile4 from "../../assets/images/profile/featured-profile-car4.png";
import know_car1 from "../../assets/images/profile/know-car1.png";
import know_car2 from "../../assets/images/profile/know-car2.png";
import know_car3 from "../../assets/images/profile/know-car3.png";
import { getNfts } from "../../api/nft";
import { toast } from "react-toastify";
import CustomNftGroup from "../../components/CustomNftGroup";

const trendingCar = [
  { img: trending_car1, badge: "New Release", info: true, footer: true },
  { img: trending_car2, badge: "New Release", info: true, footer: true },
  { img: trending_car3, badge: "New Release", info: true, footer: true },
  { img: trending_car4, badge: "New Release", info: true, footer: true },
  { img: trending_car5, badge: "New Release", info: true, footer: true },
  { img: trending_car6, badge: "New Release", info: true, footer: true },
];

const featuredCar = [
  { img: featured_car1, badge: "New Release", footer: true, price: 500 },
  { img: featured_car2, badge: "Limited Edition", footer: true, price: 300 },
  { img: featured_car3, badge: "Rare", footer: true, price: 200 },
];

const viewedCar = [
  { img: viewed_car1, footer: false, type: "SPORTS CARS" },
  { img: viewed_car2, footer: false, type: "VINTAGE CARS" },
  { img: viewed_car3, footer: false, type: "REGULAR CARS" },
];

const featuredProfile = [
  { img: featured_profile1, price: 500, footer: true, badge: "New Release" },
  {
    img: featured_profile2,
    price: 500,
    footer: true,
    badge: "Limited Edition",
  },
  { img: featured_profile3, price: 500, footer: true, badge: "Rare" },
  { img: featured_profile4, price: 500, footer: true, badge: "New Release" },
];

const knowCar = [
  { img: know_car1, price: 500, footer: true, badge: "New Release" },
  {
    img: know_car2,
    price: 300,
    footer: true,
    badge: "Limited Edition",
  },
  { img: know_car3, price: 200, footer: true, badge: "Rare" },
];

const NftMarketplace = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [nfts, setNfts] = useState();
  const token = useAccessToken();

  useEffect(() => {
    if (token) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  }, [token]);

  useEffect(() => {
    const getNft = async () => {
      try {
        const res = await getNfts();
        if (res.success) {
          setNfts(res.listings);
        }
      } catch (e) {
        toast.error(e);
      }
    };
    getNft();
  }, []);

  return (
    <div id="profile">
      <div className="container">
        <img src={effect1} alt="login effect" className="login-effect1" />
        <Navbar />
      </div>
      <Welcome />
      <div className="container">
        <div className="main">
          <img src={shape} alt="shape" className="shape3" />
          {loggedIn ? (
            <p className="tracking-widest welcome-txt">WELCOME BACK</p>
          ) : (
            <div className="move-top">
              <AddNewNft />
            </div>
          )}
          {nfts && nfts.length && (
            <CustomNftGroup title="Your Garage" nfts={nfts} />
          )}

          {/* {loggedIn && (
            <NftGroup title="YOUR TRENDING NFTs." cars={trendingCar} />
          )}
          <NftGroup title="FEATURED NFTs." cars={featuredCar} />
          <NftGroup
            title={loggedIn ? "RECENTLY VIEWED." : "FEATURED CATEGORY."}
            cars={viewedCar}
          />
          <NftGroup
            title="FEATURED PROFILES."
            cars={featuredProfile}
            rows={4}
          />
          <NftGroup title="PEOPLE YOU MAY KNOW." cars={knowCar} /> */}
          <AddNewNft />
          <Blog />
          <Community />
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default NftMarketplace;
