import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import createAvatar from "../../utils/createAvatar";
import share_icon from "../../assets/images/share-icon.png";
import useCopyClip from "../../store/hooks/useCopyClip";

const UserInfo = ({ notification, currentUser, countOfNft, editable }) => {
  const navigate = useNavigate();
  const { copyStatus, copyToClipboard } = useCopyClip();

  const dateFormate = (dateString) => {
    if (dateString) {
      const date = new Date(dateString);

      const options = { year: "numeric", month: "long" };
      const formatter = new Intl.DateTimeFormat("en-US", options);
      const formattedDate = formatter.format(date);
      return formattedDate;
    }
  };

  return (
    <>
      <div className="user-info">
        {currentUser?.avatar ? (
          <motion.img
            initial={{ opacity: 0, scale: 0.5 }}
            whileInView={{ opacity: 1, scale: 1 }}
            transition={{ duration: 1, delay: 0.5 }}
            viewport={{ once: true }}
            src={currentUser.avatar}
            alt="avatar"
            className="user-avatar"
          />
        ) : (
          <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            whileInView={{ opacity: 1, scale: 1 }}
            transition={{ duration: 1, delay: 0.5 }}
            viewport={{ once: true }}
            className="user-avatar"
            style={{
              backgroundColor: createAvatar(currentUser?.fullname).color,
            }}
          >
            {createAvatar(currentUser?.fullname).name}
          </motion.div>
        )}
        <motion.div
          initial={{ opacity: 0, x: 200 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 1, delay: 0.5 }}
          viewport={{ once: true }}
          className="user-info-content"
        >
          <p className="tracking-widest text-center text-sm-start mb-3">
            WELCOME BACK.
          </p>
          <h2 className="text-shadow text-center text-sm-start">
            {currentUser?.fullname}.
          </h2>
          <div className="d-flex justify-content-center justify-content-sm-start align-items-center gap-2 gap-sm-3 mt-3">
            <p>Member since {dateFormate(currentUser?.createdAt)}</p>
            <div className="divider"></div>
            <p>
              {currentUser?.type === 1
                ? "Public"
                : currentUser?.type === 2
                ? "Semi-Private"
                : "Private"}{" "}
              Profile
            </p>
            {!notification && editable && (
              <>
                <div className="divider"></div>
                <p
                  onClick={() => navigate("/edit-profile")}
                  className="z-3 edit-profile-txt hover-bottom"
                >
                  <i className="fas fa-pencil-alt"></i> Edit Profile
                </p>
              </>
            )}
          </div>
          <div className="d-flex justify-content-between align-items-center my-4">
            <div>
              <h2 className="tracking-wide">{countOfNft}</h2>
              <p>Tokenized Assets</p>
            </div>
            <div>
              <h2 className="tracking-wide">{currentUser?.follower}</h2>
              <p>Followers</p>
            </div>
            <div>
              <h2 className="tracking-wide">{currentUser?.following}</h2>
              <p>Following</p>
            </div>
            <div>
              <h2 className="tracking-wide">0</h2>
              <p>Offers</p>
            </div>
          </div>
          {notification && (
            <div className="d-flex gap-2 justify-content-center justify-content-sm-start">
              <button
                className="nav-btn"
                onClick={() => navigate("/edit-profile")}
              >
                <i className="fas fa-pencil-alt"></i> EDIT PROFILE
              </button>
              <button
                className="nav-btn"
                onClick={() =>
                  copyToClipboard(
                    `${process.env.REACT_APP_CLINET_URL}myhome/${currentUser.username}`
                  )
                }
              >
                {copyStatus ? (
                  <i className="fas fa-check"></i>
                ) : (
                  <img src={share_icon} alt="share_icon" />
                )}{" "}
                SHARE PROFILE
              </button>
            </div>
          )}
        </motion.div>
      </div>
    </>
  );
};

export default UserInfo;
