import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import Navbar from "../../components/Navbar";
import SliderComp from "../../components/Slider";
import ProductInfo from "../productInfo";
import "./single.css";

const DetailTop = () => {
  const navigate = useNavigate();
  return (
    <div className="single-top">
      <div className="container">
        <Navbar />
        <motion.div
          initial={{ opacity: 0, x: -200 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ delay: 1.1, duration: 0.5 }}
          viewport={{ once: true }}
          className="text-start ps-2"
        >
          <button
            className="text-grey font-albert text-decoration-none pe-auto bg-transparent border-0"
            onClick={() => navigate(-1)}
          >
            <i className="fas fa-chevron-left me-2"></i> Back
          </button>
        </motion.div>
        <motion.div className="container mb-5">
          <div className="row">
            <div className="col-lg-6 mt-5">
              <motion.div
                initial={{ opacity: 0, x: -200 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ delay: 1, duration: 1 }}
                viewport={{ once: true }}
              >
                <SliderComp />
              </motion.div>
            </div>
            <div className="col-lg-6 mt-5">
              <ProductInfo />
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default DetailTop;
