import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import image1 from "../../assets/images/why-image1.png";
import image2 from "../../assets/images/why-image2.png";
import image3 from "../../assets/images/why-image3.png";
import icon1 from "../../assets/images/icon1.png";
import icon2 from "../../assets/images/icon2.png";
import icon3 from "../../assets/images/icon3.png";
import icon4 from "../../assets/images/icon4.png";
import icon5 from "../../assets/images/icon5.png";
import icon6 from "../../assets/images/icon6.png";
import arrow_icon from "../../assets/images/arrow-icon.png";
import back_car from "../../assets/images/back-car.png";
import "./why.css";

const Why = () => {
  const navigate = useNavigate();

  return (
    <div className="position-relative">
      <img src={back_car} alt="back car" className="back-car" />
      <div className="why">
        <div className="container">
          <motion.div
            initial={{ opacity: 0, y: 200 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
            viewport={{ once: true }}
          >
            <h4 className="add-vertical-line">WHY</h4>
            <h2>
              WE ARE <span className="text-shadow">DIFFERENT.</span>
            </h2>
            <h4>THREE-TIERED PROFILE</h4>
          </motion.div>
          <div className="d-flex gap-4 gap-md-1 card-contain flex-column flex-md-row">
            <motion.div
              initial={{ opacity: 0, y: 200 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2, duration: 1 }}
              viewport={{ once: true }}
              className="why-card"
            >
              <img src={image1} alt="why-image" />
              <div className="card-content">
                <h5>.01</h5>
                <h2 className="mb-1 mb-lg-4">PUBLIC PROFILE.</h2>
                <p>
                  Showcase your cars to all users. Accept, reject, or negotiate
                  offers that exceed market value.
                </p>
              </div>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 200 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.6, duration: 1 }}
              viewport={{ once: true }}
              className="why-card"
            >
              <img src={image2} alt="why-image" />
              <div className="card-content">
                <h5>.02</h5>
                <h2 className="mb-1 mb-lg-4">EXCLUSIVE ACCESS.</h2>
                <p>
                  Limit visibility to friends or approved investors, maintaining
                  privacy while still engaging in active trading.
                </p>
              </div>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 200 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ delay: 1, duration: 1 }}
              viewport={{ once: true }}
              className="why-card"
            >
              <img src={image3} alt="why-image" />
              <div className="card-content">
                <h5>.03</h5>
                <h2 className="mb-1 mb-lg-4">PRIVATE VIEWING.</h2>
                <p>
                  Manage your portfolio in total privacy, accessible only to
                  you.
                </p>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
      <div className="how">
        <div className="container">
          <motion.div
            initial={{ opacity: 0, y: 200 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
            viewport={{ once: true }}
          >
            <h4 className="add-vertical-line">How</h4>
            <h2>
              IT <span className="text-shadow">WORKS.</span>
            </h2>
          </motion.div>
          <div className="mt-5 d-grid grid-container gap-5">
            <motion.div
              className="shadow-box"
              initial={{ opacity: 0, x: -200 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.3, duration: 1 }}
              viewport={{ once: true }}
            >
              <img src={icon1} alt="icon" />
              <h5>.01</h5>
              <h3 className="mb-4">
                REGISTER +<br /> VERIFY.
              </h3>
              <p>
                Join our community of elite car enthusiasts by creating your
                profile and digital wallet.
              </p>
              <img src={arrow_icon} alt="arrow icon" className="arrow-icon" />
            </motion.div>
            <motion.div
              className="shadow-box"
              initial={{ opacity: 0, x: -200 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.6, duration: 1 }}
              viewport={{ once: true }}
            >
              <img src={icon2} alt="icon" />
              <h5>.02</h5>
              <h3 className="mb-4">
                APPOINTMENT
                <br /> BOOKING.
              </h3>
              <p>
                Book an appointment through our platform to meet with a Drivora
                rep at a local car storage partner or at your home.
              </p>
              <img src={arrow_icon} alt="arrow icon" className="arrow-icon" />
            </motion.div>
            <motion.div
              className="shadow-box"
              initial={{ opacity: 0, x: -200 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.9, duration: 1 }}
              viewport={{ once: true }}
            >
              <img src={icon3} alt="icon" />
              <h5>.03</h5>
              <h3 className="mb-4">
                CAR
                <br /> TOKENIZATION.
              </h3>
              <p>
                The car is tokenized, creating a digital ownership record (NFT)
                that is stored in the user's digital wallet.
              </p>
              <img src={arrow_icon} alt="arrow icon" className="arrow-icon" />
            </motion.div>
            <motion.div
              className="shadow-box"
              initial={{ opacity: 0, x: 200 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ delay: 1.5, duration: 1 }}
              viewport={{ once: true }}
            >
              <img src={icon4} alt="icon" />
              <p>
                Register today to begin your journey with Drivora, where your
                luxury cars are not just driven, but driven forward.
              </p>
              <button
                className="normal-btn"
                onClick={() => navigate("/auth/register")}
              >
                REGISTER
              </button>
            </motion.div>
            <motion.div
              className="custom-shadow-box"
              initial={{ opacity: 0, x: 200 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ delay: 1.2, duration: 1 }}
              viewport={{ once: true }}
            >
              <img src={icon5} alt="icon" />
              <h5>.05</h5>
              <h3 className="mb-4">
                TRADE +<br /> MANAGE.
              </h3>
              <p>
                Monitor offers, negotiate deals, and manage your investments
                through our user-friendly platform.
              </p>
              <img src={arrow_icon} alt="arrow icon" className="arrow-icon" />
            </motion.div>
            <motion.div
              className="custom-shadow-box"
              initial={{ opacity: 0, x: 200 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.9, duration: 1 }}
              viewport={{ once: true }}
            >
              <img src={icon6} alt="icon" />
              <h5>.04</h5>
              <h3 className="mb-4">
                LIST ON THE <br /> MARKETPLACE.
              </h3>
              <p>
                Choose which tier to list your tokenized car, set your terms,
                and engage with potential investors.
              </p>
              <img src={arrow_icon} alt="arrow icon" className="arrow-icon" />
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Why;
