import React from "react";
import { Navigate } from "react-router-dom";

import { useIsAuthenticated } from "../store/hooks/auth";

const AuthRoute = ({ children }) => {
  const isAuthenticated = useIsAuthenticated();
  return !isAuthenticated ? children : <Navigate to="/myhome" />;
};

export default AuthRoute;
