import { useState } from "react";
import Modal from "react-modal";
import useWallet from "../../store/hooks/useWallet";
import StepModal from "./StepModal";
import "./modal.css";

const serviceFee = 0;

const BidModal = (props) => {
  const { showBidModal, setShowBidModal, nftData } = props;
  const [bidAmount, setBidAmount] = useState();
  const [showStepModal, setShowStepModal] = useState(false);

  const { disconnectWallet, balance, userInfo } =
    useWallet();

  const handleClose = () => {
    setShowBidModal(false);
  };

  const handleBid = () => {
    if (bidAmount > 0) {
      setShowBidModal(false);
      setShowStepModal(true);
    }
  };

  return (
    <>
      <Modal
        appElement={document.getElementById("root")}
        isOpen={showBidModal}
        onRequestClose={handleClose}
        overlayClassName={{
          base: "overlay-base",
          afterOpen: "offer-overlay-after",
          beforeClose: "overlay-before",
        }}
        className={{
          base: "content-base",
          afterOpen: "bid-content-after",
          beforeClose: "content-before",
        }}
        preventScroll={true}
      >
        <div className="modal-close">
          <i className="material-icons" onClick={handleClose}>
            close
          </i>
        </div>
        <h5 className="text-black modal-title">PLACE A BID</h5>
        <p className="text-black my-4">
          You are about to place a bid for {nftData?.name} by{" "}
          {nftData?.ownerData.fullname}.
        </p>
        <h5>YOUR BID</h5>
        <div className="w-100 my-4">
          <div className="d-flex align-items-center justify-content-between">
            <input
              placeholder="Enter bid"
              type="number"
              className="bid-input"
              value={bidAmount}
              onChange={(e) => setBidAmount(e.target.value)}
            />
            <p className="text-black">
              <b>ETH</b>
            </p>
          </div>
          <div className="bottom-line mb-3"></div>
          <div className="d-flex align-items-center justify-content-between">
            <p>Your balance</p>
            <p className="text-black">
              <b>{balance} ETH</b>
            </p>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <p>Service fee</p>
            <p className="text-black">
              <b>0 ETH</b>
            </p>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <p>Total bid amount</p>
            <p className="text-black">
              <b>{bidAmount - serviceFee || 0} ETH</b>
            </p>
          </div>
        </div>
        <button
          className="modal-btn black-btn"
          onClick={handleBid}
          disabled={balance < bidAmount || bidAmount == 0 || !bidAmount}
        >
          PLACE A BID
        </button>
        <button className="modal-btn white-btn mt-2" onClick={handleClose}>
          CANCEL
        </button>
      </Modal>

      <StepModal
        showStepModal={showStepModal}
        setShowStepModal={setShowStepModal}
        bidAmount={bidAmount}
      />
    </>
  );
};

export default BidModal;
