import React from "react";
import Slider from "react-slick";
import "./slider.css";
import { useNftData } from "../../store/hooks/token";

const images = [
  "/cars/car1.jpg",
  "/cars/car2.jpg",
  "/cars/car3.jpg",
  "/cars/car4.jpg",
];

const SliderComp = () => {
  const nftData = useNftData();

  const settings = {
    customPaging: function (i) {
      return (
        <a>
          <img
            src={nftData?.asset_files.split(",")[i] || images[i]}
            // src={images[i]}
            alt={`Thumbnail ${i + 1}`}
          />
        </a>
      );
    },
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  return (
    <div>
      {nftData?.asset_files.split(",").length > 1 ? (
        <Slider {...settings}>
          {nftData?.asset_files.split(",").map((src, index) => (
            <div key={index}>
              <img className="slider-image" src={src} alt={`${index + 1}`} />
            </div>
          ))}
        </Slider>
      ) : (
        <div>
          <img
            className="one-slider-image"
            src={nftData?.asset_files}
            alt={`nft`}
          />
        </div>
      )}
    </div>
  );
};

export default SliderComp;
