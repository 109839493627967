import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAccount } from "wagmi";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import CustomInput from "../../components/CustomInput";
import useWallet from "../../store/hooks/useWallet";
import useCopyClip from "../../store/hooks/useCopyClip";
import { register } from "../../api/user";
import { useLogin } from "../../store/hooks/auth";
import LoadingButton from "../../components/LoadingButton";

import registerImg from "../../assets/images/register-back.png";
import effect1 from "../../assets/images/login-light1.png";
import effect2 from "../../assets/images/login-light2.png";
import guard_icon from "../../assets/images/guard-icon.png";

const Register = () => {
  const { handleConnectWallet } = useWallet();
  const { address: account, isConnected } = useAccount();
  const navigate = useNavigate();
  const { copyToClipboard } = useCopyClip();
  const location = useLocation();
  const login = useLogin();

  const searchParams = new URLSearchParams(location.search);
  const step = Number(searchParams.get("step"));

  const [selectedType, setSelectedType] = useState();
  const [verifyMethod, setVerifyMethod] = useState();
  const [values, setValues] = useState({
    fullname: "",
    email: "",
    username: "",
    password: "",
  });
  const [errors, setErrors] = useState({
    fullname: "",
    email: "",
    username: "",
    password: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const emptyKeys = Object.entries(values)
      .filter(([key, value]) => value === "")
      .map(([key]) => key);

    if (emptyKeys.length !== 0) {
      navigate("/auth/register");
    }
  }, []);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    setValues((prev) => ({ ...prev, [name]: value }));

    if (value) {
      setErrors((prev) => ({ ...prev, [name]: "" }));
    } else {
      setErrors((prev) => ({ ...prev, [name]: `${name} cannot be empty` }));
    }
  };

  const validateForm = () => {
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    let newErrors = {};

    // Check for empty fields and invalid email
    if (!values.fullname) newErrors.fullname = "Full name cannot be empty";
    if (!values.email) newErrors.email = "Email cannot be empty";
    else if (!emailRegex.test(values.email))
      newErrors.email = "Email is invalid";
    if (!values.username) newErrors.username = "Username cannot be empty";
    if (!values.password) newErrors.password = "Password cannot be empty";
    else if (values.password.length < 6)
      newErrors.password = "Password must be longer than 6 characters";

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const goToSecondStep = () => {
    if (validateForm()) {
      navigate("?step=2");
    }
  };

  const goToThirdStep = () => {
    if (!isConnected) {
      toast.error("Please connect wallet");
      return;
    }
    if (selectedType) {
      navigate("?step=3");
    } else {
      toast.error("Please select the type of your profile");
    }
  };

  const goToFourthStep = async () => {
    const data = { ...values, type: selectedType, address: account };
    setIsLoading(true);
    try {
      const res = await register(data);
      if (res.success) {
        login({ token: res.token });
        navigate("?step=4");
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
    setIsLoading(false);
  };

  return (
    <div id="register">
      <div className="container">
        <img src={effect1} alt="login effect" className="login-effect1" />
        <Navbar />
        <div className="d-flex mt-5 position-relative">
          <img src={effect2} alt="login effect" className="login-effect2" />
          <div className="login-img">
            <h2>YOUR MARKETPLACE FOR TOKENIZED EXOTICS</h2>
            <img src={registerImg} alt="login" />
          </div>
          <div className="login-form">
            {(!step || step === 1) && (
              <div>
                <div className="login-title text-shadow">REGISTER.</div>
                <div className="login-description">
                  Your Gateway to Tokenized Exotic Car Ownership.
                </div>
                <div className="d-flex gap-1 justify-content-center">
                  <button className="social-icon-box">
                    <i
                      className="fab fa-facebook-f"
                      style={{ fontSize: "24px" }}
                    ></i>
                  </button>
                  <button className="social-icon-box">
                    <i
                      className="fab fa-google"
                      style={{ fontSize: "20px" }}
                    ></i>
                  </button>
                  <button className="social-icon-box">
                    <i
                      className="fab fa-apple"
                      style={{ fontSize: "24px" }}
                    ></i>
                  </button>
                </div>
                <div className="vertical-line"></div>
                <h4 className="mt-4">OR</h4>
                <CustomInput
                  placeholder="Full Name"
                  type="text"
                  className="mt-4"
                  name="fullname"
                  value={values.fullname}
                  error={errors.fullname}
                  onChange={handleChange}
                />
                <CustomInput
                  placeholder="Email Address"
                  type="email"
                  className="mt-3"
                  name="email"
                  value={values.email}
                  error={errors.email}
                  onChange={handleChange}
                />
                <CustomInput
                  placeholder="Create User Name"
                  type="text"
                  className="mt-3"
                  name="username"
                  value={values.username}
                  error={errors.username}
                  onChange={handleChange}
                />
                <CustomInput
                  placeholder="Password"
                  type="password"
                  className="mt-3"
                  name="password"
                  value={values.password}
                  error={errors.password}
                  onChange={handleChange}
                />
                <button
                  className="normal-btn large mt-5"
                  onClick={goToSecondStep}
                >
                  NEXT
                </button>
              </div>
            )}
            {step === 2 && (
              <div>
                <div className="login-title text-shadow">REGISTER.</div>
                <div className="login-description">
                  Your Gateway to Tokenized Car Ownership
                </div>
                <div className="login-title d-flex align-items-center justify-content-center mt-5">
                  SELECT PROFILE TYPE.{" "}
                  <i className="material-icons" style={{ color: "#6c6c6c" }}>
                    info_outline
                  </i>
                </div>
                <div className="button-group mt-3">
                  <button
                    className={`profile-btn ${
                      selectedType === 1 && "selectedType"
                    }`}
                    onClick={() => setSelectedType(1)}
                  >
                    PUBLIC PROFILE
                  </button>
                  <button
                    className={`profile-btn ${
                      selectedType === 2 && "selectedType"
                    }`}
                    onClick={() => setSelectedType(2)}
                  >
                    SEMI-PRIVATE PROFILE
                  </button>
                  <button
                    className={`profile-btn ${
                      selectedType === 3 && "selectedType"
                    }`}
                    onClick={() => setSelectedType(3)}
                  >
                    PRIVATE PROFILE
                  </button>
                </div>
                {selectedType === 1 && (
                  <div className="text-start">
                    <div className="login-description mb-1">
                      <span className="text-white">User Action:</span> Display
                      cars for public viewing.
                    </div>
                    <div className="login-description mt-1">
                      <span className="text-white">Platform Function:</span>{" "}
                      Cars listed under the public profile are visible to all
                      users. Other users can search for these cars, view
                      ownership details, and make offers. Owners can accept,
                      reject, or counter offers. An interactive portal for
                      negotiations (video/voice/text) opens upon offer
                      acceptance.
                    </div>
                  </div>
                )}
                {selectedType === 2 && (
                  <div className="text-start">
                    <div className="login-description mb-1">
                      <span className="text-white">User Action:</span> Display
                      cars to friends or approved users.
                    </div>
                    <div className="login-description mt-1">
                      <span className="text-white">Platform Function:</span>{" "}
                      Only friends or approved users can view and make offers on
                      the owner's collection, enhancing privacy and exclusivity.
                      This layer is for users interested in entertaining
                      above-market-value offers from a select group.
                    </div>
                  </div>
                )}
                {selectedType === 3 && (
                  <div className="text-start">
                    <div className="login-description mb-1">
                      <span className="text-white">User Action:</span> Keep car
                      details private.
                    </div>
                    <div className="login-description mt-1">
                      <span className="text-white">Platform Function:</span>{" "}
                      Cars in The Vault are only visible to the car owner. This
                      is the default layer for new NFTs/car tokens, ensuring
                      privacy until the owner decides to make them visible.
                    </div>
                  </div>
                )}
                <div className="login-title mt-5">CONNECT WALLET</div>
                {/* <CustomInput
                  placeholder="ABC Wallet-1"
                  type="text"
                  className="mt-3"
                />
                <CustomInput
                  placeholder="ABC Wallet-2"
                  type="text"
                  className="mt-2"
                />
                <CustomInput
                  placeholder="ABC Wallet-3"
                  type="text"
                  className="mt-2"
                /> */}
                <div className="text-center mb-5">
                  {!!account ? (
                    <button
                      className="normal-btn mt-4"
                      onClick={() => copyToClipboard(account)}
                    >
                      {account.substring(0, 15)}...
                      {account.substring(account.length - 4)}
                    </button>
                  ) : (
                    <button
                      className="normal-btn mt-4"
                      onClick={() => handleConnectWallet()}
                    >
                      CONNECT WALLET
                    </button>
                  )}
                </div>
                {/* <a href="#" className="d-block more mt-4 text-start">
                  SEE MORE OPTIONS <i className="fas fa-arrow-right"></i>
                </a> */}
                <button
                  className="normal-btn large mt-4"
                  onClick={goToThirdStep}
                >
                  NEXT
                </button>
              </div>
            )}
            {step === 3 && (
              <div>
                <div className="login-title text-shadow">
                  SECURE YOUR PROFILE.
                </div>
                <img src={guard_icon} alt="guard_icon" className="mt-5" />
                <div className="login-title mt-4">
                  VERIFY YOUR IDENTITY FOR SECURITY PURPOSE.
                </div>
                <div className="button-group mt-5">
                  <button
                    className={`profile-btn w-100 ${
                      verifyMethod === 1 && "selectedType"
                    }`}
                    onClick={() => setVerifyMethod(1)}
                  >
                    EMAIL
                  </button>
                  <button
                    className={`profile-btn w-100 ${
                      verifyMethod === 2 && "selectedType"
                    }`}
                    onClick={() => setVerifyMethod(2)}
                  >
                    PHONE
                  </button>
                  <button
                    className={`profile-btn w-100 ${
                      verifyMethod === 3 && "selectedType"
                    }`}
                    onClick={() => setVerifyMethod(3)}
                  >
                    BOTH
                  </button>
                </div>
                <LoadingButton
                  content="PROCEED"
                  isLoading={isLoading}
                  clickAction={goToFourthStep}
                  className="normal-btn large mt-5"
                />
              </div>
            )}
            {step === 4 && (
              <div>
                <div className="login-title text-shadow">REGISTER.</div>
                <div className="login-description">
                  Your Gateway to Tokenized Car Ownership
                </div>
                <i
                  className="far fa-check-circle mt-5 shadow-icon"
                  style={{ fontSize: "24px" }}
                ></i>
                <div className="login-title mt-4">
                  YOUR VERIFICATION HAS COMPLETED SUCCESSFULLY.
                </div>
                <button
                  className="normal-btn extra-large mt-5 d-block"
                  onClick={() => navigate("/marketplace")}
                >
                  PROCEED TO MARKETPLACE
                </button>
                <button
                  className="normal-btn extra-large mt-4 d-flex align-items-center justify-content-center gap-2"
                  onClick={() => navigate("/tokenize")}
                >
                  <i className="material-icons" style={{ color: "#bfbfbf" }}>
                    add_circle_outline
                  </i>{" "}
                  ADD A NFT
                </button>
              </div>
            )}
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Register;
