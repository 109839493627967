import { motion } from "framer-motion";
import { useNftData } from "../../store/hooks/token";

const icons = [
  {
    type: "Make",
    description: "Ferrari",
    value: "company_name",
  },
  {
    type: "Model",
    description: "812 Superfast",
    value: "car_model",
  },
  {
    type: "Units Produced",
    description: "799",
    value: "unit_produced",
  },
  {
    type: "Capacity",
    description: "6,486 cc",
    value: "mileage",
  },
  {
    type: "Torque",
    description: "5310lb ft @ 7,000 rpm (718 Nm)",
    value: "torque",
  },
  {
    type: "Power",
    description: "789 hp @ 8,500 rpm (588 kW)",
    value: "horse_power",
  },
  {
    type: "Engine",
    description: "V12 gasoline engine",
    value: "engine",
  },
  {
    type: "Transmission",
    description: "7-Speed Automatic",
    value: "transmission",
  },
  {
    type: "Year",
    description: "2017",
    value: "manufacture_year",
  },
  {
    type: "Kerb Weight",
    description: "1,774kh",
    value: "kerb_weight",
  },
  {
    type: "Top Speed",
    description: "340 km/h",
    value: "top_speed",
  },
  {
    type: "0-100km/h",
    description: "2.9 sec",
    value: "mhp",
  },
];

const Information = () => {
  const nftData = useNftData();

  return (
    <div className="container  pt-5 info">
      <motion.h5
        initial={{ opacity: 0, x: -200 }}
        whileInView={{ opacity: 1, x: 0 }}
        transition={{ delay: 2, duration: 0.3 }}
        viewport={{ once: true }}
        className="text-start"
      >
        INFORMATION
      </motion.h5>
      <div className="row text-start mt-5 mb-3">
        {icons.map((item, index) => (
          <motion.div
            initial={{ opacity: 0, y: 150 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.1 + 0.3, duration: 1 }}
            viewport={{ once: true }}
            key={index}
            className="col-md-2 col-sm-4 col-6 mb-5 d-flex flex-column gap-2 align-items-start"
          >
            <div className="info_img">
              <img src={`/images/info_` + (index + 1) + `.png`} alt="icon" />
            </div>
            <p>{item.type}</p>
            <p className="text-white">{nftData ? nftData[item.value] : ""}</p>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default Information;
