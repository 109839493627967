import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAccount } from "wagmi";
import { useGoogleLogin } from "@react-oauth/google";
import AppleLogin from "react-apple-login";

import { useLogin } from "../../store/hooks/auth";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import CustomInput from "../../components/CustomInput";
import LoadingButton from "../../components/LoadingButton";
import {
  forgotPassword,
  googleLogin,
  login,
  resetPassword,
} from "../../api/user";
import useWallet from "../../store/hooks/useWallet";
import useCopyClip from "../../store/hooks/useCopyClip";
import effect1 from "../../assets/images/login-light1.png";
import effect2 from "../../assets/images/login-light2.png";
import loginImg from "../../assets/images/login-back.jpg";

import "./login.css";

const Login = () => {
  const { handleConnectWallet, disconnectWallet } = useWallet();
  const { address: account, isConnected } = useAccount();
  const location = useLocation();
  const navigate = useNavigate();
  const { copyToClipboard } = useCopyClip();
  const searchParams = new URLSearchParams(location.search);
  const status = searchParams.get("status");
  const token = searchParams.get("token");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const loginActon = useLogin();

  const checkValidation = (e) => {
    if (e.target.value) {
      setErrors((prev) => ({ ...prev, [e.target.name]: "" }));
    } else {
      setErrors((prev) => ({
        ...prev,
        [e.target.name]: `${e.target.name} cannot be empty`,
      }));
    }
  };

  const handleLogin = async () => {
    if (!email) {
      setErrors((prev) => ({
        ...prev,
        email: "User name or email cannot be empty",
      }));
    }
    if (!password) {
      setErrors((prev) => ({ ...prev, password: "Password cannot be empty" }));
    }
    if (!email || !password) return;

    if (!isConnected) {
      toast.warning("Please connect wallet!");
      return;
    }

    const data = { email, password, address: account };
    setIsLoading(true);
    await login(data)
      .then((res) => {
        if (res.success) {
          loginActon({ token: res.token });
          // navigate("/myhome");
        } else {
          toast.error(res.message);
          if (res.message.includes("connect")) {
            disconnectWallet();
          }
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
    setIsLoading(false);
  };

  const validateForm = () => {
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    let newErrors = {};

    // Check for empty fields and invalid email
    if (!email) newErrors.email = "Email cannot be empty";
    else if (!emailRegex.test(email)) newErrors.email = "Email is invalid";

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const sendLink = async () => {
    if (validateForm()) {
      setIsLoading(true);
      try {
        const data = await forgotPassword({ email });
        if (data.success) {
          toast.success(data.message);
          navigate("?status=sendlink");
        } else {
          toast.error(data.message);
        }
      } catch (error) {
        toast.error(error.message);
      }
      setIsLoading(false);
    }
  };

  const handleReset = async () => {
    if (password !== confirmPassword) {
      setErrors((prev) => ({
        ...prev,
        confirmPassword: "Passwords do not match",
      }));
    } else {
      setErrors((prev) => ({
        ...prev,
        confirmPassword: "",
      }));
      try {
        const data = await resetPassword({ newPassword: password, token });
        if (data.success) {
          toast.success(data.message);
          navigate("/auth/login");
        } else {
          toast.error(data.message);
        }
      } catch (error) {
        toast.error(error.message);
      }
    }
  };

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        await googleLogin({ token: tokenResponse.access_token })
          .then((res) => {
            if (res.success) {
              loginActon({ token: res.token });
            } else {
              toast.error(res.message);
            }
          })
          .catch((err) => {
            toast.error(err.message);
          });
      } catch (err) {
        console.log(err);
      }
    },
  });

  return (
    <div id="login">
      <div className="container">
        <img src={effect1} alt="login effect" className="login-effect1" />
        <Navbar />
        <div className="d-flex mt-5 position-relative">
          <img src={effect2} alt="login effect" className="login-effect2" />
          <div className="login-img">
            <h2>YOUR MARKETPLACE FOR TOKENIZED EXOTICS</h2>
            <img src={loginImg} alt="login" />
          </div>
          <div className="login-form">
            {!status && !token && (
              <div>
                <div className="login-title text-shadow">LOGIN</div>
                <div className="login-description">
                  Your Gateway to Tokenized Exotic Car Ownership
                </div>
                <div className="d-flex gap-1 justify-content-center">
                  <button className="social-icon-box">
                    <i
                      className="fab fa-facebook-f"
                      style={{ fontSize: "24px" }}
                    ></i>
                  </button>
                  <button
                    className="social-icon-box"
                    onClick={() => {
                      if (!isConnected) {
                        toast.warning("Please connect wallet!");
                        return;
                      }
                      handleGoogleLogin();
                    }}
                  >
                    <i
                      className="fab fa-google"
                      style={{ fontSize: "20px" }}
                    ></i>
                  </button>
                  <button className="social-icon-box">
                    <i
                      className="fab fa-apple"
                      style={{ fontSize: "24px" }}
                    ></i>
                  </button>
                  {/* <AppleLogin
                    clientId="com.react.apple.login"
                    redirectURI="https://wmrs8.csb.app/"
                  /> */}
                  {/* <GoogleLogin
                    clientId={clientId}
                    buttonText="Login with Google"
                    onSuccess={onSuccess}
                    onFailure={onFailure}
                    cookiePolicy={"single_host_origin"}
                  /> */}
                </div>
                <div className="vertical-line"></div>
                <h4 className="mt-4">OR</h4>
                <CustomInput
                  placeholder="User Name or Email"
                  type="text"
                  icon="user"
                  className="mt-4"
                  value={email}
                  name="email"
                  onChange={(e) => {
                    setEmail(e.target.value);
                    checkValidation(e);
                  }}
                  error={errors.email}
                />
                <CustomInput
                  placeholder="Password"
                  type="password"
                  icon="key"
                  className="mt-3"
                  value={password}
                  name="password"
                  onChange={(e) => {
                    setPassword(e.target.value);
                    checkValidation(e);
                  }}
                  error={errors.password}
                />
                <div className="text-end">
                  <button
                    className="forgot mb-2"
                    onClick={() => navigate("?status=forgotpassword")}
                  >
                    FORGOT PASSWORD
                  </button>
                </div>
                <LoadingButton
                  content="LOGIN"
                  isLoading={isLoading}
                  clickAction={handleLogin}
                  className="normal-btn large mt-5"
                />
                <br />
                {!!account ? (
                  <button
                    className="normal-btn large mt-2"
                    onClick={() => copyToClipboard(account)}
                  >
                    {account.substring(0, 8)}...
                    {account.substring(account.length - 4)}
                  </button>
                ) : (
                  <button
                    className="normal-btn large mt-2"
                    onClick={() => handleConnectWallet()}
                  >
                    CONNECT WALLET
                  </button>
                )}
              </div>
            )}
            {status === "forgotpassword" && (
              <div>
                <div className="login-title text-shadow">FORGOT PASSWORD</div>
                <div className="login-description">
                  Lost Your Password? Securely Reset & Get Back on Track.
                </div>
                <div className="my-5">
                  <CustomInput
                    placeholder="Your Email"
                    type="email"
                    icon="user"
                    value={email}
                    name="email"
                    onChange={(e) => {
                      setEmail(e.target.value);
                      checkValidation(e);
                    }}
                    error={errors.email}
                  />
                </div>
                <LoadingButton
                  content="SEND LINK"
                  isLoading={isLoading}
                  clickAction={sendLink}
                  className="normal-btn large mt-5"
                />
                <div className="text-center">
                  <button
                    onClick={() => navigate(-1)}
                    className="forgot text-center mt-4"
                  >
                    GO BACK
                  </button>
                </div>
              </div>
            )}
            {status === "sendlink" && (
              <div>
                <div className="login-title text-shadow">FORGOT PASSWORD</div>
                <div className="login-description">
                  Lost Your Password? Securely Reset & Get Back on Track.
                </div>
                <i className="fas fa-key mt-4"></i>
                <div className="login-title mt-4">
                  PASSWORD RESET LINK HAS BEEN SENT TO YOUR EMAIL ADDRESS.
                </div>
                <button className="normal-btn large mt-5" onClick={sendLink}>
                  RESEND
                </button>
                <div className="text-center">
                  <button
                    onClick={() => navigate(-1)}
                    className="forgot text-center mt-4"
                  >
                    GO BACK
                  </button>
                </div>
              </div>
            )}
            {!status && token && (
              <div>
                <div className="login-title text-shadow">RESET PASSWORD</div>
                <CustomInput
                  placeholder="Password"
                  type="password"
                  icon="key"
                  className="mt-5"
                  value={password}
                  name="password"
                  onChange={(e) => {
                    setPassword(e.target.value);
                    checkValidation(e);
                  }}
                  error={errors.password}
                />
                <CustomInput
                  placeholder="Confirm password"
                  type="password"
                  icon="key"
                  className="mt-3"
                  value={confirmPassword}
                  name="confirmPassword"
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                    checkValidation(e);
                  }}
                  error={errors.confirmPassword}
                />
                <button
                  className="normal-btn large mt-5"
                  onClick={() => handleReset()}
                >
                  RESET
                </button>
              </div>
            )}
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Login;
