import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { motion } from "framer-motion";
import { useAccount } from "wagmi";
import { toast } from "react-toastify";
import { ethers } from "ethers";
import ConnectWalletModal from "../../components/modals/ConnectWalletModal";
import BidModal from "../../components/modals/BidModal";
import ListingModal from "../../components/modals/ListingModal";
import LoadingButton from "../../components/LoadingButton";
import useCopyClip from "../../store/hooks/useCopyClip";
import { useIsAuthenticated, useUser } from "../../store/hooks/auth";
import useWallet from "../../store/hooks/useWallet";
import { addFollow, addLike, unFollow, unLike } from "../../api/follow";
import { getNftByTokenId, updateListing } from "../../api/nft";
import { approveNFTWeb3 } from "../../web3/offer";
import { listItemWeb3 } from "../../web3/listing";

import "./productInfo.css";
import { useNftData, useSetNftData } from "../../store/hooks/token";
import { cancelOffer } from "../../api/offer";

const ProductInfo = () => {
  const { handleConnectWallet } = useWallet();
  const { address, isConnected, connector: activeConnector } = useAccount();
  const { copyStatus, copyToClipboard } = useCopyClip();
  const user = useUser();
  const nftData = useNftData();
  const isAuthenticated = useIsAuthenticated();
  const { tokenId } = useParams();
  const navigate = useNavigate();
  const setNftData = useSetNftData();

  const [isMine, setIsMine] = useState(false);
  const [isSentOffer, setIsSentOffer] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showConnectModal, setShowConnectModal] = useState(false);
  const [showListingModal, setShowListingModal] = useState(false);
  const [showBidModal, setShowBidModal] = useState(false);

  useEffect(() => {
    if (nftData) {
      setIsMine(user?.id === nftData.createdBy);
      nftData.offerHistory.forEach(({ offerer }) => {
        if (address === offerer) {
          setIsSentOffer(true);
        }
      });
    }
  }, [user, nftData, address]);

  const handleModal = () => {
    if (!isConnected) {
      setShowConnectModal(true);
    } else {
      setShowBidModal(true);
    }
    // setShowCounterModal(true);
  };

  const handleApiAction = async (
    apiFunc,
    params,
    successMessage,
    errorMessage
  ) => {
    if (isAuthenticated) {
      try {
        const res = await apiFunc(params);
        if (res.success) {
          toast.success(successMessage);
          const response = await getNftByTokenId({
            tokenId,
            currentUserId: user?.id,
          });
          if (response.success) {
            setNftData(response.data);
          }
        } else {
          toast.error(res.message);
        }
      } catch (error) {
        if (error?.response?.status === 403) {
          toast.error(error.response.data.message);
          navigate("/auth/login");
        } else {
          toast.error(errorMessage || error.message);
        }
      }
    } else {
      toast.warning("Please login first");
    }
  };

  const handleFollowing = () =>
    handleApiAction(
      addFollow,
      { followed_id: nftData.createdBy },
      "Successfully followed",
      "Failed to follow"
    );

  const handleUnFollowing = () =>
    handleApiAction(
      unFollow,
      { followed_id: nftData.createdBy },
      "Successfully unfollowed",
      "Failed to unfollow"
    );

  const handleLike = () =>
    handleApiAction(
      addLike,
      { tokenId: tokenId },
      "Successfully liked",
      "Failed to like"
    );

  const handleUnLike = () =>
    handleApiAction(
      unLike,
      { tokenId: tokenId },
      "Successfully unliked",
      "Failed to unlike"
    );

  const handleCancelOffer = () =>
    handleApiAction(
      cancelOffer,
      { tokenId: tokenId },
      "Successfully unliked",
      "Failed to unlike"
    );

  const handleListing = async (sellPrice) => {
    setShowListingModal(false);
    setIsLoading(true);
    try {
      if (!isConnected) {
        await handleConnectWallet();
      }
      const isApproveNFT = await approveNFTWeb3(
        address,
        process.env.REACT_APP_DRIVORA_ADDRESS
      );
      if (isApproveNFT) {
        const payMethod = 0;
        const nftAddress = process.env.REACT_APP_DRIVORA_ADDRESS;
        const payToken = process.env.REACT_APP_PAY_TOKEN_ADDRESS;
        const deadLine = process.env.REACT_APP_LISTING_DEADLINE;
        const price = ethers.utils.parseEther(sellPrice).toString(10);

        const isListItem = await listItemWeb3(
          nftAddress,
          tokenId,
          payMethod,
          payToken,
          price,
          deadLine
        );
        if (isListItem && isListItem.success) {
          const res = await updateListing({
            nftAddress,
            tokenId,
            payMethod,
            payToken,
            price,
            deadLine,
            live: 1,
            tx: isListItem.hash,
          });

          if (res.success) {
            // loadData();
            toast.success("Successfully listed for sale!");
            navigate(-1);
          } else {
            toast.error(res.message);
          }
        }
      }
    } catch (err) {
      if (err.code === 4001) toast.warning(err.message);
      else toast.warning("Error happened while processing.");
    }
    setIsLoading(false);
  };

  const handleEdit = () => {};

  return (
    <div className="product-info ps-sm-3 text-start">
      <motion.div
        initial={{ opacity: 0, x: 200 }}
        whileInView={{ opacity: 1, x: 0 }}
        transition={{ delay: 1.5, duration: 0.5 }}
        viewport={{ once: true }}
      >
        {/* <h5>FERRARI</h5> */}
        <h2 className="text-shadow text-start">
          {nftData?.name ? nftData?.name : "SF90 STRADALE."}
        </h2>
      </motion.div>
      <motion.div
        initial={{ opacity: 0, x: 200 }}
        whileInView={{ opacity: 1.2, x: 0 }}
        transition={{ delay: 1.7, duration: 0.5 }}
        viewport={{ once: true }}
      >
        <div className="actions pt-3 pb-3 d-flex align-items-center gap-3">
          <p>
            <i className="far fa-thumbs-up text-gr ey"></i>&nbsp;{" "}
            {nftData?.likes}{" "}
            <span className="text-grey">
              {nftData?.likes > 1 ? "LIKES" : "LIKE"}
            </span>
          </p>
          {!isMine && (
            <>
              {nftData?.isLike ? (
                <button
                  className="btn btn-outline-light"
                  onClick={handleUnLike}
                >
                  <i className="far fa-thumbs-down"></i> UNLIKE
                </button>
              ) : (
                <button className="btn btn-outline-light" onClick={handleLike}>
                  <i className="far fa-thumbs-up"></i> LIKE
                </button>
              )}

              <button
                className="btn btn-outline-light"
                onClick={() => copyToClipboard(window.location.href)}
              >
                {copyStatus ? (
                  <i className="fas fa-check"></i>
                ) : (
                  <i className="fas fa-external-link-alt"></i>
                )}{" "}
                SHARE
              </button>
            </>
          )}
        </div>
      </motion.div>
      <motion.div
        initial={{ opacity: 0, x: 200 }}
        whileInView={{ opacity: 1, x: 0 }}
        transition={{ delay: 1.9, duration: 0.5 }}
        viewport={{ once: true }}
      >
        <div className="price-box d-flex gap-3 gap-sm-5">
          <div className="price">
            <h5>PRICE</h5>
            <h3>
              <i className="fab fa-ethereum"></i>{" "}
              {`${
                nftData?.price === undefined
                  ? "N/A"
                  : ethers.utils.formatEther(nftData.price)
              }`}
            </h3>
          </div>
          <div className="owner ps-3 ps-sm-5 d-flex align-items-center align-items-sm-end gap-2">
            <div>
              <h5>OWNED BY</h5>
              <h3>
                {isMine ? (
                  "You"
                ) : (
                  <a
                    href={`${process.env.REACT_APP_CLINET_URL}myhome/${nftData?.ownerData.username}`}
                    target="_blank"
                    rel="noreferrer"
                    className="text-white"
                  >
                    {nftData?.ownerData.fullname}
                  </a>
                )}
              </h3>
            </div>
            {!isMine && (
              <>
                {nftData?.isFollowing ? (
                  <button
                    className="btn btn-outline-light"
                    onClick={handleUnFollowing}
                  >
                    UnFollow
                  </button>
                ) : (
                  <button
                    className="btn btn-outline-light"
                    onClick={handleFollowing}
                  >
                    Follow
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      </motion.div>
      <motion.div
        initial={{ opacity: 0, x: 200 }}
        whileInView={{ opacity: 1, x: 0 }}
        transition={{ delay: 2.1, duration: 0.5 }}
        viewport={{ once: true }}
      >
        <div className="mt-4 mb-4">
          <h5>DESCRIPTION</h5>
          <p>{nftData?.description}</p>
        </div>
      </motion.div>

      <motion.div
        initial={{ opacity: 0, x: 200 }}
        whileInView={{ opacity: 1, x: 0 }}
        transition={{ delay: 2.3, duration: 0.5 }}
        viewport={{ once: true }}
      >
        {isMine ? (
          <>
            {nftData?.live ? (
              <button
                className="btn btn-outline-light offer"
                onClick={handleEdit}
              >
                EDIT
              </button>
            ) : (
              <LoadingButton
                className="btn btn-outline-light offer"
                clickAction={() => setShowListingModal(true)}
                isLoading={isLoading}
                content="LIST FOR SALE"
              />
            )}
          </>
        ) : (
          <>
            {isSentOffer ? (
              <button
                className="btn btn-outline-light offer"
                onClick={handleCancelOffer}
              >
                <i className="far fa-check-circle"></i> CANCEL OFFER
              </button>
            ) : (
              <button
                className="btn btn-outline-light offer"
                onClick={handleModal}
              >
                <i className="far fa-check-circle"></i> MAKE OFFER
              </button>
            )}
          </>
        )}
      </motion.div>
      <ConnectWalletModal
        showConnectModal={showConnectModal}
        setShowConnectModal={setShowConnectModal}
      />
      <BidModal
        showBidModal={showBidModal}
        setShowBidModal={setShowBidModal}
        nftData={nftData}
      />
      <ListingModal
        showListingModal={showListingModal}
        setShowListingModal={setShowListingModal}
        handleListing={handleListing}
      />
    </div>
  );
};

export default ProductInfo;
