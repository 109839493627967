import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import { useCurrentTokenId } from "../../store/hooks/token";
import { getNftById } from "../../api/nft";
import StepBar from "../../components/StepBar";
import { useUser } from "../../store/hooks/auth";
import Step1 from "../../sections/addSteps/step1";
import Step2 from "../../sections/addSteps/step2";
import Step3 from "../../sections/addSteps/step3";
import Step4 from "../../sections/addSteps/step4";
import Step5 from "../../sections/addSteps/step5";
import Step6 from "../../sections/addSteps/step6";

import effect1 from "../../assets/images/login-light1.png";
import shape from "../../assets/images/profile/shape.png";

import "./add.css";

const Add = () => {
  const { step } = useParams();
  const navigate = useNavigate();
  const user = useUser();
  const token = useCurrentTokenId();
  const [nftData, setNftData] = useState();

  useEffect(() => {
    const getNft = async () => {
      try {
        const res = await getNftById({ id: token.token });
        if (res.success) {
          setNftData(res.data);
        }
      } catch (e) {
        toast.error(e);
      }
    };
    if (token.token) {
      getNft();
    }
  }, [token, navigate]);

  const Steps = () => {
    const stepNumber = parseInt(step, 10);

    const stepComponents = {
      1: Step1,
      2: Step2,
      3: Step3,
      4: Step4,
      5: Step5,
      6: Step6,
    };

    const StepComponent = stepComponents[stepNumber] || Step1;

    return (
      <StepComponent
        navigate={navigate}
        user={user}
        token={token.token}
        nftData={nftData}
      />
    );
  };

  return (
    <div id="add">
      <div className="background-gray">
        <div className="container">
          <img src={effect1} alt="login effect" className="login-effect1" />
          <Navbar />
          <div className="main">
            <img src={shape} alt="shape" className="shape1" />
            <h2 className="text-shadow">TOKENIZE YOUR VEHICLE.</h2>
          </div>

          <StepBar currentStep={step} />
        </div>
      </div>
      <Steps />
      <div className="background-gray">
        <Footer />
      </div>
    </div>
  );
};

export default Add;
