import { motion } from "framer-motion";
import twitter from "../assets/images/twitter.png";

const Footer = () => {
  return (
    <div className="footer">
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ delay: 1, duration: 1 }}
        viewport={{ once: true }}
      >
        <div className="d-flex justify-content-center align-items-center gap-5 my-5">
          <i className="fab fa-facebook-f" style={{ fontSize: "24px" }}></i>
          <img src={twitter} alt="twitter" />
          <i className="fab fa-instagram" style={{ fontSize: "24px" }}></i>
        </div>
      </motion.div>
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ delay: 1.3, duration: 1 }}
        viewport={{ once: true }}
      >
        <div className="d-flex justify-content-center align-items-center gap-4">
          <p>@2024 Drivora. All Rights Reserved.</p>
          <div className="divider"></div>
          <p>Terms of Service.</p>
          <div className="divider"></div>
          <p>Privacy Policy</p>
        </div>
      </motion.div>
    </div>
  );
};

export default Footer;
