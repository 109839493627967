import React from 'react';

const CustomInput = React.forwardRef((props, ref) => {
  const {
    name,
    placeholder,
    className,
    type,
    icon,
    value,
    onChange,
    error,
    ...fields
  } = props;

  return (
    <>
      <div className={`${className} position-relative`}>
        <input
          ref={ref}
          name={name}
          type={type}
          className={`${icon && "ps-5"} custom-input`}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          {...fields}
        />
        {icon === "user" && <i className="fas fa-user input-icon"></i>}
        {icon === "key" && <i className="fas fa-lock input-icon"></i>}
      </div>
      <p className="text-danger mt-1 text-start">{error}</p>
    </>
  );
});

export default CustomInput;