import { motion } from "framer-motion";
import car from "../../assets/images/drivora-car.png";
import graphic from "../../assets/images/graphic.png";
import image1 from "../../assets/images/drivora-image1.png";
import image2 from "../../assets/images/drivora-image2.png";
import image3 from "../../assets/images/drivora-image3.png";
import "./drivora.css";

const Drivora = () => {
  return (
    <div className="drivora">
      <img src={graphic} alt="graphic" className="graphic" />
      <div className="container">
        <img src={car} alt="car" />
        <motion.div
          initial={{ opacity: 0, y: 200 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
        >
          <h4 className="add-vertical-line">WHY</h4>
          <h2 className="text-shadow">DRIVORA?</h2>
        </motion.div>
        <div className="d-flex gap-4 gap-md-1 card-contain flex-column flex-md-row">
          <motion.div
            initial={{ opacity: 0, x: -200 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.2, duration: 1 }}
            viewport={{ once: true }}
            className="why-card"
          >
            <img src={image1} alt="why-asset" />
            <div className="card-content">
              <h2 className="mb-1 mb-lg-4">LUXURY MEETS INNOVATION.</h2>
              <p>
                Drivora caters to those who seek excellence in all facets of
                life, reflecting the prestige and refinement of the luxury
                assets it handles.
              </p>
            </div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: -200 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.4, duration: 1 }}
            viewport={{ once: true }}
            className="why-card"
          >
            <img src={image2} alt="why-asset" />
            <div className="card-content">
              <h2 className="mb-1 mb-lg-4">SECURITY + TRUST.</h2>
              <p>
                Drivora uses blockchain technology to ensure every transaction
                is secure, transparent, and compliant with global standards.
              </p>
            </div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: -200 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.6, duration: 1 }}
            viewport={{ once: true }}
            className="why-card"
          >
            <img src={image3} alt="why-asset" />
            <div className="card-content">
              <h2 className="mb-1 mb-lg-4">EXCLUSIVE NETWORK.</h2>
              <p>
                Join an elite community of luxury car enthusiasts and investors.
                Drivora is more than a marketplace - it's a hub for passion and
                growth.
              </p>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Drivora;
