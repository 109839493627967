import { useState } from "react";
import { motion } from "framer-motion";
import { ethers } from "ethers";
import CounterModal from "../../components/modals/CounterModal";
import AcceptDetailModal from "../../components/modals/AcceptDetailModal";
import { useNftData } from "../../store/hooks/token";

const offers = [
  {
    event: "Transfer",
    usd: "$3,000.00",
    price: "<0.0001 ETH",
    quantity: "1",
    differ: "29% below",
    from: "4ABD79",
    date: "25min ago",
  },
  {
    event: "Sale",
    usd: "$3,000.00",
    price: "<0.0001 ETH",
    quantity: "1",
    differ: "29% below",
    from: "John Doe",
    date: "in 4hours",
  },
  {
    event: "List",
    usd: "$3,000.00",
    price: "<0.0001 ETH",
    quantity: "1",
    differ: "29% below",
    from: "6GHD89",
    date: "in 8hours",
  },
  {
    event: "Transfer",
    usd: "$3,000.00",
    price: "<0.0001 ETH",
    quantity: "1",
    differ: "29% below",
    from: "Jane Smith",
    date: "in 4days",
  },
  {
    event: "Mint",
    usd: "$3,000.00",
    price: "<0.0001 ETH",
    quantity: "1",
    differ: "29% below",
    from: "John Doe",
    date: "in 5days",
    class: "disabled",
  },
];

const Offers = () => {
  const [showCounterModal, setShowCounterModal] = useState(false);
  const [showAcceptDetailModal, setShowAcceptDetailModal] = useState(false);
  const nftData = useNftData();

  return (
    <div className="container  pt-5">
      <motion.h5
        initial={{ opacity: 0, x: -200 }}
        whileInView={{ opacity: 1, x: 0 }}
        transition={{ delay: 1, duration: 0.3 }}
        viewport={{ once: true }}
        className="text-start mb-3"
      >
        OFFERS
      </motion.h5>
      <motion.div
        initial={{ opacity: 0, y: 200 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
        viewport={{ once: true }}
      >
        <div className="table-responsive">
          {nftData?.offerHistory.length > 0 ? (
            <table className="table table-dark table-hover">
              <thead>
                <tr>
                  <th scope="col">Price</th>
                  <th scope="col">USD Price</th>
                  <th scope="col">Quantity</th>
                  <th scope="col">Floor Difference</th>
                  <th scope="col">Expiration</th>
                  <th scope="col">From</th>
                  <th scope="col"></th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {nftData?.offerHistory.map((offer, index) => (
                  // {offers.map((offer, index) => (
                  <tr key={index}>
                    <td>{ethers.utils.formatEther(offer.price)} ETH</td>
                    <td>{offer.usd}</td>
                    <td>1</td>
                    <td>{offer.differ}</td>
                    <td>{offer.date}</td>
                    <td>{offer.from}</td>
                    <td style={{ width: "24%" }}>
                      <button className="offer-btn me-1 mb-1">Counter</button>
                      <button className="offer-btn accept-btn mb-1">
                        Accept
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div>
              <h3 className="my-5">No offers</h3>
            </div>
          )}
        </div>
      </motion.div>

      <CounterModal
        showCounterModal={showCounterModal}
        setShowCounterModal={setShowCounterModal}
      />
      <AcceptDetailModal
        showAcceptDetailModal={showAcceptDetailModal}
        setShowAcceptDetailModal={setShowAcceptDetailModal}
      />
    </div>
  );
};

export default Offers;
