import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import { ethers } from "ethers";
import { getActivityById } from "../../api/nft";
import { useNftData } from "../../store/hooks/token";

import "./activity.css";

// const activities = [
//   {
//     event: "Transfer",
//     icon: "fas fa-sync",
//     price: "<0.0001 ETH",
//     from: "BB9117",
//     to: "CEBA7B",
//     date: "6d ago",
//   },
//   {
//     event: "Sale",
//     icon: "fas fa-shopping-cart",
//     price: "<0.0001 ETH",
//     from: "BB9117",
//     to: "",
//     date: "6d ago",
//   },
//   {
//     event: "List",
//     icon: "fas fa-th-large",
//     price: "<0.0001 ETH",
//     from: "BB9117",
//     to: "",
//     date: "7d ago",
//   },
//   {
//     event: "Transfer",
//     icon: "fas fa-sync",
//     price: "<0.0001 ETH",
//     from: "D80135",
//     to: "BB9117",
//     date: "20d ago",
//   },
//   {
//     event: "Mint",
//     icon: "fas fa-bahai",
//     price: "<0.0001 ETH",
//     from: "NullAddress",
//     to: "D80135",
//     date: "22d ago",
//     class: "disabled",
//   },
// ];

const nftAddress = process.env.REACT_APP_DRIVORA_ADDRESS;

const getAdrName = (address) => {
  return address?.substring(2, 8).toUpperCase();
};

const localDate = (dateData) => {
  var localDate = new Date(dateData).toLocaleString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
  return localDate;
};

const Activity = () => {
  const nftData = useNftData();
  const [activities, setActivities] = useState();

  useEffect(() => {
    const getActivity = async () => {
      try {
        const res = await getActivityById({
          tokenId: nftData.tokenId,
          nftAddress,
        });
        if (res.success) {
          setActivities(res.activity);
          console.log("activities ==>", res.activity);
        }
      } catch (e) {
        toast.error(e);
      }
    };
    if (nftData) {
      getActivity();
    }
  }, [nftData]);

  return (
    <div className="container pt-5">
      <motion.h5
        className="text-start mb-3"
        initial={{ opacity: 0, x: -200 }}
        whileInView={{ opacity: 1, x: 0 }}
        transition={{ delay: 1, duration: 0.3 }}
        viewport={{ once: true }}
      >
        ACTIVITY
      </motion.h5>
      <motion.div
        initial={{ opacity: 0, y: 300 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
        viewport={{ once: true }}
      >
        <div className="table-responsive">
          {activities?.length > 0 ? (
            <table className="table table-dark table-hover">
              <thead>
                <tr>
                  <th scope="col">Event</th>
                  <th scope="col">Price</th>
                  <th scope="col">From</th>
                  <th scope="col">To</th>
                  <th scope="col">Date</th>
                </tr>
              </thead>
              <tbody>
                {activities.map((activity, index) => (
                  <tr key={index}>
                    <td>
                      {!!activity.from_address &&
                        (activity.from_address ===
                        "0x0000000000000000000000000000000000000000" ? (
                          <>
                            <i className="fas fa-bahai" /> Mint
                          </>
                        ) : (
                          <>
                            <i className="fas fa-sync"></i> Transfer
                          </>
                        ))}
                    </td>
                    <td>
                      {!!activity.price &&
                        activity.price > 0 &&
                        ethers.utils.formatEther(activity.price) + " ETH"}
                    </td>
                    <td>
                      {!!activity.from_address &&
                        (activity.from_address ===
                        "0x0000000000000000000000000000000000000000"
                          ? "NullAddress"
                          : getAdrName(activity.from_address))}
                      {getAdrName(activity.seller) ||
                        getAdrName(activity.creatorAddress)}
                    </td>
                    <td>
                      {!!activity.to_address && getAdrName(activity.to_address)}
                      {getAdrName(activity.offerer) ||
                        getAdrName(activity.buyerAddress)}
                    </td>
                    <td>{localDate(activity.block_timestamp)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div>
              <h3 className="my-5">No activity</h3>
            </div>
          )}
        </div>
      </motion.div>
    </div>
  );
};

export default Activity;
