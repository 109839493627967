import * as yup from "yup";

// const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
// const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
const nameRegex = /^[a-zA-Z\s]*$/;

const basicDetailValidationSchema = yup.object({
  name: yup.string().trim().required("Name is required."),
  description: yup.string().trim().required("Description is required."),
});

export default basicDetailValidationSchema;
