import { useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useAccount } from "wagmi";
import rfid_asset from "../../assets/images/rfid-asset.png";
import { Controller, useForm } from "react-hook-form";
import CustomInput from "../../components/CustomInput";
import useYupValidationResolver from "../../store/hooks/useYupValidationResolver";
import rfidValidationSchema from "../../store/contexts/rfidValidationSchema";
import { addTokenInfo } from "../../api/nft";
import { mint } from "../../web3/nft";
import useWallet from "../../store/hooks/useWallet";

const Step6 = ({ navigate, token, user }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { handleConnectWallet } = useWallet();
  const { isConnected } = useAccount();

  const resolver = useYupValidationResolver(rfidValidationSchema);
  const { control, handleSubmit, formState } = useForm({
    mode: "onSubmit",
    reValidateMode: "onBlur",
    // resolver,
  });
  const { errors } = formState;

  const onSubmit = async (formValues) => {
    if (isConnected) {
      const formData = new FormData();
      formData.append("id", token);
      formData.append("userId", user.id);
      formData.append("currentStep", 7);

      try {
        setIsLoading(true);
        const res = await mint();
        if (res.success) {
          formData.append("tokenId", res.tokenId);
          const data = await addTokenInfo(formData);
          if (data.success) {
            toast.success("Token info is updated successfully!");
            navigate("/myhome");
          } else {
            toast.error(data.message);
          }
        }
      } catch (error) {
        toast.error(error.message);
      }
      setIsLoading(false);
    } else {
      handleConnectWallet();
    }
  };

  return (
    <div className="rfid background-gray">
      <div className="container">
        <h2 className="text-shadow">RFID Tag Shipping</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="rfid-box">
            <img src={rfid_asset} alt="rfid_asset" className="rfid-asset" />
            <div className="rfid-contain">
              <p className="position-relative text-white mb-5 z-2">
                To complete the tokenization process, we will send you an RFID
                tag to attach to your car. This tag helps track and verify your
                vehicle's presence and ownership. Please submit your shipping
                address to receive your RFID tag, ensuring seamless integration
                and enhanced security for your tokenized car.
              </p>
              <div className="d-flex w-100 flex-column flex-sm-row gap-4 mb-3">
                <div className="flex-1">
                  <Controller
                    name="first_name"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <CustomInput
                        placeholder="First Name"
                        type="text"
                        className=""
                        name="first_name"
                        error={
                          errors.first_name ? errors.first_name.message : ""
                        }
                        {...field}
                      />
                    )}
                  />
                </div>
                <div className="flex-1">
                  <Controller
                    name="last_name"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <CustomInput
                        placeholder="Last Name"
                        type="text"
                        className=""
                        name="last_name"
                        error={errors.last_name ? errors.last_name.message : ""}
                        {...field}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="mb-3">
                <Controller
                  name="address1"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <CustomInput
                      placeholder="Address Line 1"
                      type="text"
                      className=""
                      name="address1"
                      error={errors.address1 ? errors.address1.message : ""}
                      {...field}
                    />
                  )}
                />
              </div>
              <div className="mb-3">
                <Controller
                  name="address2"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <CustomInput
                      placeholder="Address Line 2"
                      type="text"
                      className=""
                      name="address2"
                      error={errors.address2 ? errors.address2.message : ""}
                      {...field}
                    />
                  )}
                />
              </div>
              <div className="d-flex flex-column flex-md-row w-100 gap-4">
                <div className="flex-1">
                  <Controller
                    name="city"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <CustomInput
                        placeholder="City"
                        type="text"
                        className=""
                        name="city"
                        error={errors.city ? errors.city.message : ""}
                        {...field}
                      />
                    )}
                  />
                </div>
                <div className="flex-1">
                  <Controller
                    name="state"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <CustomInput
                        placeholder="State"
                        type="text"
                        className=""
                        name="state"
                        error={errors.state ? errors.state.message : ""}
                        {...field}
                      />
                    )}
                  />
                </div>
                <div className="flex-1">
                  <Controller
                    name="zip"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <CustomInput
                        placeholder="Zip"
                        type="text"
                        className=""
                        name="zip"
                        error={errors.zip ? errors.zip.message : ""}
                        {...field}
                      />
                    )}
                  />
                </div>
              </div>
              <p className="mt-3">
                Provide your full shipping address where we can send the RFID
                tag. Ensure the address is accurate to avoid any delays in the
                tokenization process.
              </p>
            </div>
          </div>
          <div className="pt-5 pb-1">
            <div className="d-flex justify-content-between gap-2 my-5">
              <button
                type="button"
                className="nav-btn large"
                onClick={() => navigate(-1)}
              >
                BACK
              </button>
              <button className="nav-btn large save-btn" type="submit">
                {isLoading ? (
                  <RotatingLines
                    visible={true}
                    height="20"
                    width="20"
                    color="#FFFFFF"
                    strokeWidth="5"
                    strokeColor="#ffffff"
                    animationDuration="0.75"
                    ariaLabel="rotating-lines-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                  />
                ) : (
                  <>{isConnected ? "MINT" : "CONNECT WALLET"}</>
                )}
              </button>
            </div>
            <div className="bottom-line"></div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Step6;
