import { toast, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

const toastOpts = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  newestOnTop: false,
  closeOnClick: true,
  rtl: false,
  pauseOnFocusLoss: false,
  draggable: true,
  pauseOnHover: false,
  theme: "dark",
};

export default function ToastNotification() {
  // const pathname = usePathname();

  // const basePath = pathname.split("/")[1];

  // useEffect(() => toast.dismiss(), [basePath]);

  return <ToastContainer {...toastOpts} />;
}
