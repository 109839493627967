import { useEffect, useRef, useState } from "react";
import { Triangle } from "react-loader-spinner";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollSmoother } from "gsap/ScrollSmoother";

import NewNavbar from "../../components/NewNavbar";
import NewHero from "../../sections/newHero";
import NewWhere from "../../sections/newWhere";
import NewHow from "../../sections/newHow";
import NewWhy from "../../sections/newWhy";
import NewFeature from "../../sections/newFeature";
import NewDrivora from "../../sections/newDrivora";
import "./newHome.css";

gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

const NewHome = () => {
  const canvasRef = useRef(null);
  const images = useRef([]);
  const screens = useRef({ frame: 0 });

  const [loading, setLoading] = useState(true);

  const frameCount = 890 + 50;
  const currentFrame = (index) =>
    `./images/new-home/${(index + 1).toString().padStart(4, "0")}.webp`;

  useEffect(() => {
    const loadImages = async () => {
      const imagePromises = [];
      for (let i = 0; i < frameCount; i++) {
        const img = new Image();
        if (i > 28 && i < 78) img.src = currentFrame(28);
        else if (i >= 78 && i <= 940) img.src = currentFrame(i - 50);
        else img.src = currentFrame(i);
        images.current.push(img);
        imagePromises.push(
          new Promise((resolve, reject) => {
            img.onload = resolve;
            img.onerror = () => reject(`Failed to load image: ${img.src}`);
          })
        );
      }
      await Promise.all(imagePromises);
      setLoading(false);
    };
    loadImages();
  }, [frameCount]);

  useEffect(() => {
    if (!loading) {
      const parentElement = document.getElementById("new-home");
      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");

      const setCanvasSize = () => {
        canvas.width = parentElement.clientWidth;
        canvas.height = parentElement.clientWidth / 1.777 - 65;
      };

      setCanvasSize();

      for (let i = 0; i < frameCount; i++) {
        const img = new Image();
        if (i > 28 && i < 78) img.src = currentFrame(28);
        else if (i >= 78 && i <= 940) img.src = currentFrame(i - 50);
        else img.src = currentFrame(i);
        images.current.push(img);
      }

      let canvasTimeline = gsap.timeline();

      canvasTimeline.to(screens.current, {
        frame: frameCount - 1,
        snap: "frame",
        ease: "none",
        scrollTrigger: {
          scrub: 4,
        },
        delay: 1,
        duration: 100000,
        onUpdate: () => {
          setCanvasSize();
          render();
        },
      });

      images.current[0].onload = render;

      function render() {
        context.clearRect(0, 0, canvas.width, canvas.height);
        context.drawImage(
          images.current[screens.current.frame],
          0,
          0,
          canvas.width,
          canvas.height
        );
      }

      const handleResize = () => {
        setCanvasSize();
        render();
      };

      window.addEventListener("resize", handleResize);

      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: "#new-home",
          start: "top top",
          end: "bottom bottom",
          scrub: 4, // Adjust this value for smoothness
          toggleActions: "play reverse play reverse",
        },
      });

      const nav = document.getElementById("new-nav");
      const hero = document.getElementById("new-hero");
      const where = document.getElementById("new-where");
      const how = document.getElementById("new-how");
      const why = document.getElementById("new-why");
      const profile = document.getElementById("public-profile");
      const exclusive = document.getElementById("exclusive");
      const private_section = document.getElementById("private");
      const feature = document.getElementById("new-feature");
      const drivora = document.getElementById("new-drivora");

      const navHeight = nav.clientHeight;
      const heroHeight = hero.clientHeight;
      const whereHeight = where.clientHeight;
      const howHeight = how.clientHeight;
      const whyHeight = why.clientHeight;
      const featureHeight = feature.clientHeight;
      const drivoraHeight = drivora.clientHeight;

      gsap.set(parentElement, { opacity: 1 });
      // Hero
      gsap.fromTo(canvasRef, { scale: 0 }, { scale: 1 });
      tl.set(hero, { visibility: "visible" }, "+=0.01");
      tl.set(hero, { opacity: 1 });
      // tl.fromTo(
      //   hero,
      //   { y: 10, opacity: 0 },
      //   { y: 0, opacity: 1, duration: 0.1 }
      // );
      tl.fromTo(
        ["#new-hero h4", "#new-hero h1", "#new-hero h5"],
        { y: 10, opacity: 0 },
        { y: 0, opacity: 1, duration: 0.2, stagger: 0.07 }
      );
      tl.fromTo(hero, { y: 0 }, { y: 0 }, "+=5.4");
      tl.fromTo(hero, { y: 0 }, { y: -heroHeight, duration: 0.2 });
      tl.set(hero, { visibility: "hidden" });

      // where
      tl.set(where, { visibility: "visible" });
      tl.fromTo(where, { opacity: 0 }, { opacity: 1, duration: 0.5 });
      tl.fromTo(where, { y: 0 }, { y: 0 }, "+=4.8");
      tl.fromTo(where, { y: 0 }, { y: -heroHeight, duration: 0.2 }, "+=1");
      // tl.fromTo(vision, { y: 0 }, { y: -visionHeight, duration: 1 });
      tl.set(where, { visibility: "hidden" });

      // how
      tl.set(how, { visibility: "visible" }, "+=6.")
        // .fromTo(how, { opacity: 0 }, { opacity: 1 })
        .fromTo(
          "#new-how .steps div",
          { opacity: 0, x: -20 },
          {
            opacity: 1,
            x: 0,
            duration: 0.5,
            stagger: 0.04,
          }
        )
        .fromTo(
          ["#new-how h5", "#new-how button"],
          { opacity: 0, y: 20 },
          {
            opacity: 1,
            y: 0,
            duration: 1,
            stagger: 0.04,
          }
        )
        .fromTo(how, { y: 0 }, { y: 0 }, "+=3.5");
      tl.fromTo(
        how,
        { opacity: 1, y: 0 },
        { opacity: 0, y: -howHeight, duration: 1 }
      );
      tl.set(how, { visibility: "hidden" });

      // why
      tl.set(why, { visibility: "visible" }, "+=7.5")
        // .fromTo(why, { opacity: 0 }, { opacity: 1 })
        .fromTo(why, { y: 20, opacity: 0 }, { y: 0, opacity: 1, duration: 0.5 })
        .fromTo(why, { y: 0 }, { y: 0 }, "+=1");
      tl.fromTo(
        why,
        { opacity: 1, y: 0 },
        { opacity: 0, y: -20, duration: 0.5 }
      );
      tl.set(why, { visibility: "hidden" });

      // profile
      tl.set(profile, { visibility: "visible" })
        .fromTo(profile, { opacity: 0 }, { opacity: 1 })
        .fromTo(profile, { y: 0 }, { y: 0 }, "+=1.5");
      tl.fromTo(profile, { opacity: 1 }, { opacity: 0 });
      tl.set(profile, { visibility: "hidden" });

      // exclusive
      tl.set(exclusive, { visibility: "visible" }).fromTo(
        exclusive,
        { opacity: 0 },
        { opacity: 1 }
      );
      tl.fromTo(exclusive, { y: 0 }, { y: 0 }, "+=2");
      tl.fromTo(exclusive, { opacity: 1 }, { opacity: 0 });
      tl.set(exclusive, { visibility: "hidden" });

      // private_section
      tl.set(private_section, { visibility: "visible" })
        .fromTo(private_section, { opacity: 0 }, { opacity: 1 })
        .to(private_section, { y: 0 }, "+=1");
      tl.fromTo(private_section, { opacity: 1 }, { opacity: 0 });
      tl.set(private_section, { visibility: "hidden" });

      // feature
      tl.set(feature, { visibility: "visible" }, "+=3");
      // .fromTo(feature, { opacity: 0 }, { opacity: 1 })
      tl.fromTo(
        [
          "#new-feature .values div",
          "#new-feature .car-detail",
          "#new-feature .each-property",
          "#new-feature h5",
          "#new-feature h1",
        ],
        { y: 30, opacity: 0 },
        { y: 0, opacity: 1, duration: 0.5, stagger: 0.1 }
      );
      tl.fromTo(feature, { y: 0 }, { y: 0 }, "+=9");
      tl.fromTo(
        [
          "#new-feature .values div",
          "#new-feature .car-detail",
          "#new-feature .each-property",
          "#new-feature h5",
          "#new-feature h1",
        ],
        { y: 0, opacity: 1 },
        { y: 30, opacity: 0, duration: 0.5, stagger: 0.1 }
      );
      tl.set(feature, { visibility: "hidden" }, "-=0.5");

      tl.fromTo(
        nav,
        { height: navHeight },
        { height: "100%", duration: 1, delay: 3 }
      );
      // drivora
      tl.set(drivora, { visibility: "visible" });
      // .fromTo(drivora, { opacity: 0 }, { opacity: 1 })
      tl.fromTo("#new-drivora h1", { opacity: 0 }, { opacity: 1 });
      tl.fromTo("#new-drivora h1", { y: 0 }, { y: 0 }, "+=3");
      tl.fromTo("#new-drivora .drivora-bottom", { opacity: 0 }, { opacity: 1 });

      return () => {
        window.removeEventListener("resize", handleResize);
        ScrollTrigger.getAll().forEach((t) => t.kill());
      };
    }
  }, [frameCount, loading]);

  // useEffect(() => {
  //   const onPageLoad = () => {
  //     setLoading(false);
  //   };

  //   if (document.readyState === "complete") {
  //     onPageLoad();
  //   } else {
  //     window.addEventListener("load", onPageLoad, false);
  //     return () => window.removeEventListener("load", onPageLoad);
  //   }
  // }, []);

  useEffect(() => {
    if (!loading) {
      setTimeout(() => {
        window.scrollTo(0, 500);
      }, 1000);
    }
  }, [loading]);

  return (
    <>
      {loading ? (
        <div className="loading-screen">
          <Triangle
            visible={true}
            height="80"
            width="80"
            color="#4fa94d"
            ariaLabel="triangle-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      ) : (
        <div id="new-home">
          <div className="z-3 position-relative">
            <NewNavbar />
            <NewHero />
            <NewWhere />
            <NewHow />
            <NewWhy />
            <NewFeature />
            <NewDrivora />
          </div>

          <canvas id="canvas_main" ref={canvasRef} />
        </div>
      )}
    </>
  );
};

export default NewHome;
