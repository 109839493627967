import { useNavigate } from "react-router-dom";
import { RotatingLines } from "react-loader-spinner";

const ConfirmButtons = ({ isLoading }) => {
  const navigate = useNavigate();

  return (
    <div className="pt-5 pb-1">
      <div className="d-flex justify-content-between gap-2 my-5">
        <button
          type="button"
          className="nav-btn large"
          onClick={() => navigate(-1)}
        >
          BACK
        </button>
        <button className="nav-btn large save-btn" type="submit">
          {isLoading ? (
            <RotatingLines
              visible={true}
              height="20"
              width="20"
              color="#FFFFFF"
              strokeWidth="5"
              strokeColor="#ffffff"
              animationDuration="0.75"
              ariaLabel="rotating-lines-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          ) : (
            "SAVE CHANGES"
          )}
        </button>
      </div>
      <div className="bottom-line"></div>
    </div>
  );
};

export default ConfirmButtons;
