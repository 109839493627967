import { useState } from "react";
import document_icon from "../../assets/images/document-icon.png";
import ConfirmButtons from "../../components/ConfirmButtons";
import { toast } from "react-toastify";
import { addTokenInfo } from "../../api/nft";
import { formatSize } from "../../utils/filesize";

const Step4 = ({ navigate, user, token }) => {
  const [certificateFile, setCertificateFile] = useState();
  const [pucFile, setPucFile] = useState();
  const [insuranceFile, setInsuranceFile] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const handleCertificateFileChange = (e) => {
    const file = e.target.files?.[0];
    if (file) setCertificateFile(file);
  };

  const handleInsuranceFileChange = (e) => {
    const file = e.target.files?.[0];
    if (file) setInsuranceFile(file);
  };

  const handlePucFileChange = (e) => {
    const file = e.target.files?.[0];
    if (file) setPucFile(file);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();

    formData.append("id", token);
    formData.append("userId", user.id);
    formData.append("currentStep", 5);
    if (certificateFile && pucFile && insuranceFile) {
      formData.append("certificateFile", certificateFile);
      formData.append("pucFile", pucFile);
      formData.append("insuranceFile", insuranceFile);
      try {
        setIsLoading(true);
        const data = await addTokenInfo(formData);
        if (data.success) {
          toast.success("Token info is updated successfully!");
          navigate("/tokenize/5");
        } else {
          toast.error(data.message);
        }
      } catch (error) {
        toast.error(error.message);
      }
      setIsLoading(false);
    } else {
      toast.error("Please upload 3 documents");
    }
  };
  return (
    <div className="add-document">
      <div className="container">
        <h2 className="text-shadow">DOCUMENTATION.</h2>
        <form onSubmit={handleSubmit}>
          <div className="upload-steps">
            <div className="d-flex gap-4 align-items-center mb-5">
              <img src={document_icon} alt="document_icon" />
              <p>
                These Document will only for Verification Purpose not to be
                shared with third party.
              </p>
            </div>
            <div className="upload-document gap-4 mt-3">
              <div className="doc-box">
                <p>REGISTRATION CERTIFICATE</p>
                <label
                  htmlFor="upload-registration-certificate"
                  className="profile-btn mt-4"
                >
                  <i className="fas fa-upload	me-2"></i> UPLOAD CERTIFICATE
                </label>
                <input
                  type="file"
                  id="upload-registration-certificate"
                  className="d-none"
                  accept=".xlsx,.xls, .doc, .docx,.ppt, .pptx,.txt,.pdf"
                  onChange={handleCertificateFileChange}
                />
                {certificateFile && (
                  <div className="d-flex align-items-center gap-3 mt-2">
                    <i className="far fa-file-alt"></i>
                    <p className="text-white">{certificateFile.name}</p>
                    <p>{formatSize(certificateFile.size)}</p>
                  </div>
                )}
              </div>
              <div className="doc-box">
                <p>INSURANCE</p>
                <label htmlFor="upload-insurance" className="profile-btn mt-4">
                  <i className="fas fa-upload	me-2"></i> UPLOAD INSURANCE
                </label>
                <input
                  type="file"
                  id="upload-insurance"
                  className="d-none"
                  accept=".xlsx,.xls, .doc, .docx,.ppt, .pptx,.txt,.pdf"
                  onChange={handleInsuranceFileChange}
                />
                {insuranceFile && (
                  <div className="d-flex align-items-center gap-3 mt-2">
                    <i className="far fa-file-alt"></i>
                    <p className="text-white">{insuranceFile.name}</p>
                    <p>{formatSize(insuranceFile.size)}</p>
                  </div>
                )}
              </div>
              <div className="doc-box">
                <p>UPLOAD PUC</p>
                <label htmlFor="upload-puc" className="profile-btn mt-4">
                  <i className="fas fa-upload	me-2"></i> UPLOAD PUC
                </label>
                <input
                  type="file"
                  id="upload-puc"
                  className="d-none"
                  accept=".xlsx,.xls, .doc, .docx,.ppt, .pptx,.txt,.pdf"
                  onChange={handlePucFileChange}
                />
                {pucFile && (
                  <div className="d-flex align-items-center gap-3 mt-2">
                    <i className="far fa-file-alt"></i>
                    <p className="text-white">{pucFile.name}</p>
                    <p>{formatSize(pucFile.size)}</p>
                  </div>
                )}
              </div>
            </div>
            <div className="text-start mt-4">
              <p className="mb-3">
                <span className="text-white">Registration Certificate:</span>{" "}
                Upload a copy of your car's registration certificate. This
                document verifies the legal ownership and registration status of
                the vehicle.
              </p>
              <p className="mb-3">
                <span className="text-white">Insurance:</span> Upload a copy of
                your car's current insurance policy. This ensures the vehicle is
                covered in case of any accidents or damages.
              </p>
              <p className="mb-3">
                <span className="text-white">
                  PUC (Pollution Under Control) Certificate:
                </span>{" "}
                Upload your car's PUC certificate. This document verifies that
                your vehicle meets the required emission standards.
              </p>
            </div>
          </div>
          <ConfirmButtons isLoading={isLoading} />
        </form>
      </div>
    </div>
  );
};

export default Step4;
