import { useEffect } from "react";
import { useParams } from "react-router-dom";
import DetailTop from "../../sections/detailTop";
import Footer from "../../components/Footer";
import Information from "../../sections/information";
import Activity from "../../sections/activity";
import Offers from "../../sections/offers";
import { getNftByTokenId } from "../../api/nft";
import { toast } from "react-toastify";
import { useSetNftData } from "../../store/hooks/token";
import { useUser } from "../../store/hooks/auth";

const Detail = () => {
  const { tokenId } = useParams();
  const user = useUser();
  const setNftData = useSetNftData();

  useEffect(() => {
    const getNft = async () => {
      try {
        const res = await getNftByTokenId({ tokenId, currentUserId: user?.id });
        if (res.success) {
          setNftData(res.data);
        }
      } catch (e) {
        toast.error(e);
      }
    };
    if (tokenId) {
      getNft();
    }
  }, [tokenId, user, setNftData]);

  return (
    <div>
      <DetailTop />
      <Information />
      <Activity />
      <Offers />
      <Footer />
    </div>
  );
};

export default Detail;
