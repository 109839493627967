import { useState } from "react";
import { motion } from "framer-motion";
import Modal from "react-modal";
import { HashLink } from "react-router-hash-link";
import { useLocation, useNavigate } from "react-router-dom";

import { useIsAuthenticated, useLogout, useUser } from "../store/hooks/auth";
import createAvatar from "../utils/createAvatar";
import { useSetCurrentToken } from "../store/hooks/token";
import useWallet from "../store/hooks/useWallet";
import logo from "../assets/images/logo.png";

const Navbar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const user = useUser();
  const isAuthenticated = useIsAuthenticated();
  const setCurrentToken = useSetCurrentToken();
  const { disconnectWallet } = useWallet();
  const [viewMenu, setViewMenu] = useState(false);
  const [searchValue, setSearchValue] = useState();

  const logout = useLogout();

  const handleMenu = () => {
    setViewMenu(!viewMenu);
  };

  const handleLogout = () => {
    logout();
    disconnectWallet();
    navigate("/auth/login");
  };

  const userlink =
    location.pathname.includes("profile") ||
    location.pathname.includes("myhome") ||
    location.pathname.includes("notification") ||
    location.pathname.includes("add") ||
    location.pathname.includes("marketplace");

  return (
    <>
      <div className="position-relative">
        {userlink && (
          <HashLink className="marketplace-link" to="/marketplace">
            <span className="text-white">TOKENIZED EXOTICS</span> MARKETPLACE
          </HashLink>
        )}
        <HashLink to="/">
          <motion.img
            initial={{ opacity: 0, scale: 0 }}
            whileInView={{ opacity: 1, scale: 1 }}
            transition={{ duration: 1 }}
            viewport={{ once: true }}
            src={logo}
            className="mt-2 z-3 position-relative nav-logo"
            alt="logo"
          />
        </HashLink>
        {userlink ? (
          <div className="nav-right">
            <div className="searchBox">
              <input
                className="searchInput"
                type="text"
                name="search"
                placeholder="Search"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
              <button className="searchButton" href="#">
                <i className="material-icons" style={{ fontSize: "32px" }}>
                  search
                </i>
              </button>
            </div>
            {isAuthenticated ? (
              <>
                <button
                  className="nav-btn mobile-hide"
                  onClick={() => {
                    setCurrentToken({ token: null });
                    navigate("/tokenize");
                  }}
                >
                  TOKENIZE
                </button>
                <div className="position-relative dropdown">
                  {user?.avatar ? (
                    <img
                      src={user.avatar}
                      alt="avatar"
                      className="nav-avatar"
                    />
                  ) : (
                    <motion.div
                      initial={{ opacity: 0, scale: 0.5 }}
                      whileInView={{ opacity: 1, scale: 1 }}
                      transition={{ duration: 1, delay: 0.5 }}
                      viewport={{ once: true }}
                      className="nav-avatar"
                      style={{
                        backgroundColor: createAvatar(user?.fullname).color,
                      }}
                    >
                      {createAvatar(user?.fullname).name}
                    </motion.div>
                  )}
                  <div className="dropdown-content">
                    <div onClick={() => navigate("/myhome")}>Profile</div>
                    <div onClick={handleLogout}>Log out</div>
                  </div>
                </div>
              </>
            ) : (
              <button
                className="btn btn-menu position-relative"
                onClick={handleMenu}
                style={{ transform: "none", top: 0, marginLeft: 15 }}
              >
                <span>
                  <i></i>
                </span>
              </button>
            )}
          </div>
        ) : (
          <button className="btn btn-menu" onClick={handleMenu}>
            <span>
              <i></i>
            </span>
          </button>
        )}
      </div>
      <Modal
        appElement={document.getElementById("root")}
        isOpen={viewMenu}
        onRequestClose={handleMenu}
        overlayClassName={{
          base: "overlay-base",
          afterOpen: "overlay-after",
          beforeClose: "overlay-before",
        }}
        className={{
          base: "content-base",
          afterOpen: "content-after",
          beforeClose: "content-before",
        }}
        closeTimeoutMS={500}
        preventScroll={true}
      >
        <div className="menu-logo img-fluid">
          <HashLink to="/" rel="nofollow">
            <img src={logo} alt="mla-logo" />
          </HashLink>
        </div>
        <div className="close">
          <i className="fa close-icon" onClick={handleMenu}>
            &#xf00d;
          </i>
        </div>
        <div className="menu-list">
          <HashLink className="underline-hover" onClick={handleMenu} to="/">
            HOME
          </HashLink>
          <HashLink
            className="underline-hover"
            onClick={handleMenu}
            to="/about"
          >
            ABOUT
          </HashLink>
          <HashLink
            className="underline-hover"
            onClick={handleMenu}
            to="/marketplace"
          >
            MARKETPLACE
          </HashLink>
          {!isAuthenticated && (
            <HashLink
              className="underline-hover"
              onClick={handleMenu}
              to="/auth/login"
            >
              LOGIN
            </HashLink>
          )}
          {!isAuthenticated && (
            <HashLink
              className="underline-hover"
              onClick={handleMenu}
              to="/auth/register"
            >
              REGISTER
            </HashLink>
          )}
        </div>
      </Modal>
    </>
  );
};

export default Navbar;
