import drivoraAxios from "./api";

// Utility function to handle API requests
const apiRequest = async (endpoint, params, headers) => {
  try {
    const { data } = await drivoraAxios.post(endpoint, params, {
      headers: {
        ...headers,
      },
    });
    return data;
  } catch (err) {
    console.error(`Error in ${endpoint} request:`, err);
    throw err; // Optionally, rethrow the error or handle it as needed
  }
};

export const googleLogin = (params) => apiRequest("google-login", params);
export const login = (params) => apiRequest("login", params);
export const register = async (params) => apiRequest("register", params);
export const forgotPassword = (params) => apiRequest("forgot-password", params);
export const resetPassword = (params) => apiRequest("reset-password", params);
export const getUser = (params) => apiRequest("getUser", params);
export const updateUser = (params) =>
  apiRequest("updateUser", params, "multipart/form-data");
