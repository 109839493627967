import React from "react";
import { HashLink } from "react-router-hash-link";

const steps = [
  {
    num: "01",
    title: "Register & Verify",
    description:
      "Join our community of elite car enthusiasts by creating your profile and digital wallet.",
  },
  {
    num: "02",
    title: "Appointment Booking",
    description:
      "Book through our platform to meet with a Drivora rep at car storage partner or at your home.",
  },
  {
    num: "03",
    title: "Car Tokenization",
    description:
      "The car is tokenized, creating a digital ownership record (NFT) that is stored in the user's digital wallet.",
  },
  {
    num: "04",
    title: "List on the Marketplace",
    description:
      "Choose which tier to list your tokenized car, set your terms, and engage with potential investors.",
  },
  {
    num: "05",
    title: "Trade & Manage",
    description:
      "Monitor offers, negotiate deals, and manage your investments through our user-friendly platform.",
  },
];

const NewHow = () => {
  return (
    <div id="new-how" className="section">
      <div className="bg-black">
        <div className="section-title">HOW</div>
        <h1>It works</h1>
        <div className="steps">
          {steps.map((step, index) => (
            <React.Fragment key={index}>
              <div>
                <div className="step-num">{step.num}</div>
                <div className="step-title">{step.title}</div>
                <div className="step-description">{step.description}</div>
              </div>
              {index !== 4 && (
                <div className="large-arrow">
                  {/* <img src={large_arrow} alt="large_arrow" /> */}
                </div>
              )}
            </React.Fragment>
          ))}
        </div>
        <h5 className="mt-5">
          Register today to begin your journey with Drivora, where your luxury
          cars are not just driven, but driven forward.
        </h5>
        <HashLink className="register-btn btn-position" to="/auth/register/#">
          REGISTER
        </HashLink>
      </div>
    </div>
  );
};

export default NewHow;
