import { useState } from "react";
import { toast } from "react-toastify";
import { RotatingLines } from "react-loader-spinner";
import axios from "axios";

import ConfirmButtons from "../../components/ConfirmButtons";
import CustomInput from "../../components/CustomInput";
import { addTokenInfo } from "../../api/nft";
import { useSetVin } from "../../store/hooks/token";
import verify_logo from "../../assets/images/verify-logo.png";

const Step2 = ({ navigate, user, token }) => {
  const setVinHook = useSetVin();
  const [isVerified, setIsVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [vin, setVin] = useState("");
  const [error, setError] = useState("");

  const handleVinChange = async (e) => {
    const inputVin = e.target.value.toUpperCase();
    setVin(inputVin);

    if (inputVin.length === 17) {
      setError("");
      // await validateVin(inputVin);
    } else {
      setError("Please enter a VIN number.");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (isVerified) {
      const formData = new FormData();
      formData.append("id", token);
      formData.append("userId", user.id);
      // formData.append("vin", vin);
      formData.append("currentStep", 3);

      setIsLoading(true);
      const data = await addTokenInfo(formData);
      if (data.success) {
        setVinHook({ vin });
        toast.success("Token info is updated successfully!");
        navigate("/tokenize/3");
      } else {
        toast.error(data.message);
      }
    } else {
      toast.error("Please verify your assets");
    }
  };

  const handleVerify = async () => {
    if (vin && error === "") {
      setIsVerifying(true);
      try {
        const response = await axios.get(
          `https://vpic.nhtsa.dot.gov/api/vehicles/DecodeVin/${vin}?format=json`
        );
        // const response = await axios.get(
        //   // `https://api.vinaudit.com/v2/query?vin=${vin}&format=json`
        //   `https://specifications.vinaudit.com/v3/specifications?format=json&vin=${vin}`
        // );
        //1FMDE5BH1NLB13850
        console.log("response ==>", response);

        const results = response.data.Results;

        const decodedVin = results.find(
          (r) => r.Variable === "Error Code" && r.Value === "0"
        );
        if (decodedVin) {
          setIsVerified(true);
        } else {
          toast.error("Invalid VIN");
        }
      } catch (error) {
        toast.error("Invalid VIN");
      }
      setIsVerifying(false);
    } else {
      setError("Please enter a VIN number.");
    }
  };

  return (
    <div className="property4-details">
      <div className="container">
        <form onSubmit={handleSubmit}>
          <h2 className="text-shadow mb-4">ADD YOUR VIN</h2>
          <div className="verification-box">
            <div>
              {/* <img src={verify_logo} alt="verify_logo" className="mb-4" /> */}
              <p>
                We verify your car's ownership through secure partnerships with
                vehicle registration authorities and trusted verification
                services. This ensures authenticity and security, providing
                peace of mind for all stakeholders in our fractional ownership
                marketplace.
              </p>
              <div className="button-group flex-wrap mt-5 flex-column">
                <CustomInput
                  placeholder="Add Your VIN"
                  type="text"
                  className="vin"
                  name="vin"
                  value={vin}
                  error={error}
                  onChange={handleVinChange}
                />
                <button
                  type="button"
                  className="verify-btn"
                  onClick={handleVerify}
                >
                  {isVerified ? (
                    <>
                      <i className="fas fa-check-circle"></i> Submitted
                    </>
                  ) : (
                    <>
                      {isVerifying ? (
                        <RotatingLines
                          visible={true}
                          height="20"
                          width="20"
                          color="#FFFFFF"
                          strokeWidth="5"
                          strokeColor="#ffffff"
                          animationDuration="0.75"
                          ariaLabel="rotating-lines-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                        />
                      ) : (
                        "Submit"
                      )}
                    </>
                  )}
                </button>
                {/* <button
                  type="button"
                  className="verify-btn"
                  onClick={() => setIsVerified(true)}
                >
                  Add Your VIN
                </button> */}
              </div>
            </div>
          </div>
          <ConfirmButtons isLoading={isLoading} />
        </form>
      </div>
    </div>
  );
};

export default Step2;
