import { HashLink } from "react-router-hash-link";

const NewDrivora = () => {
  return (
    <div id="new-drivora" className="section">
      <div className="container">
        <h1>Why Drivora?</h1>
        <div className="drivora-bottom">
          <div className="d-flex gap-3 gap-md-5 bottom-area">
            <div>
              <h5>Luxury meets innovation</h5>
              <p>
                Drivora caters to those who seek excellence in all facets of
                life, reflecting the prestige and refinement of the luxury
                assets it handles.
              </p>
            </div>
            <div>
              <h5>Security & Trust</h5>
              <p>
                Drivora uses blockchain technology to ensure every transaction
                is secure, transparent, and compliant with global standards.
              </p>
            </div>
            <div>
              <h5>Exclusive Network</h5>
              <p>
                Join an elite community of luxury car enthusiasts and investors.
                Drivora is more than a marketplace - it's a hub for passion and
                growth.
              </p>
            </div>
          </div>
          <h5 className="mt-4 mt-md-5">
            Step into the future of car ownership with Drivora.
          </h5>
          <HashLink className="register-btn" to="/auth/register/#">
            REGISTER
          </HashLink>
        </div>
      </div>
    </div>
  );
};

export default NewDrivora;
