import { useState } from "react";
import Modal from "react-modal";
import { Oval, RotatingLines } from "react-loader-spinner";
import "./modal.css";

const AcceptModal = (props) => {
  const { showAcceptModal, setShowAcceptModal, bidAmount } = props;
  const [passed, setPassed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    setShowAcceptModal(false);
  };

  return (
    <Modal
      appElement={document.getElementById("root")}
      isOpen={showAcceptModal}
      onRequestClose={handleClose}
      overlayClassName={{
        base: "overlay-base",
        afterOpen: "offer-overlay-after",
        beforeClose: "overlay-before",
      }}
      className={{
        base: "content-base",
        afterOpen: "bid-content-after",
        beforeClose: "content-before",
      }}
      preventScroll={true}
    >
      <div className="modal-close">
        <i className="material-icons" onClick={handleClose}>
          close
        </i>
      </div>
      <h5 className="text-black modal-title">FOLLOW STEPS</h5>
      <div className="mt-4 w-100">
        <div className="d-flex gap-3 align-items-center mb-2">
          {isLoading ? (
            <Oval
              visible={true}
              height="40"
              width="40"
              color="#000000"
              secondaryColor="grey"
              ariaLabel="oval-loading"
              wrapperStyle={{}}
              wrapperClass=""
              strokeWidth={3}
            />
          ) : (
            <div className={`${passed && "approve"} icon-circle`}>
              {passed ? (
                <i className="fas fa-check"></i>
              ) : (
                <i className="far fa-caret-square-up"></i>
              )}
            </div>
          )}
          <div>
            <h5>ACCEPT BID</h5>
            <p>Send transaction with your wallet</p>
          </div>
        </div>
        {isLoading ? (
          <div className="btn-loading">
            <RotatingLines
              visible={true}
              height="24"
              width="24"
              color="#FFFFFF"
              strokeWidth="5"
              strokeColor="#ffffff"
              animationDuration="0.75"
              ariaLabel="rotating-lines-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </div>
        ) : (
          <button
            className="modal-btn black-btn"
            disabled={passed}
            onClick={() => setIsLoading(true)}
          >
            {passed ? "DONE" : "START NOW"}
          </button>
        )}
      </div>
    </Modal>
  );
};

export default AcceptModal;
