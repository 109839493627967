import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import car_image from "../../assets/images/join-image.png";
import logo from "../../assets/images/logo.png";
import "./joinus.css";

const JoinUs = () => {
  const navigate = useNavigate();

  return (
    <div className="joinus">
      <div className="container">
        <div className="position-relative mb-5">
          <img src={car_image} alt="car" />
          <motion.img
            initial={{ opacity: 0, scale: 0, x: "-50%" }}
            whileInView={{ opacity: 1, scale: 1, x: "-50%" }}
            transition={{ duration: 1 }}
            viewport={{ once: true }}
            src={logo}
            alt="logo"
            className="logo"
          />
          <div className="vertical-line"></div>
        </div>
        <motion.div
          initial={{ opacity: 0, y: 200 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3, duration: 1 }}
          viewport={{ once: true }}
        >
          <h2 className="text-shadow">JOIN US.</h2>
          <p>Step into the future of car ownership with Drivora.</p>
          <br />
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 200 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.6, duration: 1 }}
          viewport={{ once: true }}
        >
          <p className="m-auto" style={{ maxWidth: "800px" }}>
            Whether adding to your collection or investing in a passion, our
            platform connects you with opportunities that match the caliber of
            your lifestyle. Register today to begin your journey with Drivora,
            where your luxury cars are not just driven, but driven forward.
          </p>
          <button
            className="normal-btn mt-5"
            onClick={() => navigate("/auth/register")}
          >
            REGISTER
          </button>
        </motion.div>
      </div>
    </div>
  );
};

export default JoinUs;
