import { motion } from "framer-motion";
import AnimatedTextWord from "../../components/AnimatedTextWord";
import Navbar from "../../components/Navbar";
import "./hero.css";

const LandingHero = () => {
  return (
    <div className="hero">
      <div className="container">
        <Navbar />
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4, duration: 1 }}
          viewport={{ once: true }}
          className="mt-5"
        >
          <h4>EXPERIENCE THE FUTURE OF</h4>
        </motion.div>
        <AnimatedTextWord text="EXOTIC CAR TOKENIZATION." />
        {/* <h1 className="text-shadow my-4">EXOTIC CAR TOKENIZATION.</h1> */}
        <motion.p
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ delay: 1.5, duration: 1 }}
          viewport={{ once: true }}
        >
          Drivora transforms your exotic cars into digital assets, enabling you
          to list, trade, and manage fractional ownerships with unparalleled
          precision and luxury.
        </motion.p>
      </div>
    </div>
  );
};

export default LandingHero;
