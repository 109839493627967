import { useDispatch, useSelector } from "react-redux";

import { setCurrentToken, setNftData, setVin } from "../../slices/nft";

export function useSetCurrentToken() {
  const dispatch = useDispatch();
  return (payload) => dispatch(setCurrentToken(payload));
}

export function useSetVin() {
  const dispatch = useDispatch();
  return (payload) => dispatch(setVin(payload));
}

export function useCurrentTokenId() {
  return useSelector(({ nft }) => nft.token);
}

export function useVin() {
  return useSelector(({ nft }) => nft.vin.vin);
}

export function useSetNftData() {
  const dispatch = useDispatch();
  return (payload) => dispatch(setNftData(payload));
}

export function useNftData() {
  return useSelector(({ nft }) => nft.nftData);
}
