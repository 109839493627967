import Modal from "react-modal";
import "./modal.css";
import useWallet from "../../store/hooks/useWallet";

const ConnectWalletModal = (props) => {
  const { showConnectModal, setShowConnectModal } = props;
  const { handleConnectWallet } = useWallet();
  const handleClose = () => {
    setShowConnectModal(false);
  };

  return (
    <Modal
      appElement={document.getElementById("root")}
      isOpen={showConnectModal}
      onRequestClose={handleClose}
      overlayClassName={{
        base: "overlay-base",
        afterOpen: "offer-overlay-after",
        beforeClose: "overlay-before",
      }}
      className={{
        base: "content-base",
        afterOpen: "offer-content-after",
        beforeClose: "content-before",
      }}
      preventScroll={true}
    >
      <div className="modal-close">
        <i className="material-icons" onClick={handleClose}>
          close
        </i>
      </div>
      <div className="circle-wallet">
        <i className="fas fa-wallet text-white"></i>
      </div>
      <p className="text-black my-4">
        You need to connect your wallet first to sign messages and send
        transactions to Ethereum network.
      </p>
      <button
        className="modal-btn black-btn"
        onClick={() => {
          handleConnectWallet();
          setShowConnectModal(false);
        }}
      >
        CONNECT WALLET
      </button>
      <button className="modal-btn white-btn mt-2" onClick={handleClose}>
        CANCEL
      </button>
    </Modal>
  );
};

export default ConnectWalletModal;
