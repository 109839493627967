import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import car_image from "../../assets/images/join-image.png";
import logo from "../../assets/images/logo.png";

const AboutJoinUs = () => {
  const navigate = useNavigate();

  return (
    <div className="joinus">
      <div className="container">
        <div className="position-relative mb-5">
          <img src={car_image} alt="car" />
          <motion.img
            initial={{ opacity: 0, scale: 0, x: "-50%" }}
            whileInView={{ opacity: 1, scale: 1, x: "-50%" }}
            transition={{ duration: 1 }}
            viewport={{ once: true }}
            src={logo}
            alt="logo"
            className="logo"
          />
          <div className="vertical-line"></div>
        </div>
        <motion.div
          initial={{ opacity: 0, y: 200 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3, duration: 1 }}
          viewport={{ once: true }}
        >
          <h2>
            JOIN US ON THE <span className="text-shadow">JOURNEY.</span>
          </h2>
          <br />
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 200 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.6, duration: 1 }}
          viewport={{ once: true }}
        >
          <p className="m-auto" style={{ maxWidth: "670px" }}>
            We are more than a marketplace; we are a movement towards a more
            inclusive and rewarding future in luxury car investment.
          </p>
          <p className="m-auto mt-4" style={{ maxWidth: "670px" }}>
            At Drivora, every interaction reflects our core values of trust,
            innovation, and community. Become part of this transformative
            journey, where your passion for cars meets unparalleled opportunity.
          </p>
          <button
            className="normal-btn mt-5"
            onClick={() => navigate("/auth/register")}
          >
            REGISTER
          </button>
        </motion.div>
      </div>
    </div>
  );
};

export default AboutJoinUs;
