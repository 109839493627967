import { motion } from "framer-motion";
import image1 from "../../assets/images/partnership-image1.png";
import image2 from "../../assets/images/partnership-image2.png";
import "./partnership.css";

const Partnership = () => {
  return (
    <div className="partnership">
      <div className="container">
        <motion.div
          initial={{ opacity: 0, y: 200 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
        >
          <h4 className="add-vertical-line">EXCLUSIVE</h4>
          <h2 className="text-shadow">PARTNERSHIP.</h2>
        </motion.div>
        <div className="d-flex gap-4 gap-lg-2 mt-5 flex-lg-row flex-column">
          <motion.div
            initial={{ opacity: 0, x: -200 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 1 }}
            viewport={{ once: true }}
            className="position-relative"
          >
            <img src={image1} alt="asset" />
            <div className="partnership-content">
              <h4>ELITE VEHICLE</h4>
              <h2>TRANSPORTATION.</h2>
              <p>
                Collaborations with premier transport services for local,
                national, and global delivery of your luxury automobiles.
              </p>
            </div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: 200 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 1 }}
            viewport={{ once: true }}
            className="position-relative"
          >
            <img src={image2} alt="asset" />
            <div className="partnership-content">
              <h4>PROFESSIONAL</h4>
              <h2>CAR PHOTOGRAPHY.</h2>
              <p>
                Access to expert photography services for a fee, elevating the
                display of your tokenized vehicles.
              </p>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Partnership;
