import { createSlice } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const initialState = { token: null, user: null };

export const { actions, ...slice } = createSlice({
  name: "drivora-auth",
  initialState,
  reducers: {
    login: (state, { payload }) => payload,
    logout: () => initialState,
    getUser: (state, { payload }) => {
      state.user = payload;
    },
  },
});

export const reducer = persistReducer(
  {
    key: "drivora-auth",
    version: 1,
    storage,
  },
  slice.reducer
);
