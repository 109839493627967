import { Navigate, Route, Routes } from "react-router-dom";
import Login from "../pages/login";
import Register from "../pages/register";

const AuthPage = () => {
  return (
    <Routes>
      <Route exact path="login" element={<Login />} />
      <Route exact path="register" element={<Register />} />
      <Route exact path="reset-password" element={<Login />} />
    </Routes>
  );
};

export default AuthPage;
