import { GoogleOAuthProvider } from "@react-oauth/google";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter as Router } from "react-router-dom";
import { createClient, configureChains, WagmiConfig, useAccount } from "wagmi";
import { alchemyProvider } from "wagmi/providers/alchemy";
import { infuraProvider } from "wagmi/providers/infura";
import { publicProvider } from "wagmi/providers/public";
import { mainnet, goerli, sepolia } from "wagmi/chains";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { CoinbaseWalletConnector } from "wagmi/connectors/coinbaseWallet";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";
import ToastNotification from "./components/ToastNotification";
import store, { persistor } from "./store";
import { WalletProvider } from "./store/contexts/walletContext";
import AppRoutes from "./routes";
import "./App.css";

window.Buffer = window.Buffer || require("buffer").Buffer;

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

function App() {
  const { chains, provider, webSocketProvider } = configureChains(
    [/* mainnet */ sepolia],
    [
      infuraProvider({ apiKey: process.env.REACT_APP_INFURA_KEY }),
      publicProvider(),
    ]
  );

  const client = createClient({
    provider,
    webSocketProvider,
    connectors: [
      new MetaMaskConnector({ chains }),
      new CoinbaseWalletConnector({
        chains,
        options: {
          appName: "DRIVORA",
        },
      }),
      new WalletConnectConnector({
        chains,
        options: {
          projectId: process.env.REACT_APP_WALLETCONNECT_ID,
        },
      }),
    ],
    autoConnect: true,
  });

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={null}>
        <WagmiConfig client={client}>
          <WalletProvider>
            <Router>
              <div className="App">
                <ToastNotification />
                <GoogleOAuthProvider clientId={clientId}>
                  <AppRoutes />
                </GoogleOAuthProvider>
              </div>
            </Router>
          </WalletProvider>
        </WagmiConfig>
      </PersistGate>
    </Provider>
  );
}

export default App;
