import { useState } from "react";
import { toast } from "react-toastify";
import FileUpload from "../../components/FileUpload";
import ConfirmButtons from "../../components/ConfirmButtons";
import { addTokenInfo } from "../../api/nft";

const Step5 = ({ navigate, user, token }) => {
  const [assetFiles, setAssetFiles] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();

    formData.append("id", token);
    formData.append("userId", user.id);
    formData.append("currentStep", 6);

    if (assetFiles.length > 0) {
      assetFiles.forEach((file) => {
        formData.append("assetFiles", file);
      });
      try {
        setIsLoading(true);
        const data = await addTokenInfo(formData);
        if (data.success) {
          toast.success("Token info is updated successfully!");
          navigate("/tokenize/6");
        } else {
          toast.error(data.message);
        }
      } catch (error) {
        toast.error(error.message);
      }
      setIsLoading(false);
    } else {
      toast.error("Please upload asset files");
    }
  };

  return (
    <div className="add-asset">
      <div className="container">
        <h2 className="text-shadow mb-4">MEDIA.</h2>
        <form onSubmit={handleSubmit}>
          <FileUpload onFilesSelected={setAssetFiles} />
          <ConfirmButtons isLoading={isLoading} />
        </form>
      </div>
    </div>
  );
};

export default Step5;
