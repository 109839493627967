import { useDispatch, useSelector } from "react-redux";

import { actions } from "../../slices/auth";

export function useLogin() {
  const dispatch = useDispatch();
  return (payload) => dispatch(actions.login(payload));
}

export function useLogout() {
  const dispatch = useDispatch();
  return () => dispatch(actions.logout());
}

export function useAccessToken() {
  return useSelector(({ auth }) => auth.token);
}

export function useIsAuthenticated() {
  return !!useAccessToken();
}

export function useGetUser() {
  const dispatch = useDispatch();
  return (payload) => dispatch(actions.getUser(payload));
}

export function useUser() {
  return useSelector(({ auth }) => auth.user);
}
