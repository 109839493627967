import { useState } from "react";
import Modal from "react-modal";
import { toast } from "react-toastify";
import { signTypedData } from "@wagmi/core";
import { ethers } from "ethers";
import { createSignature, getAllowance } from "../../api/offer";
import useTypedData from "../../store/hooks/useTypedData";
import "./modal.css";
import { useParams } from "react-router-dom";
import LoadingButton from "../LoadingButton";

const tokenAddress = process.env.REACT_APP_PAY_TOKEN_ADDRESS;
const nftAddress = process.env.REACT_APP_DRIVORA_ADDRESS;

const StepModal = (props) => {
  const { showStepModal, setShowStepModal, bidAmount } = props;
  const [step, setStep] = useState(1);
  const [passed, setPassed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { tokenId } = useParams();

  const typedData = useTypedData();

  const handleClose = () => {
    setShowStepModal(false);
    setStep(1);
    setPassed(false);
  };

  const handleGetAllowance = async () => {
    const data = {
      payToken: tokenAddress,
      price: ethers.utils.parseEther(bidAmount).toString(10),
    };
    setIsLoading(true);
    // await getAllowance(data)
    //   .then((res) => {
    //     if (res.success) {
    //       setPassed(true);
    //       setStep(3);
    //     } else {
    //       // setPassed(false);
    //       setPassed(true);
    //       setStep(3);
    //       toast.error(res.message);
    //     }
    //   })
    //   .catch((err) => {
    //     toast.error(err);
    //     setPassed(false);
    //   });
    setPassed(true);
    setStep(3);
    setIsLoading(false);
  };

  const createOffer = async () => {
    setIsLoading(true);
    const signature = await signTypedData(
      typedData(
        nftAddress,
        tokenId,
        tokenAddress,
        ethers.utils.parseEther(bidAmount).toString(10)
      )
    );

    const data = {
      nftAddress,
      tokenId,
      payToken: tokenAddress,
      price: ethers.utils.parseEther(bidAmount).toString(10),
      signature,
    };

    await createSignature(data)
      .then((res) => {
        if (res.success) {
          toast.success("Your offer has been submitted!");
          setPassed(true);
          setShowStepModal(false);
        } else {
          setPassed(false);
          toast.error(res.message);
        }
      })
      .catch((err) => {
        toast.error(err);
        setPassed(false);
      });
    setIsLoading(false);
  };

  return (
    <Modal
      appElement={document.getElementById("root")}
      isOpen={showStepModal}
      onRequestClose={handleClose}
      overlayClassName={{
        base: "overlay-base",
        afterOpen: "offer-overlay-after",
        beforeClose: "overlay-before",
      }}
      className={{
        base: "content-base",
        afterOpen: "bid-content-after",
        beforeClose: "content-before",
      }}
      preventScroll={true}
    >
      <div className="modal-close">
        <i className="material-icons" onClick={handleClose}>
          close
        </i>
      </div>
      <h5 className="text-black modal-title">FOLLOW STEPS</h5>
      <div className="mt-4 w-100">
        <div className="d-flex gap-3 align-items-center mb-2">
          <div className={`${step > 1 && "approve"} icon-circle`}>
            {step > 1 ? (
              <i className="fas fa-check"></i>
            ) : (
              <i className="far fa-caret-square-up"></i>
            )}
          </div>
          <div>
            <h5>DEPOSIT ETH</h5>
            <p>Send transaction with your wallet</p>
          </div>
        </div>
        <LoadingButton
          content={step > 1 ? "DONE" : "START NOW"}
          isLoading={false}
          clickAction={() => {
            setPassed(true);
            if (true) {
              setStep(2);
            }
          }}
          className="modal-btn black-btn"
          disabled={step !== 1}
        />
      </div>
      <div className="mt-4 w-100">
        <div className="d-flex gap-3 align-items-center mb-2">
          <div
            className={`icon-circle ${
              step > 2 && passed
                ? "approve"
                : step === 2 && !passed
                ? "failed"
                : ""
            }`}
          >
            <i className="fas fa-check"></i>
          </div>
          <div>
            <h5>APPROVE</h5>
            <p>Checking balance and approving</p>
          </div>
        </div>
        <LoadingButton
          content={
            step > 2
              ? "DONE"
              : !passed && step === 2
              ? "CANCELLED"
              : "START NOW"
          }
          isLoading={false}
          clickAction={handleGetAllowance}
          className={`modal-btn ${
            !passed && step === 2 ? "failed-btn" : "black-btn"
          }`}
          disabled={step !== 2}
        />
        {!passed && step === 2 && (
          <p>Something went wrong, please try again.</p>
        )}
      </div>
      <div className="mt-4 w-100">
        <div className="d-flex gap-3 align-items-center mb-2">
          <div className="icon-circle">
            <i className="fas fa-pen"></i>
          </div>
          <div>
            <h5>SIGNATURE</h5>
            <p>Create a signature to place a bid</p>
          </div>
        </div>
        <LoadingButton
          content="START NOW"
          isLoading={isLoading}
          clickAction={createOffer}
          className="modal-btn black-btn"
          disabled={step !== 3}
        />
      </div>
    </Modal>
  );
};

export default StepModal;
