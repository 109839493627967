import { motion } from "framer-motion";
import icon1 from "../../assets/images/process-icon1.png";
import icon2 from "../../assets/images/process-icon2.png";
import graphic1 from "../../assets/images/process-graphic1.png";
import graphic2 from "../../assets/images/process-graphic2.png";
import effect1 from "../../assets/images/process-effect1.png";
import "./process.css";

const Process = () => {
  return (
    <div className="process">
      <div className="container">
        <motion.div
          initial={{ opacity: 0, y: 200 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
        >
          <h4 className="add-vertical-line">OUR PROCESS</h4>
          <div className="section-content">
            <h2>
              PIONEERING CAR TOKENIZATION WITH
              <span className="text-shadow">
                {" "}
                CHAINLINK AND RFID TECHNOLOGY.
              </span>
            </h2>
          </div>
        </motion.div>
        <div className="row process-content">
          <motion.div
            initial={{ opacity: 0, x: -200 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 1 }}
            viewport={{ once: true }}
            className="col-lg-6 process-text1"
          >
            <div className="position-relative">
              <img src={icon1} alt="process icon" className="process-icon1" />
              <h2>CHAINLINK TOKENIZATION PROCESS.</h2>
            </div>
            <p className="my-4">
              At Drivora, we embrace cutting-edge technology to transform your
              exquisite automobiles into valuable digital assets. Our
              tokenization process utilizes Chainlink, a leading decentralized
              oracle network, to ensure that every vehicle's data is accurately
              and securely represented on the blockchain.
            </p>
            <p className="my-4">
              This integration allows us to convert your car's value into a
              digital token, offering a seamless blend of real-world data with
              blockchain's inherent security and transparency.
            </p>
            <p className="my-4">
              Each tokenized car benefits from blockchain's immutability,
              facilitating trustworthy transactions in our exclusive
              marketplace.
            </p>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, scale: 0 }}
            whileInView={{ opacity: 1, scale: 1 }}
            transition={{ duration: 1 }}
            viewport={{ once: true }}
            className="col-lg-6"
          >
            <div className="position-relative">
              <img src={graphic1} alt="graphic" className="graphic1" />
              <img src={effect1} alt="effect" className="process-effect1" />
            </div>
          </motion.div>
        </div>
        <div className="row process-content flex-column-reverse flex-lg-row">
          <motion.div
            initial={{ opacity: 0, scale: 0 }}
            whileInView={{ opacity: 1, scale: 1 }}
            transition={{ duration: 1 }}
            viewport={{ once: true }}
            className="col-lg-6"
          >
            <div className="position-relative">
              <img src={graphic2} alt="graphic" className="graphic1" />
              <img src={effect1} alt="effect" className="process-effect2" />
            </div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: 200 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 1 }}
            viewport={{ once: true }}
            className="col-lg-6 process-text2"
          >
            <div className="position-relative">
              <img src={icon2} alt="process icon" className="process-icon2" />
              <h2>
                PHYSICAL <br />
                RFID TAG IMPLEMENTATION.
              </h2>
            </div>
            <p className="my-4">
              Beyond digital, we ensure physical verification through RFID
              technology. Each exotic car registered on Drivora is equipped with
              a state-of-the-art RFID tag.
            </p>
            <p className="my-4">
              This tag securely stores data about the car's history, ownership,
              and authenticity. When scanned, it confirms the vehicle’s
              identity, matching it with its blockchain record.
            </p>
            <p className="my-4">
              This dual-layer of verification - combining physical tags with
              digital tokens - sets a new standard in asset security and
              investor confidence.
            </p>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Process;
