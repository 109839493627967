import { useState } from "react";
import { motion } from "framer-motion";
import { HashLink } from "react-router-hash-link";
import { useNavigate } from "react-router-dom";

import logo from "../assets/images/new/new-logo.png";

const NewNavbar = () => {
  const navigate = useNavigate();
  const [viewMenu, setViewMenu] = useState(false);

  const handleMenu = () => {
    setViewMenu(!viewMenu);
  };

  return (
    <>
      <div id="new-nav">
        <div className="position-relative">
          <HashLink to="/new-home/#">
            <motion.img
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 1 }}
              viewport={{ once: true }}
              src={logo}
              className="z-3 position-relative nav-logo"
              alt="logo"
            />
          </HashLink>
          <HashLink to="/#" className="nav-item nav-right members-btn">
            Members Area
          </HashLink>
          <div className="nav-left">
            <HashLink to="/new-home/#" className="nav-item hover-bottom">
              Home
            </HashLink>
            <HashLink to="/about/#" className="nav-item hover-bottom pad-hide">
              About
            </HashLink>
            <HashLink to="/marketplace/#" className="nav-item hover-bottom pad-hide">
              Marketplace
            </HashLink>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewNavbar;
