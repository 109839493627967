import icon1 from "../../assets/images/featured-icon1.png";
import icon2 from "../../assets/images/featured-icon2.png";
import icon3 from "../../assets/images/featured-icon3.png";
import icon4 from "../../assets/images/featured-icon4.png";
import icon5 from "../../assets/images/featured-icon5.png";
import icon6 from "../../assets/images/featured-icon6.png";
import icon7 from "../../assets/images/featured-icon7.png";
import icon8 from "../../assets/images/featured-icon8.png";
import icon9 from "../../assets/images/featured-icon9.png";
import icon10 from "../../assets/images/featured-icon10.png";

const properties = [
  { icon: icon1, key: "Model", value: "LaFerrari" },
  { icon: icon2, key: "Produced", value: 799 },
  { icon: icon3, key: "Capacity", value: "6,486 cc" },
  { icon: icon4, key: "Torque", value: "5310lb ft" },
  { icon: icon5, key: "Power", value: "789 hp" },
  { icon: icon6, key: "Engine", value: "V12" },
  { icon: icon7, key: "Transmission", value: "7-speed Auto" },
  { icon: icon8, key: "Year", value: 2019 },
  { icon: icon9, key: "Weight", value: "1,774kh" },
  { icon: icon10, key: "0-100km/h", value: "2.9 sec" },
];

const NewFeature = () => {
  return (
    <div id="new-feature" className="section">
      <div className="position-relative">
        <div>
          <h5>SHOWCASE</h5>
          <h1>Featured Car</h1>
        </div>
        <div className="values">
          <div className="mb-3">
            <p className="value">$450k</p>
            <p>Sold by Ferrari in 2016</p>
          </div>
          <div className="mb-3">
            <p className="value">$998k</p>
            <p>Current Value</p>
          </div>
          <div className="mb-3">
            <p className="value">20%</p>
            <p>Total Target IRR until 2025</p>
          </div>
        </div>
        <div className="car-detail">
          <div style={{ letterSpacing: "7px" }}>FERRARI "La Ferrari"</div>
          <div>"Was sold In 2016 in Los Angeles for $1,550,000.</div>
        </div>
      </div>
      <div className="car-properties">
        {properties.map((property, index) => (
          <div key={index} className="each-property">
            <img src={property.icon} alt="icon" />
            <div className="key">{property.key}</div>
            <div>{property.value}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NewFeature;
