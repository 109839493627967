import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { useSetCurrentToken } from "../../store/hooks/token";
import light from "../../assets/images/add-new-nft-light.png";
import "./addNewNft.css";

const AddNewNft = () => {
  const navigate = useNavigate();
  const setCurrentToken = useSetCurrentToken();

  return (
    <motion.div
      initial={{ opacity: 0, y: 200 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 1 }}
      viewport={{ once: true }}
      className="add-new-nft"
    >
      <img src={light} alt="light" className="add-new-nft-light" />
      <h2 className="text-shadow">TOKENIZE YOUR VEHICLE.</h2>
      <button
        className="position-relative normal-btn extra-large mt-4 d-flex align-items-center justify-content-center gap-2"
        onClick={() => {
          setCurrentToken({ token: null });
          navigate("/tokenize");
        }}
      >
        <i className="material-icons" style={{ color: "#bfbfbf" }}>
          add_circle_outline
        </i>{" "}
        TOKENIZE
      </button>
    </motion.div>
  );
};

export default AddNewNft;
