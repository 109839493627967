import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Oval } from "react-loader-spinner";
import { useAccessToken, useUser } from "../../store/hooks/auth";
import { getUser } from "../../api/user";
import Navbar from "../../components/Navbar";
import NftGroup from "../../components/NftGroup";
import Footer from "../../components/Footer";
import Insights from "../../sections/insights";
import AddNewNft from "../../sections/addNewNft";
import Blog from "../../sections/blog";
import Community from "../../sections/community";
import UserInfo from "../../sections/userInfo";
import { getNfts, getNftsByUser } from "../../api/nft";
import CustomNftGroup from "../../components/CustomNftGroup";

import shape from "../../assets/images/profile/shape.png";
import effect1 from "../../assets/images/login-light1.png";
import trending_car1 from "../../assets/images/profile/trending-car1.png";
import trending_car2 from "../../assets/images/profile/trending-car2.png";
import trending_car3 from "../../assets/images/profile/trending-car3.png";
import trending_car4 from "../../assets/images/profile/trending-car4.png";
import trending_car5 from "../../assets/images/profile/trending-car5.png";
import trending_car6 from "../../assets/images/profile/trending-car6.png";
import featured_car1 from "../../assets/images/profile/feature-car1.png";
import featured_car2 from "../../assets/images/profile/feature-car2.png";
import featured_car3 from "../../assets/images/profile/feature-car3.png";
import viewed_car1 from "../../assets/images/profile/viewed-car1.png";
import viewed_car2 from "../../assets/images/profile/viewed-car2.png";
import viewed_car3 from "../../assets/images/profile/viewed-car3.png";
import know_car1 from "../../assets/images/profile/know-car1.png";
import know_car2 from "../../assets/images/profile/know-car2.png";
import know_car3 from "../../assets/images/profile/know-car3.png";

import "./user.css";

const trendingCar = [
  { img: trending_car1, badge: "New Release", info: true, footer: true },
  { img: trending_car2, badge: "New Release", info: true, footer: true },
  { img: trending_car3, badge: "New Release", info: true, footer: true },
  { img: trending_car4, badge: "New Release", info: true, footer: true },
  { img: trending_car5, badge: "New Release", info: true, footer: true },
  { img: trending_car6, badge: "New Release", info: true, footer: true },
];

const featuredCar = [
  { img: featured_car1, badge: "New Release", footer: true, price: 500 },
  { img: featured_car2, badge: "Limited Edition", footer: true, price: 300 },
  { img: featured_car3, badge: "Rare", footer: true, price: 200 },
];

const viewedCar = [
  { img: viewed_car1, footer: false, type: "SPORTS CARS" },
  { img: viewed_car2, footer: false, type: "VINTAGE CARS" },
  { img: viewed_car3, footer: false, type: "REGULAR CARS" },
];

const knowCar = [
  { img: know_car1, price: 500, footer: true, badge: "New Release" },
  {
    img: know_car2,
    price: 300,
    footer: true,
    badge: "Limited Edition",
  },
  { img: know_car3, price: 200, footer: true, badge: "Rare" },
];

const Profile = () => {
  const { username } = useParams();
  const token = useAccessToken();
  const user = useUser();
  const [currentUser, setCurrentUser] = useState({ isLoading: true });
  const [nfts, setNfts] = useState([]);
  const [listedNfts, setListedNfts] = useState([]);
  const [editable, setEditable] = useState(true);

  useEffect(() => {
    const getUserFunc = async () => {
      try {
        const res = await getUser({ username: username });
        if (res.success) {
          setCurrentUser({ user: res.user, isLoading: false });
          setEditable(false);
        } else {
          setCurrentUser({ isLoading: false });
        }
      } catch (err) {
        if (err.response.status === 403) {
          toast.error("Something went wrong!");
        }
        setCurrentUser({ isLoading: false });
      }
    };

    if (token && username) {
      getUserFunc();
    } else if (user) {
      setCurrentUser({ user, isLoading: false });
    }
  }, [token, username, user]);

  useEffect(() => {
    const getNfts = async () => {
      try {
        const res = await getNftsByUser({ id: currentUser.user.id });
        if (res.success) {
          setNfts(res.data);
        }
      } catch (err) {
        if (err.response?.status === 403) {
          toast.error("Something went wrong!");
        }
      }
    };

    if (!username || username === user?.username) {
      getNfts(currentUser.user);
    }
  }, [currentUser, username, user]);

  useEffect(() => {
    if (user) {
      setCurrentUser({ user, isLoading: false });
    }
  }, [user]);

  useEffect(() => {
    const getNft = async () => {
      try {
        const res = await getNfts();
        if (res.success) {
          setListedNfts(res.listings);
        }
      } catch (e) {
        toast.error(e);
      }
    };
    getNft();
  }, []);

  return (
    <div id="profile">
      <div className="container">
        <img src={effect1} alt="login effect" className="login-effect1" />
        <Navbar />
        {!currentUser.isLoading ? (
          <>
            {currentUser.user ? (
              <div className="main">
                <img src={shape} alt="shape" className="shape1" />
                <UserInfo
                  currentUser={currentUser.user}
                  countOfNft={nfts.length}
                  editable={editable}
                />
                {nfts && nfts.length > 0 && (
                  <CustomNftGroup
                    title={editable ? "Your NFTs." : "Owner NFTs."}
                    nfts={nfts}
                  />
                )}
                {listedNfts && listedNfts.length > 0 && (
                  <CustomNftGroup title="Your Garage." nfts={listedNfts} />
                )}
                {/* <NftGroup title="YOUR TRENDING NFTs." cars={trendingCar} /> */}
                <Insights />
                {/* <NftGroup title="FEATURED NFTs." cars={featuredCar} />
                <NftGroup title="RECENTLY VIEWED." cars={viewedCar} />
                <NftGroup title="PEOPLE YOU MAY KNOW." cars={knowCar} /> */}
                <AddNewNft />
                <Blog />
                <Community />
                <Footer />
              </div>
            ) : (
              <div className="mt-5">
                <h2 className="text-shadow">404 Page not found</h2>
              </div>
            )}
          </>
        ) : (
          <Oval
            visible={true}
            height="100"
            width="100"
            color="#000000"
            secondaryColor="#ffffff"
            ariaLabel="oval-loading"
            wrapperStyle={{ justifyContent: "center", marginTop: "150px" }}
            wrapperClass=""
            strokeWidth={3}
          />
        )}
      </div>
    </div>
  );
};

export default Profile;
