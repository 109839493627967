export const setupAxios = (axios, store) => {
  axios.interceptors.request.use(
    async (config) => {
      const { auth } = store.getState();
      if (auth.token) {
        config.headers.Authorization = `Bearer ${auth.token}`;
      }
      return config;
    },
    (err) => Promise.reject(err)
  );
};
