const NewWhy = () => {
  return (
    <>
      <div id="new-why" className="section">
        <div className="container">
          <div className="section-title">WHY</div>
          <div className="section-content">
            <h1>We are different</h1>
          </div>
          <h5>Three tiered profiles</h5>
        </div>
      </div>
      <div id="public-profile" className="section">
        <div className="container">
          <div className="section-second-title">PUBLIC PROFILE</div>
          <div className="section-content">
            <h5>
              Showcase your cars to all users.
              <br /> Accept, reject, or negotiate offers that exceed market
              value.
            </h5>
          </div>
        </div>
      </div>
      <div id="exclusive" className="section">
        <div className="container">
          <div className="section-second-title">EXCLUSIVE ACCESS</div>
          <div className="section-content">
            <h5>
              Limit visibility to friends or approved investors, maintaining
              privacy while still engagin in active trading.
            </h5>
          </div>
        </div>
      </div>
      <div id="private" className="section">
        <div className="container">
          <div className="section-second-title">PRIVATE VIEWING</div>
          <div className="section-content">
            <h5>
              Manage your portfolio in total privacy, accessible only to you.
            </h5>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewWhy;
