import * as yup from "yup";

// const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
// const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
const nameRegex = /^[a-zA-Z\s]*$/;

const assetDetailvalidationSchema = yup.object({
  company_name: yup.string().trim().required("Company name is required."),
  car_model: yup.string().trim().required("Model no is required."),
  manufacture_year: yup
    .string()
    .trim()
    .required("Manufacture year is required."),
  purchase_year: yup.string().trim().required("Purchase year  is required."),
  unit_produced: yup.string().trim().required("Unit produced is required."),
  kerb_weight: yup
    .string()
    .trim()
    // .matches(nameRegex, "Please enter only letters.")
    .required("Kerb weight is required."),
  mileage: yup.string().trim().required("Mileage is required."),
  condition: yup.string().trim().required("Condition is required."),
  engine: yup.string().trim().required("Engine is required."),
  transmission: yup.string().trim().required("Transmission is required."),
  torque: yup.string().trim().required("Torque is required."),
  horse_power: yup.string().trim().required("Horse power is required."),
  top_speed: yup.string().trim().required("Top speed is required."),
  mhp: yup.string().trim().required("MHP is required."),
});

export default assetDetailvalidationSchema;
