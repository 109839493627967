import Chart from "react-apexcharts";
import { motion } from "framer-motion";
import "./insights.css";

const Insights = () => {
  const options = {
    chart: {
      id: "apexchart-example",
    },
    xaxis: {
      categories: [1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000],
    },
    title: {
      text: "Grouped Labels on the X-axis",
    },
  };
  const series = [
    {
      name: "series-1",
      data: [1250, 2000, 3000, 2400, 1000, 1500, 1700, 2000, 1250],
    },
  ];

  return (
    <div className="insights">
      <motion.div
        initial={{ opacity: 0, y: 200 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
        viewport={{ once: true }}
      >
        <p className="tracking-widest">INSIGHTS</p>
        <div className="insights-title">
          ARTWORK <span className="text-shadow">ANALYTICS.</span>
        </div>
        <p className="text-white my-5">Track performance of your NFTs</p>
      </motion.div>
      <motion.div
        initial={{ opacity: 0, y: 200 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
        viewport={{ once: true }}
        className="analytics"
      >
        <div>
          <p>Total views</p>
          <div className="num">567</div>
          <p className="percentage positive">+25%</p>
        </div>
        <div>
          <p>Sales</p>
          <div className="num">12</div>
          <p className="percentage negative">-10%</p>
        </div>
        <div>
          <p>Likes</p>
          <div className="num">98</div>
          <p className="percentage positive">+45%</p>
        </div>
      </motion.div>
      <motion.div
        initial={{ opacity: 0, y: 200 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
        viewport={{ once: true }}
        className="chart-box"
      >
        <Chart options={options} series={series} type="bar" height={400} />
      </motion.div>
    </div>
  );
};

export default Insights;
