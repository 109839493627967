import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Controller, useForm } from "react-hook-form";

import CustomInput from "../../components/CustomInput";
import useYupValidationResolver from "../../store/hooks/useYupValidationResolver";
import assetDetailvalidationSchema from "../../store/contexts/assetDetailvalidationSchema";
import ConfirmButtons from "../../components/ConfirmButtons";
import { addTokenInfo } from "../../api/nft";
import { useVin } from "../../store/hooks/token";
import add_asset from "../../assets/images/add-asset.png";
import ring_icon from "../../assets/images/ring-icon.png";
import car_icon from "../../assets/images/car-icon.png";
import year_calendar_icon from "../../assets/images/year-calendar-icon.png";
import day_calendar_icon from "../../assets/images/day-calendar-icon.png";
import unit_icon from "../../assets/images/featured-icon2.png";
import mileage_icon from "../../assets/images/milesage_icon.png";
import weight_icon from "../../assets/images/featured-icon9.png";
import condition_icon from "../../assets/images/condition_icon.png";
import engine_icon from "../../assets/images/engine-icon.png";
import transmission_icon from "../../assets/images/featured-icon7.png";
import torque_icon from "../../assets/images/featured-icon4.png";
import power_icon from "../../assets/images/featured-icon5.png";
import speed_icon from "../../assets/images/speed-icon.png";
import time_icon from "../../assets/images/time-icon.png";
import axios from "axios";

const Step3 = ({ navigate, user, token, nftData }) => {
  const vin = useVin();
  const [isLoading, setIsLoading] = useState(false);

  const resolver = useYupValidationResolver(assetDetailvalidationSchema);
  const { control, handleSubmit, formState, setValue } = useForm({
    mode: "onSubmit",
    reValidateMode: "onBlur",
    resolver,
  });
  const { errors } = formState;

  useEffect(() => {
    const decodeVin = async () => {
      try {
        const response = await axios.get(
          `https://vpic.nhtsa.dot.gov/api/vehicles/DecodeVin/${vin}?format=json`
        );
        const results = response.data.Results;

        const decodedVin = results.find(
          (r) => r.Variable === "Error Code" && r.Value === "0"
        );
        if (decodedVin) {
          setValue(
            "company_name",
            results.find((r) => r.Variable === "Make")?.Value || ""
          );
          setValue(
            "car_model",
            results.find((r) => r.Variable === "Model")?.Value || ""
          );
          setValue(
            "manufacture_year",
            results.find((r) => r.Variable === "Model Year")?.Value || ""
          );
          setValue(
            "kerb_weight",
            results.find((r) => r.Variable === "Curb Weight")?.Value || ""
          );
          setValue(
            "engine",
            results.find((r) => r.Variable === "Engine Model")?.Value || ""
          );
          setValue(
            "transmission",
            results.find((r) => r.Variable === "Transmission Style")?.Value ||
              ""
          );
          setValue(
            "torque",
            results.find((r) => r.Variable === "Engine Torque")?.Value || ""
          );
          setValue(
            "horse_power",
            results.find((r) => r.Variable === "Engine HP")?.Value || ""
          );
        } else {
          toast.error("Invalid VIN");
        }
      } catch (error) {
        console.log("error ===>", error);
        toast.error("Invalid VIN");
      }
    };
    if (vin) {
      decodeVin();
      return;
    }
    if (nftData) {
      setValue("company_name", nftData.company_name);
      setValue("car_model", nftData.car_model);
      setValue("manufacture_year", nftData.manufacture_year);
      setValue("purchase_year", nftData.purchase_year);
      setValue("unit_produced", nftData.unit_produced);
      setValue("mileage", nftData.mileage);
      setValue("kerb_weight", nftData.kerb_weight);
      setValue("condition", nftData.condition);
      setValue("engine", nftData.engine);
      setValue("transmission", nftData.transmission);
      setValue("torque", nftData.torque);
      setValue("horse_power", nftData.horse_power);
      setValue("top_speed", nftData.top_speed);
      setValue("mhp", nftData.mhp);
      return;
    }
  }, [nftData, setValue, vin]);

  const onSubmit = async (formValues) => {
    const formData = new FormData();

    for (const key in formValues) {
      if (formValues.hasOwnProperty(key)) {
        formData.append(key, formValues[key]);
      }
    }

    formData.append("id", token);
    formData.append("userId", user.id);
    formData.append("currentStep", 4);

    try {
      setIsLoading(true);
      const data = await addTokenInfo(formData);
      if (data.success) {
        toast.success("Token info is updated successfully!");
        navigate("/tokenize/4");
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
    setIsLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="asset-details">
        <div className="container">
          <h2 className="mb-5 text-shadow">ASSET DETAILS.</h2>
          <div className="row align-items-center gap-4 gap-lg-0">
            <div className="col-lg-6 text-center text-lg-start">
              <img src={add_asset} alt="add_asset" />
            </div>
            <div className="col-lg-6 d-flex justify-content-center justify-content-lg-end">
              <div className="">
                <div className="mb-5">
                  <div className="d-flex align-items-center gap-3 mb-3">
                    <img
                      src={ring_icon}
                      alt="ring icon"
                      className="form-icon"
                    />
                    <p>Company Name</p>
                  </div>
                  <Controller
                    name="company_name"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <CustomInput
                        placeholder="Ferrari, Porsche…"
                        type="text"
                        className=""
                        name="company_name"
                        error={
                          errors.company_name ? errors.company_name.message : ""
                        }
                        {...field}
                      />
                    )}
                  />
                  <p className="description">
                    Enter the name of the manufacturer of your exotic car (e.g.,
                    "Ferrari," "Porsche").
                  </p>
                </div>
                <div className="mb-5">
                  <div className="d-flex align-items-center gap-3 mb-3">
                    <img src={car_icon} alt="ring icon" className="form-icon" />
                    <p>Model No.</p>
                  </div>
                  <Controller
                    name="car_model"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <CustomInput
                        placeholder="F8 Tributo"
                        type="text"
                        className=""
                        name="car_model"
                        {...field}
                        error={errors.car_model ? errors.car_model.message : ""}
                      />
                    )}
                  />
                  <p className="description">
                    Provide the model number or name of your car (e.g., "F8
                    Tributo," "Aventador S").
                  </p>
                </div>
                <div className="mb-5">
                  <div className="d-flex align-items-center gap-3 mb-3">
                    <img
                      src={year_calendar_icon}
                      alt="ring icon"
                      className="form-icon"
                    />
                    <p>Manufacture Year</p>
                  </div>
                  <Controller
                    name="manufacture_year"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <CustomInput
                        placeholder="Enter Manufacture Year"
                        type="text"
                        className=""
                        name="manufacture_year"
                        error={
                          errors.manufacture_year
                            ? errors.manufacture_year.message
                            : ""
                        }
                        {...field}
                      />
                    )}
                  />
                  <p className="description">
                    Indicate the year your car was manufactured. This helps
                    verify the age and condition of the vehicle.
                  </p>
                </div>
                <div>
                  <div className="d-flex align-items-center gap-3 mb-3">
                    <img
                      src={day_calendar_icon}
                      alt="ring icon"
                      className="form-icon"
                    />
                    <p>Purchase Year</p>
                  </div>
                  <Controller
                    name="purchase_year"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <CustomInput
                        placeholder="Enter the Purchase Year"
                        type="text"
                        className=""
                        name="purchase_year"
                        error={
                          errors.purchase_year
                            ? errors.purchase_year.message
                            : ""
                        }
                        {...field}
                      />
                    )}
                  />
                  <p className="description">
                    Enter the year you purchased the car. This information is
                    useful for determining the car's ownership history.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="property1-details">
        <div className="container">
          <h2 className="mb-5 text-shadow">VEHICLE SPECS.</h2>
          <div className="d-flex align-items-center justify-content-center justify-content-lg-between flex-wrap">
            <div>
              <div className="mb-5">
                <div className="d-flex align-items-center gap-3 mb-3">
                  <img src={unit_icon} alt="ring icon" className="form-icon" />
                  <p>Unit Produced</p>
                </div>
                <Controller
                  name="unit_produced"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <CustomInput
                      placeholder="Total number of units produced and which #"
                      type="text"
                      className=""
                      name="unit_produced"
                      error={
                        errors.unit_produced ? errors.unit_produced.message : ""
                      }
                      {...field}
                    />
                  )}
                />
                <p className="description">
                  Enter the total number of units produced for this mode and
                  which # number this vehicle is. This can indicate the rarity
                  and exclusivity of the vehicle.
                </p>
              </div>
              <div className="mb-5">
                <div className="d-flex align-items-center gap-3 mb-3">
                  <img
                    src={mileage_icon}
                    alt="ring icon"
                    className="form-icon"
                  />
                  <p>Mileage</p>
                </div>
                <Controller
                  name="mileage"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <CustomInput
                      placeholder="Enter Current Mileage"
                      type="text"
                      className=""
                      name="mileage"
                      error={errors.mileage ? errors.mileage.message : ""}
                      {...field}
                    />
                  )}
                />
                <p className="description">
                  Provide the current mileage of your car. This is important for
                  assessing the vehicle's usage and condition.
                </p>
              </div>
            </div>
            <div>
              <div className="mb-5">
                <div className="d-flex align-items-center gap-3 mb-3">
                  <img
                    src={weight_icon}
                    alt="ring icon"
                    className="form-icon"
                  />
                  <p>Kerb Weight</p>
                </div>
                <Controller
                  name="kerb_weight"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <CustomInput
                      placeholder="Enter Vehicle Weight"
                      type="text"
                      className=""
                      name="kerb_weight"
                      error={
                        errors.kerb_weight ? errors.kerb_weight.message : ""
                      }
                      {...field}
                    />
                  )}
                />
                <p className="description">
                  Indicate the kerb weight of the car. This is the total weight
                  of the vehicle without any passengers or cargo, which helps in
                  understanding its performance characteristics.
                </p>
              </div>
              <div className="mb-5">
                <div className="d-flex align-items-center gap-3 mb-3">
                  <img
                    src={condition_icon}
                    alt="ring icon"
                    className="form-icon"
                  />
                  <p>Condition</p>
                </div>
                <Controller
                  name="condition"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <CustomInput
                      placeholder="Enter Current Condition"
                      type="text"
                      className=""
                      name="condition"
                      error={errors.condition ? errors.condition.message : ""}
                      {...field}
                    />
                  )}
                />
                <p className="description">
                  Describe the current condition of your car. Include details
                  about any wear and tear, recent maintenance, or restorations.
                  This gives potential buyers a clear picture of the car's
                  state.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="property2-details">
        <div className="container">
          <h2 className="mb-5 text-shadow">PERFORMANCE DETAILS.</h2>
          <div className="d-flex justify-content-center justify-content-lg-between flex-wrap">
            <div>
              <div className="mb-5">
                <div className="d-flex align-items-center gap-3 mb-3">
                  <img
                    src={engine_icon}
                    alt="ring icon"
                    className="form-icon"
                  />
                  <p>Engine</p>
                </div>
                <Controller
                  name="engine"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <CustomInput
                      placeholder="Your Engine Type"
                      type="text"
                      className=""
                      name="engine"
                      error={errors.engine ? errors.engine.message : ""}
                      {...field}
                    />
                  )}
                />
                <p className="description">
                  Provide details about the engine of your car, including the
                  type (e.g., V8, V12), displacement, and any notable features.
                  This information highlights the car's performance
                  capabilities.
                </p>
              </div>
              <div className="mb-5">
                <div className="d-flex align-items-center gap-3 mb-3">
                  <img
                    src={transmission_icon}
                    alt="ring icon"
                    className="form-icon"
                  />
                  <p>Transmission</p>
                </div>
                <Controller
                  name="transmission"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <CustomInput
                      placeholder="Automatic, Manual or semi-automatic"
                      type="text"
                      className=""
                      name="transmission"
                      error={
                        errors.transmission ? errors.transmission.message : ""
                      }
                      {...field}
                    />
                  )}
                />
                <p className="description">
                  Enter the type of transmission your car has (e.g., automatic,
                  manual, semi-automatic). Specify the number of gears if
                  applicable.
                </p>
              </div>
              <div className="mb-5">
                <div className="d-flex align-items-center gap-3 mb-3">
                  <img
                    src={torque_icon}
                    alt="ring icon"
                    className="form-icon"
                  />
                  <p>Torque</p>
                </div>
                <Controller
                  name="torque"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <CustomInput
                      placeholder="Enter the Torque"
                      type="text"
                      className=""
                      name="torque"
                      error={errors.torque ? errors.torque.message : ""}
                      {...field}
                    />
                  )}
                />
                <p className="description">
                  Indicate the torque produced by the engine, usually measured
                  in Nm (Newton meters) or lb-ft (pound-feet). This provides
                  insight into the car's acceleration and pulling power.
                </p>
              </div>
              <div className="mb-5">
                <div className="d-flex align-items-center gap-3 mb-3">
                  <img src={power_icon} alt="ring icon" className="form-icon" />
                  <p>Horse Power</p>
                </div>
                <Controller
                  name="horse_power"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <CustomInput
                      placeholder="Enter the horsepower"
                      type="text"
                      className=""
                      name="horse_power"
                      error={
                        errors.horse_power ? errors.horse_power.message : ""
                      }
                      {...field}
                    />
                  )}
                />
                <p className="description">
                  Enter the horsepower (HP) rating of the engine. This metric is
                  key to understanding the car's overall power and performance.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="property3-details">
        <div className="container">
          <div className="d-flex justify-content-center justify-content-lg-end">
            <div>
              <h2 className="mb-5 text-shadow">PERFORMANCE METRICS.</h2>
              <div className="mb-5">
                <div className="d-flex align-items-center gap-3 mb-3">
                  <img src={speed_icon} alt="ring icon" className="form-icon" />
                  <p>Top Speed</p>
                </div>
                <Controller
                  name="top_speed"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <CustomInput
                      placeholder="What is the Top Speed?"
                      type="text"
                      className=""
                      name="top_speed"
                      error={errors.top_speed ? errors.top_speed.message : ""}
                      {...field}
                    />
                  )}
                />
                <p className="description">
                  Enter the top speed of your car, typically measured in mph
                  (miles per hour) or km/h (kilometers per hour). This indicates
                  the maximum velocity the vehicle can achieve.
                </p>
              </div>
              <div className="mb-5">
                <div className="d-flex align-items-center gap-3 mb-3">
                  <img src={time_icon} alt="ring icon" className="form-icon" />
                  <p>0-60 MHP</p>
                </div>
                <Controller
                  name="mhp"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <CustomInput
                      placeholder="Enter in Seconds"
                      type="text"
                      className=""
                      name="mhp"
                      error={errors.mhp ? errors.mhp.message : ""}
                      {...field}
                    />
                  )}
                />
                <p className="description">
                  Provide the time it takes for your car to accelerate from 0 to
                  60 mph. This is a key performance indicator for the car's
                  acceleration capabilities.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="background-gray">
        <div className="container">
          <ConfirmButtons isLoading={isLoading} />
        </div>
      </div>
    </form>
  );
};

export default Step3;
