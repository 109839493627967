import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Navbar from "../../components/Navbar";
import { useUser } from "../../store/hooks/auth";
import { updateUser } from "../../api/user";
import createAvatar from "../../utils/createAvatar";
import CustomInput from "../../components/CustomInput";
import Footer from "../../components/Footer";
import LoadingButton from "../../components/LoadingButton";

import effect1 from "../../assets/images/login-light1.png";
import shape from "../../assets/images/profile/shape.png";

import "./edit.css";

const EditProfile = () => {
  const currentUser = useUser();
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [previewImg, setPreviewImg] = useState("");
  const [values, setValues] = useState({
    fullname: "",
    email: "",
    bio: "",
    contactNo: "",
  });
  const [errors, setErrors] = useState({});
  const [isActive, setIsActive] = useState(false);
  const [selected, setSelected] = useState("Please select profile type");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (currentUser) {
      setValues({
        fullname: currentUser.fullname,
        email: currentUser.email,
        bio: currentUser.bio,
        contactNo: currentUser.contactNo,
      });
      setSelectedType(Number(currentUser.type));
      setSelected(
        ["Please select profile type", "Public", "Semi-Private", "Private"][
          Number(currentUser.type)
        ]
      );
      setPreviewImg(currentUser.avatar);
    }
  }, [currentUser]);

  const handleFileChange = (event) => {
    const file = event.target.files?.[0];
    if (file) setFile(file);
  };

  const handleChange = ({ target: { name, value } }) => {
    setValues((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({
      ...prev,
      [name]: value ? "" : `${name} cannot be empty`,
    }));
  };

  const handleDropdownToggle = () => setIsActive(!isActive);

  const handleOptionClick = (id, text) => {
    setSelected(text);
    setSelectedType(id);
    setIsActive(false);
  };

  const handleUpdate = async () => {
    if (Object.values(errors).filter((error) => error !== "").length === 0) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("type", selectedType);
      formData.append("fullname", values.fullname);
      formData.append("email", values.email);
      formData.append("bio", values.bio);
      formData.append("contactNo", values.contactNo);
      try {
        setIsLoading(true);
        const data = await updateUser(formData);
        if (data.success) {
          toast.success("Profile is updated successfully!");
          navigate("/myhome");
        } else {
          toast.error(data.message);
        }
      } catch (error) {
        toast.error(error.message);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div id="edit-profile">
      <div className="container">
        <Navbar />
        <img src={effect1} alt="login effect" className="login-effect1" />
        <div className="main">
          <img src={shape} alt="shape" className="shape1" />
          <h3 className="text-shadow">EDIT PROFILE INFORMATION.</h3>
          <div className="edit-form">
            <div className="avatar-box">
              <div>
                <p>Change profile picture</p>
                {currentUser?.avatar || file ? (
                  <motion.img
                    initial={{ opacity: 0, scale: 0.5 }}
                    whileInView={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 1, delay: 0.5 }}
                    viewport={{ once: true }}
                    src={file ? URL.createObjectURL(file) : previewImg}
                    alt="avatar"
                    className="user-avatar my-4"
                  />
                ) : (
                  <motion.div
                    initial={{ opacity: 0, scale: 0.5 }}
                    whileInView={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 1, delay: 0.5 }}
                    viewport={{ once: true }}
                    className="user-avatar my-4"
                    style={{
                      backgroundColor: createAvatar(currentUser?.fullname)
                        .color,
                    }}
                  >
                    {createAvatar(currentUser?.fullname).name}
                  </motion.div>
                )}
                <label htmlFor="upload-image" className="nav-btn">
                  UPLOAD PICTURE
                </label>
                <input
                  type="file"
                  id="upload-image"
                  className="d-none"
                  accept="video/*,image/*"
                  onChange={handleFileChange}
                />
              </div>
            </div>
            <div className="text-start grid-form">
              {["fullname", "email", "bio", "contactNo"].map((field) => (
                <div key={field}>
                  <p className="mb-2">
                    {field.charAt(0).toUpperCase() + field.slice(1)}
                  </p>
                  <CustomInput
                    placeholder="Enter your new information"
                    type="text"
                    name={field}
                    value={values[field]}
                    error={errors[field]}
                    onChange={handleChange}
                  />
                </div>
              ))}
              <div>
                <p className="mb-2">
                  Username <span>(not editable)</span>
                </p>
                <CustomInput
                  placeholder="Enter your new information"
                  type="text"
                  name="username"
                  className="disabled-field"
                  value={currentUser?.username || ""}
                  onChange={handleChange}
                  disabled="disabled"
                  readOnly
                />
              </div>
              <div>
                <p className="mb-2">Profile Type</p>
                <div
                  className={`custom-dropdown ${isActive ? "active" : ""}`}
                  tabIndex="0"
                  onBlur={() => setIsActive(false)}
                >
                  <div
                    className="custom-input d-flex align-items-center justify-content-between"
                    onClick={handleDropdownToggle}
                  >
                    <span
                      style={{ color: selectedType ? "#ffffff" : "#8e8e8e" }}
                    >
                      {selected}
                    </span>
                    <i className="fa fa-chevron-left"></i>
                  </div>
                  <ul
                    className="custom-dropdown-menu"
                    style={{ height: isActive ? "100%" : "0" }}
                  >
                    {["Public", "Semi-Private", "Private"].map(
                      (type, index) => (
                        <li
                          key={type}
                          onClick={() => handleOptionClick(index + 1, type)}
                        >
                          {type}
                        </li>
                      )
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="bottom-line"></div>
          <div className="button-box">
            <button className="nav-btn large" onClick={() => navigate(-1)}>
              CANCEL
            </button>
            <LoadingButton
              content="SAVE CHANGES"
              isLoading={isLoading}
              clickAction={handleUpdate}
              className="nav-btn large"
            />
          </div>
          <div className="bottom-line"></div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default EditProfile;
