import { motion } from "framer-motion";
import blog1 from "../../assets/images/profile/blog1.png";
import blog2 from "../../assets/images/profile/blog2.png";
import blog3 from "../../assets/images/profile/blog3.png";

const blogs = [
  { img: blog1, type: "Announcement", date: "22 JAN 2024" },
  { img: blog2, type: "Knowledgebase", date: "22 JAN 2024" },
  { img: blog3, type: "Technology", date: "22 JAN 2024" },
];

const Blog = () => {
  return (
    <div className="nft-group">
      <div className="nft-group-header">
        <div className="group-title">
          <span className="text-shadow text-white">BLOGS.</span>
        </div>
        <div className="bottom-line"></div>
        <div className="view-more">
          VIEW MORE <i className="fas fa-arrow-right ms-2"></i>
        </div>
      </div>
      <div className="nft-grid">
        {blogs.map((item, index) => (
          <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            whileInView={{ opacity: 1, scale: 1 }}
            transition={{ duration: 1, delay: 0.2 * index }}
            viewport={{ once: true }}
            className="nft-card"
            key={index}
          >
            <img src={item.img} alt="trending car" className="nft-img" />
            <div className="card-text">
              <div className="card-badge">{item.type}</div>
              <p className="card-date">{item.date}</p>
              <p className="card-name mb-2">
                Lorem ipsum dolor sit nullam set amet
              </p>
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default Blog;
