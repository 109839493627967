import axios from "axios";

const drivoraAxios = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
  withCredentials: true,
});

// drivoraAxios.interceptors.request.use(
//   (config) => {
// const token = localStorage.getItem("persist:drivora-auth");
//     console.log("token ==>", JSON.parse(token).token);
//     if (token) {
//       config.headers.Authorization = `Bearer ${JSON.parse(token).token}`;
//     }
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

export default drivoraAxios;
